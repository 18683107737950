import React from 'react';
import { RadioButton, FormFieldRadioButtonGroup } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { FormikProps, useFormikContext } from 'formik';
import { InitialValues } from '../../initialValues';
import { InfoIcon } from '@cp-shared-10/frontend-ui';
import { Contract, ProductType, VehicleDocumentReceiver } from '@cp-de/common';

type VehicleDocumentReceiverFormProps = {
    contract: Contract;
};

export const VehicleDocumentReceiverForm: React.FC<VehicleDocumentReceiverFormProps> = ({ contract }) => {
    const { t } = useTranslation('total-early-settlement');
    const { setFieldValue }: FormikProps<InitialValues> = useFormikContext();

    const isClassicCredit = contract.productType === ProductType.CLASSIC_CREDIT;
    const isIndividualCredit = contract.productType === ProductType.INDIVIDUAL_CREDIT;
    const isDirectCreditCarGroup = 'DirectCreditCar' === contract.productGroup;
    const dealerReceiver =
        (isClassicCredit || isIndividualCredit) && isDirectCreditCarGroup ? [] : [VehicleDocumentReceiver.DEALER];
    const vehicleDocumentReceiver = [
        VehicleDocumentReceiver.CUSTOMER,
        ...dealerReceiver,
        VehicleDocumentReceiver.THIRD_PARTY,
    ];

    return (
        <FormFieldRadioButtonGroup>
            {vehicleDocumentReceiver.map((receiver, index) => {
                const translationSuffix =
                    receiver === VehicleDocumentReceiver.CUSTOMER
                        ? 'customer'
                        : receiver === VehicleDocumentReceiver.DEALER
                        ? 'dealer'
                        : 'third-party';
                return (
                    <React.Fragment key={`${receiver} - ${index}`}>
                        <RadioButton
                            testId={`requester-${receiver}`}
                            value={receiver}
                            name="vehicleDocumentReceiver"
                            defaultChecked={receiver === VehicleDocumentReceiver.CUSTOMER}
                            onChange={() => setFieldValue('vehicleDocumentReceiver', receiver)}
                        >
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: t(`vehicle-document-receiver.radio-button-${translationSuffix}`),
                                }}
                            />
                        </RadioButton>
                        {receiver === VehicleDocumentReceiver.DEALER && (
                            <InfoIcon text={t('vehicle-document-receiver.tooltip-dealer')} />
                        )}
                    </React.Fragment>
                );
            })}
        </FormFieldRadioButtonGroup>
    );
};
