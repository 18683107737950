import React, { useState } from 'react';
import { Formik } from 'formik';
import { Form } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { Contract } from '@cp-de/common';
import { preventSubmit } from '@cp-shared-10/frontend-ui';
import { getValidationSchema } from './validationSchema';
import { Step } from './enums';
import { ThirdPartyForm } from './third-party-form';
import { CustomerForm } from './customer-form';
import { InitialValues, initialValues } from './initialValues';
import { IbanStateHandler } from '../types';

type TotalEarlySettlementUiProps = {
    contract: Contract;
    iban?: string;
    name?: string;
    ibanStateHandler: IbanStateHandler;
    onCancel: () => void;
    onSubmit: (formValues: InitialValues) => void;
    withKbs: boolean;
};

export const TotalEarlySettlementForm: React.FC<TotalEarlySettlementUiProps> = ({
    contract,
    iban,
    name,
    ibanStateHandler,
    onCancel,
    onSubmit,
    withKbs,
}) => {
    const { t } = useTranslation('total-early-settlement');
    const [step, setStep] = useState<Step>(Step.FIRST);
    const [isValidating, setIsValidating] = useState<boolean>(false);
    const validationSchema = getValidationSchema(
        t,
        setIsValidating,
        step === Step.SECOND ? ibanStateHandler : undefined,
    );

    return (
        <Formik
            initialValues={initialValues(name, iban)}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            validateOnBlur={false}
        >
            {() => (
                <Form onSubmit={e => preventSubmit(e)}>
                    {step === Step.SECOND ? (
                        <ThirdPartyForm
                            contract={contract}
                            setStep={setStep}
                            isValidating={isValidating}
                            ibanStateHandler={ibanStateHandler}
                            withKbs={withKbs}
                        />
                    ) : (
                        <CustomerForm contract={contract} onCancel={onCancel} setStep={setStep} />
                    )}
                </Form>
            )}
        </Formik>
    );
};
