import React, { useState } from 'react';
import { CpDataApi } from 'cp-xhr';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Button, ButtonContainer, Fieldset, Form, Layout, SectionHeading } from '@vwfs-bronson/bronson-react';
import { DataOverview, preventSubmit, ValidatedInput, Spinner } from '@cp-shared-10/frontend-ui';
import { Base64File } from '@cp-shared-10/apis';
import { getChangeNameEndpoint, IdentityChange, IdentityChangeError, UserType } from '@cp-de/common';

import { IdentityChangeFormValues, validationSchema } from './validation';
import { FileUpload } from './file-upload';
import { ErrorModal } from './error-modal';
import { getBase64, getFileType } from '../../../../utils';
import { parseErrorResponse } from '@cp-shared-10/frontend-integration';
import { WithDefaultCpIntegrationErrors } from '@cp-shared-10/common-utilities';

export type ChangeNameFormProps = {
    initialValues: IdentityChangeFormValues;
    onCancel: (formValues: IdentityChangeFormValues) => void;
    onSubmitSuccess: () => void;
    onSubmit: (formValues: IdentityChangeFormValues) => void;
    isAbo?: boolean;
    isContractView?: boolean;
};

export const testIds = {
    cancel: 'cancel',
    submit: 'submit',
    lastName: 'lastName',
    birthName: 'birthName',
};

export const ChangeNameForm: React.FC<ChangeNameFormProps> = ({
    initialValues,
    onSubmit,
    onCancel,
    onSubmitSuccess,
    isAbo,
    isContractView,
}) => {
    const { t } = useTranslation('my-profile');
    const translationPrefix = 'change-name';
    const [identityError, setIdentityError] = useState<WithDefaultCpIntegrationErrors<IdentityChangeError> | undefined>(
        undefined,
    );
    const [loading, setLoading] = useState<boolean>(false);
    const maxFileSize = 2 * 1024 * 1024;
    const validFileFormats = ['pdf', 'jpeg', 'jpg', 'png', 'tiff', 'tif'];

    const mapFromValues = async (formValues: IdentityChangeFormValues): Promise<IdentityChange> => {
        const { lastName, birthName, frontFile, backFile } = formValues;
        const frontFilePromises: Promise<Base64File>[] = frontFile.map(file => getBase64(file));
        const backFilePromises: Promise<Base64File>[] = backFile.map(file => getBase64(file));

        return Promise.all([...frontFilePromises, ...backFilePromises]).then(files => ({
            lastName,
            birthName,
            frontFile: files[0],
            backFile: files[1],
        }));
    };

    const areFilesValid = (mappedValues: IdentityChange): boolean =>
        mappedValues.frontFile.size <= maxFileSize &&
        mappedValues.backFile.size <= maxFileSize &&
        validFileFormats.includes(getFileType(mappedValues.frontFile.filename)) &&
        validFileFormats.includes(getFileType(mappedValues.backFile.filename));

    const submit = async (formValues: IdentityChangeFormValues) => {
        onSubmit(formValues);
        const mappedValues = await mapFromValues(formValues);
        if (!areFilesValid(mappedValues)) return;
        setLoading(true);
        const changeNameEndpoint: string = isAbo ? getChangeNameEndpoint(UserType.AUTO_ABO) : getChangeNameEndpoint();
        CpDataApi.put(changeNameEndpoint, mappedValues)
            .then(() => {
                onSubmitSuccess();
            })
            .catch(error => {
                const parsedError = parseErrorResponse<WithDefaultCpIntegrationErrors<IdentityChangeError>>(error).code;
                setIdentityError(parsedError);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            {loading && <Spinner fullPage={true} />}
            <DataOverview title={t(`${translationPrefix}.title`)} withoutCardEffect={isContractView}>
                <SectionHeading level={3} subtitle={t(`${translationPrefix}.info`)} className={'u-mt'} />
                <Formik
                    initialValues={initialValues}
                    onSubmit={(values: IdentityChangeFormValues): void => {
                        submit(values);
                    }}
                    validationSchema={validationSchema(t)}
                >
                    {formik => (
                        <Form onSubmit={e => preventSubmit(e)} data-testid="editForm">
                            <Fieldset>
                                <Fieldset.Row>
                                    <Layout>
                                        <Layout.Item default="3/6" s="1/1">
                                            <ValidatedInput
                                                label={t(`${translationPrefix}.last-name.label`)}
                                                name="lastName"
                                                testId={testIds.lastName}
                                                type="text"
                                            />
                                        </Layout.Item>
                                        <Layout.Item default="3/6" s="1/1">
                                            <ValidatedInput
                                                label={t(`${translationPrefix}.birth-name.label`)}
                                                name="birthName"
                                                testId={testIds.birthName}
                                                type="text"
                                            />
                                        </Layout.Item>
                                    </Layout>
                                </Fieldset.Row>
                            </Fieldset>
                            <Fieldset>
                                <Fieldset.Row>
                                    <p
                                        className="u-mb"
                                        dangerouslySetInnerHTML={{
                                            __html: t(`${translationPrefix}.front-file.title`),
                                        }}
                                    />
                                    <p
                                        className="u-mb"
                                        dangerouslySetInnerHTML={{
                                            __html: t(`${translationPrefix}.front-file.text`),
                                        }}
                                    />
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: t(`${translationPrefix}.front-file.label`),
                                        }}
                                    />
                                    <FileUpload
                                        name={'frontFile'}
                                        maxFileSize={maxFileSize}
                                        validFileFormats={validFileFormats}
                                    />
                                </Fieldset.Row>
                            </Fieldset>
                            <Fieldset>
                                <Fieldset.Row>
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: t(`${translationPrefix}.back-file.label`),
                                        }}
                                    />
                                    <FileUpload
                                        name={'backFile'}
                                        maxFileSize={maxFileSize}
                                        validFileFormats={validFileFormats}
                                    />
                                </Fieldset.Row>
                            </Fieldset>
                            <ErrorModal error={identityError} onClose={() => setIdentityError(undefined)} />
                            <ButtonContainer nav className="u-mt">
                                <Button
                                    type="reset"
                                    secondary
                                    onClick={() => onCancel(formik.values)}
                                    testId={testIds.cancel}
                                >
                                    {t('translation:editableSectionNav.cancel')}
                                </Button>
                                <Button type="submit" onClick={formik.submitForm} testId={testIds.submit}>
                                    {t(`${translationPrefix}.change`)}
                                </Button>
                            </ButtonContainer>
                        </Form>
                    )}
                </Formik>
            </DataOverview>
        </>
    );
};
