import React from 'react';

import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';

import { PostboxUi } from './ui';
import { usePostbox } from './usePostbox';
import { usePostboxFourSales } from './usePostboxFourSales';
import { useTranslation } from 'react-i18next';
import {
    useAnalyticsPageViewTracker,
    MyDocumentsLoadingPlaceholder,
    Notification,
    NotificationStatus,
} from '@cp-shared-10/frontend-ui';
import { useMyProfileData } from '../my-profile/useMyProfileData';
import { CombinedContractType, isStandardContract } from '../../utils';
import { AutoAboContract } from '@cp-de/common';
import { Error, WithDefaultCpIntegrationErrors } from '@cp-shared-10/common-utilities';

const PostboxWithHandlers = withLoadingAndNoConnectionHandler(PostboxUi);

type PostboxProps = {
    allContracts?: CombinedContractType[];
    autoAboContracts?: AutoAboContract[];
    isLoadingContracts?: boolean;
    contractsLoadingError?: Error<WithDefaultCpIntegrationErrors<'MARKET_API_DEFAULT_BUSINESS_ERROR'>>;
    isFourSalesContractsLoading?: boolean;
    fourSalesContractsLoadingError?: Error<WithDefaultCpIntegrationErrors<'MARKET_API_DEFAULT_BUSINESS_ERROR'>>;
    isFetchingStandardAllowed?: boolean;
    isFetchingFourSalesAllowed?: boolean;
    contractId?: string;
};

export const Postbox: React.FC<PostboxProps> = ({
    contractId,
    allContracts,
    autoAboContracts,
    isLoadingContracts,
    contractsLoadingError,
    isFourSalesContractsLoading,
    fourSalesContractsLoadingError,
    isFetchingStandardAllowed,
    isFetchingFourSalesAllowed,
}) => {
    const { t } = useTranslation('postbox');

    const {
        data: postboxDocuments,
        isLoading: isDocumentsLoading,
        loadingError: loadingErrorPostboxDocuments,
    } = usePostbox(!!isFetchingStandardAllowed);
    const {
        data: postboxDocuments4S,
        isLoading: isDocumentsLoading4S,
        loadingError: loadingErrorPostboxDocuments4S,
    } = usePostboxFourSales(!!isFetchingFourSalesAllowed);
    const { isAod, isRac } = useMyProfileData();

    const documents = [...(postboxDocuments || []), ...(postboxDocuments4S || [])];

    useAnalyticsPageViewTracker('postbox', !!postboxDocuments);

    const isLoading = isDocumentsLoading || isLoadingContracts || isFourSalesContractsLoading || isDocumentsLoading4S;
    const postboxLoadingError =
        (isFetchingStandardAllowed &&
            isFetchingFourSalesAllowed &&
            !!loadingErrorPostboxDocuments &&
            !!loadingErrorPostboxDocuments4S) ||
        (!isFetchingFourSalesAllowed && !!loadingErrorPostboxDocuments) ||
        (!isFetchingStandardAllowed && !!loadingErrorPostboxDocuments4S);
    const loadingError = !!contractsLoadingError || !!fourSalesContractsLoadingError || postboxLoadingError;
    const isMixedCustomer =
        (isFetchingStandardAllowed && isFetchingFourSalesAllowed) ||
        ((isFetchingStandardAllowed || isFetchingFourSalesAllowed) &&
            (isAod || isRac || (Array.isArray(autoAboContracts) && !!autoAboContracts.length)));

    let contractNumber;

    if (contractId) {
        const contract = allContracts?.find(contract => contract.contractId === contractId);
        contractNumber = contract
            ? isStandardContract(contract)
                ? contract.contractNumber
                : contract.contractId
            : undefined;
    }

    let customerErrorMessageForNoConnection = undefined;

    if (isFetchingStandardAllowed && !!loadingErrorPostboxDocuments && !loadingErrorPostboxDocuments4S) {
        // custom error message when fetching only standard documents and fetch fails
        customerErrorMessageForNoConnection = t('error-messages.only-standard-cutomer-error', {
            id: contractNumber,
            category: 'customerPortalIncident',
        });
    } else if (!isFetchingStandardAllowed && !!loadingErrorPostboxDocuments4S) {
        // custom error message when fetching only 4S documents and fetch fails
        customerErrorMessageForNoConnection = t('error-messages.only-four-sales-error', {
            id: contractNumber,
            category: 'customerPortalIncident',
        });
    } else if (!!loadingErrorPostboxDocuments && !!loadingErrorPostboxDocuments4S) {
        // custom error message when standard and 4S document fetch fails
        customerErrorMessageForNoConnection = t('error-messages.mixed-cutomer-error', {
            id: contractNumber,
            category: 'customerPortalIncident',
        });
    }

    return (
        <>
            {isMixedCustomer && (
                <Notification
                    className="u-mb"
                    status={NotificationStatus.info}
                    text={t('mixed-customer-notification')}
                />
            )}
            {!loadingError && (!!loadingErrorPostboxDocuments || !!loadingErrorPostboxDocuments4S) && (
                <Notification
                    className="u-mb"
                    status={NotificationStatus.warning}
                    text={t('error-messages.dss-or-four-sales-error')}
                />
            )}

            <PostboxWithHandlers
                isLoading={isLoading}
                contracts={allContracts}
                contractNumber={contractNumber}
                postboxDocuments={documents}
                hasError={!!loadingError}
                errorText={customerErrorMessageForNoConnection}
                loadingPlaceholder={<MyDocumentsLoadingPlaceholder />}
            />
        </>
    );
};
