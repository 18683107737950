import { getDigitalOffersEndpoint, DigitalOfferDataFrontend } from '@cp-de/common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-10/common-utilities';
import { getSimpleDataFetchSlice } from '@cp-shared-10/frontend-integration';
import { CpDataApi } from 'cp-xhr';

const { reducer, fetchData } = getSimpleDataFetchSlice<DigitalOfferDataFrontend[], DefaultBusinessMarketApiErrorCode>({
    dataName: 'digitalOffer',
    fetchCallback() {
        return CpDataApi.post(getDigitalOffersEndpoint()).then(response => response.data);
    },
});

export default reducer;
export const fetchDigitalOffer = fetchData;
