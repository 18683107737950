import { dashboardPagePath } from '../../navigation/paths';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Layout, Wizard } from '@vwfs-bronson/bronson-react';
import { ArrearPaymentStep2 } from '../arrear-payment-step-2';
import { ArrearPaymentStep1 } from '../arrear-payment-step-1';
import { ArrearPaymentModal } from '../modal';
import { Contract, FinancialDetails, getArrearPaymentAgreementEndpoint } from '@cp-de/common';
import { NoConnectionNotification } from '../../notifications/no-connection/NoConnectionNotification';
import { CpDataApi } from 'cp-xhr';
import { useAnalyticsActionTracker } from '@cp-shared-10/frontend-ui';

export const ArrearPaymentUi: React.FC<{ contract?: Contract; financialDetails?: FinancialDetails }> = ({
    contract,
    financialDetails,
}) => {
    const history = useHistory();
    const [step, setActiveStep] = useState<number>(1);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const { onAction } = useAnalyticsActionTracker('onPay');

    if (
        !contract ||
        !financialDetails ||
        !contract.contractNumber ||
        !financialDetails.iban ||
        !contract.outstandingPayment
    )
        return <NoConnectionNotification />;

    const paymentInfo = {
        iban: financialDetails?.iban,
        contractNumber: contract?.contractNumber,
        outstandingPayment: contract?.outstandingPayment,
    };

    const steps = [
        {
            step: 1,
        },
        {
            step: 2,
        },
    ];

    const goBackToDashboard = (): void => history.push(dashboardPagePath());

    const submitArrearPayment = (): void => {
        onAction();
        CpDataApi.post(getArrearPaymentAgreementEndpoint(contract.contractId))
            .then(() => {
                setIsError(false);
                setShowModal(true);
            })
            .catch(() => {
                setIsError(true);
                setShowModal(true);
            });
    };

    const handleNext = (): void => {
        step === 2 ? submitArrearPayment() : setActiveStep(prevState => prevState + 1);
    };

    const handlePrev = (): void => {
        step === 1 || !step ? goBackToDashboard() : setActiveStep(prevState => prevState - 1);
    };

    return (
        <>
            <Wizard collapsedText="" collapsedCounter={step + '/' + steps.length}>
                {steps.map((_item: { step: number }, index: number) => (
                    <Wizard.Item key={index} done={index + 1 < step} active={index + 1 === step} />
                ))}
            </Wizard>
            <Layout center>
                {step === 1 && (
                    <Layout.Item testId={'arrear-payment-step1'}>
                        <ArrearPaymentStep1
                            handleNext={handleNext}
                            handlePrev={handlePrev}
                            outstandingPayment={paymentInfo.outstandingPayment}
                        />
                    </Layout.Item>
                )}
                {step === 2 && (
                    <Layout.Item testId={'arrear-payment-step2'}>
                        <ArrearPaymentStep2 handleNext={handleNext} handlePrev={handlePrev} paymentInfo={paymentInfo} />
                    </Layout.Item>
                )}
            </Layout>
            <ArrearPaymentModal
                shown={showModal}
                error={isError}
                onClose={(): void => (isError ? goBackToDashboard() : setShowModal(false))}
                onConfirm={goBackToDashboard}
            />
        </>
    );
};
