import { Moment } from 'moment-timezone';
import * as Yup from 'yup';
import { formatCpDate, WithDefaultBusinessMarketApiError } from '@cp-shared-10/common-utilities';
import { longDateFormat } from '@cp-de/common';
import { TFunction } from 'i18next';
import { Tab } from './enums';
import { isValidCountryCode, isValidLength } from './third-party-form/iban-validation-view/ibanValidations';
import { CpDataApi } from '../../../cp-xhr';
import { parseErrorResponse } from '@cp-shared-10/frontend-integration';
import { IbanValidationError } from './third-party-form/iban-validation-view/IbanValidationError';
import { BankData, getIbanValidationEndpoint, SignedData } from '@cp-shared-10/apis';
import { IbanStateHandler, SetIsValidating } from '../types';
import { AnyObject } from 'yup/lib/types';

export const errorMessageIds = {
    contractRedemptionDate: {
        isRequired: 'contract-redemption-date.required',
        invalidFormat: 'contract-redemption-date.format',
        isWeekend: 'contract-redemption-date.is-weekend',
        invalidDate: 'contract-redemption-date.invalid-date',
        consentRequired: 'checkbox.notification',
    },
};

const nextValidDate = (date: Moment, days: number): Moment => {
    while (days > 0) {
        date = date.add(1, 'days');
        if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
            days -= 1;
        }
    }
    return date;
};

const ibanValidationSchema = (t: TFunction, ibanStateHandler: IbanStateHandler, setIsValidating: SetIsValidating) => {
    const translationPrefix = 'iban-section';
    const translationIbanPrefix = `${translationPrefix}.bank-account-validation.iban-change-input`;
    const translationErrorPrefix = `${translationIbanPrefix}.error`;
    const { savedIban, setSavedIban, setSignedBankData } = ibanStateHandler;

    const getError = (errorCode: string, iban: string): Yup.ValidationError => {
        switch (errorCode) {
            case 'BFF_API_ERROR':
            case 'SAME_BANK_ACCOUNT':
            case 'INCORRECT_IBAN':
                return new Yup.ValidationError(t(`${translationErrorPrefix}.invalid-iban`), iban, 'iban');
            default:
                return new Yup.ValidationError(t(`${translationErrorPrefix}.iban-validator-unavailable`), iban, 'iban');
        }
    };

    const ibanValidation = Yup.string()
        .required(t(`${translationErrorPrefix}.required`))
        .test('validCountryCode', t(`${translationErrorPrefix}.invalid-iban`), isValidCountryCode)
        .matches(RegExp('^[A-Z]{2}[0-9]{2}[a-zA-Z0-9_ ]*$'), t(`${translationErrorPrefix}.invalid-iban`))
        .test('ibanLength', t(`${translationErrorPrefix}.invalid-iban`), isValidLength);

    return Yup.object().shape({
        iban: ibanValidation.test(
            'asyncIban',
            t(`${translationErrorPrefix}.invalid-iban`),
            async (iban: string | undefined) => {
                if (!iban) {
                    return false;
                }
                if (!ibanValidation.isValidSync(iban)) {
                    setSignedBankData(undefined);
                    setSavedIban({});
                    return true;
                }
                if (savedIban.iban === iban) {
                    if (!savedIban.error) {
                        return true;
                    }
                    return getError(savedIban.error, savedIban.iban);
                }
                await setIsValidating(true);
                return await CpDataApi.post(getIbanValidationEndpoint(), { iban })
                    .then(({ data }: { data: SignedData<BankData> }) => {
                        const { isValid } = data.data;
                        setSignedBankData(isValid ? data : undefined);
                        setSavedIban({ iban, error: isValid ? undefined : 'INCORRECT_IBAN' });
                        return isValid;
                    })
                    .catch(error => {
                        const errorCode = parseErrorResponse<WithDefaultBusinessMarketApiError<IbanValidationError>>(
                            error,
                        ).code;
                        setSavedIban({ iban, error: errorCode });
                        return getError(errorCode, iban);
                    })
                    .finally(async () => {
                        await setIsValidating(false);
                    });
            },
        ),
        consent: Yup.bool().oneOf([true], t(errorMessageIds.contractRedemptionDate.consentRequired)),
    });
};

export const validationSchema = (t: TFunction): Yup.ObjectSchema<AnyObject> => {
    const getStringValidationSchema = (field: string, maxLength?: number, type?: string): Yup.StringSchema => {
        const translation = type ? `address-information.${type}.${field}` : `address-information.${field}`;
        return maxLength
            ? Yup.string()
                  .trim()
                  .required(t(`${translation}.required`))
                  .max(maxLength, t(`${translation}.too-long`))
            : Yup.string()
                  .trim()
                  .required(t(`${translation}.required`));
    };

    const addressValidation = {
        streetName: getStringValidationSchema('street', 60),
        houseNumber: getStringValidationSchema('house-number', 10),
        zipCode: getStringValidationSchema('zip-code').min(5, t(`address-information.zip-code.too-short`)),
        locality: getStringValidationSchema('locality', 40),
    };

    return Yup.object().shape({
        contractRedemptionDate: Yup.string()
            .required(t(errorMessageIds.contractRedemptionDate.isRequired))
            .test('invalid format', t(errorMessageIds.contractRedemptionDate.invalidFormat), date =>
                formatCpDate(date, longDateFormat).isValid(),
            )
            .test('is weekend', t(errorMessageIds.contractRedemptionDate.isWeekend), date => {
                return (
                    formatCpDate(date, longDateFormat)
                        .toMoment()
                        .isoWeekday() < 6
                );
            })
            .test(
                'after 5 days from today',
                t(errorMessageIds.contractRedemptionDate.invalidDate, {
                    date: nextValidDate(formatCpDate().toMoment(), 5).format(longDateFormat),
                }),
                date =>
                    formatCpDate(date, longDateFormat)
                        .toMoment()
                        .isAfter(nextValidDate(formatCpDate().toMoment(), 4)),
            ),
        person: Yup.object().when(['isThirdPartyRequester', 'selectedTab'], {
            is: (requester: unknown, tab: Tab) => requester && tab === Tab.PERSON,
            then: Yup.object().shape({
                formOfAddress: getStringValidationSchema('form-of-address', undefined, 'person'),
                firstName: getStringValidationSchema('first-name', undefined, 'person'),
                lastName: getStringValidationSchema('last-name', undefined, 'person'),
                ...addressValidation,
            }),
        }),
        business: Yup.object().when(['isThirdPartyRequester', 'selectedTab'], {
            is: (requester: unknown, tab: Tab) => requester && tab === Tab.BUSINESS,
            then: Yup.object().shape({
                companyName: getStringValidationSchema('company-name', undefined, 'business'),
                ...addressValidation,
            }),
        }),
        accountHolder: Yup.string()
            .trim()
            .required(t('iban-section.account-holder-input.required')),
    });
};

export const getValidationSchema = (
    t: TFunction,
    setIsValidating: SetIsValidating,
    ibanStateHandler?: IbanStateHandler,
): Yup.ObjectSchema<AnyObject> => {
    return ibanStateHandler
        ? validationSchema(t).concat(ibanValidationSchema(t, ibanStateHandler, setIsValidating))
        : validationSchema(t);
};
