import React from 'react';

import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';

import { UsageAgreementUi } from './ui';
import { useUsageAgreement } from './useUsageAgreement';
import { StaticPageLoadingPlaceholder } from '@cp-shared-10/frontend-ui';

export const UsageAgreement: React.FC = () => {
    const { cmsContent: usageAgreement, isLoading, loadingError } = useUsageAgreement();

    const UsageAgreementWithHandlers = withLoadingAndNoConnectionHandler(UsageAgreementUi);

    return (
        <UsageAgreementWithHandlers
            isLoading={isLoading}
            usageAgreement={usageAgreement}
            hasError={!!loadingError}
            loadingPlaceholder={<StaticPageLoadingPlaceholder />}
        />
    );
};
