import React, { useState } from 'react';
import {
    AoDContract,
    getCancelReservationEndpoint,
    RaCContract,
    ContractType,
    UserType,
    CancelReservationEmailRequest,
} from '@cp-de/common';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Button, ButtonContainer, Card, Fieldset, Form, Layout, Modal } from '@vwfs-bronson/bronson-react';
import {
    ContractHeader as ContractHeaderShared,
    Spinner,
    ValidatedCheckbox,
    preventSubmit,
} from '@cp-shared-10/frontend-ui';
import { getCarBrandAndModel } from '../../contracts/utils';
import { Formik } from 'formik';
import { dashboardPagePath } from '../../navigation/paths';
import { getCancelReservationInitialValues } from './initialValues';
import { CpDataApi } from '../../../cp-xhr';
import { getAbsoluteUrlWithParams } from '../../../utils/urlSearchParams';
import { createCancelReservationEmailRequest } from './helpers';

type CancelReservationProps = { reservationContract?: AoDContract | RaCContract };

export const CancelReservationUi: React.FC<CancelReservationProps> = ({ reservationContract }) => {
    const { t, i18n } = useTranslation('cancel-reservation');
    const history = useHistory();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [modalStatus, setModalStatus] = useState<'success' | 'error' | undefined>(undefined);

    const language = i18n.languages[0];
    if (!reservationContract) {
        return null;
    }

    const { details, contractNumber, contractId, contractType } = reservationContract;
    const { carBrand, carModel } = details?.vehicleDetails || {};

    const isAoD = contractType === ContractType.AUDI_ON_DEMAND;

    const closeModal = (): void => {
        if (modalStatus === 'success') window.location.assign(getAbsoluteUrlWithParams(dashboardPagePath()));
        else setModalStatus(undefined);
    };

    const handleSubmit = (): void => {
        setIsSubmitting(true);

        const request: CancelReservationEmailRequest = createCancelReservationEmailRequest(t, reservationContract);

        CpDataApi.post(getCancelReservationEndpoint(contractId, isAoD ? UserType.AOD : UserType.RAC), request)
            .then(() => {
                setModalStatus('success');
            })
            .catch(() => {
                setModalStatus('error');
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    return (
        <>
            {isSubmitting && <Spinner center fullPage />}
            <Card element="article">
                <ContractHeaderShared
                    carInformation={getCarBrandAndModel(carBrand, carModel, language)}
                    contractCategory={isAoD ? t('contract-type.aod') : t('contract-type.rac')}
                    contractNumber={contractNumber}
                />
            </Card>
            <Formik initialValues={getCancelReservationInitialValues()} onSubmit={handleSubmit}>
                {formik => (
                    <Form onSubmit={e => preventSubmit(e)}>
                        <Fieldset className={'u-mt'}>
                            <Fieldset.Row>
                                <Layout>
                                    <Layout.Item default={'1/1'}>
                                        <ValidatedCheckbox
                                            label={t('form.checkbox.label', {
                                                reservationId: contractNumber,
                                            })}
                                            name={'terminate'}
                                            testId={'terminate'}
                                        />
                                    </Layout.Item>
                                </Layout>
                            </Fieldset.Row>
                            <Fieldset>
                                <Fieldset.Row>
                                    <ButtonContainer center>
                                        <Button
                                            type="button"
                                            secondary
                                            onClick={(): void => history.push(dashboardPagePath())}
                                            testId="cancelButton"
                                        >
                                            {t('form.go-back')}
                                        </Button>
                                        <Button
                                            onClick={formik.submitForm}
                                            testId="submitButton"
                                            type="submit"
                                            disabled={!formik.values.terminate}
                                        >
                                            {t('form.confirm')}
                                        </Button>
                                    </ButtonContainer>
                                </Fieldset.Row>
                            </Fieldset>
                        </Fieldset>
                    </Form>
                )}
            </Formik>
            <Modal
                shown={!!modalStatus}
                buttonConfirmText={
                    modalStatus === 'success' ? t(`modal.success.button`) : t('translation:editableSectionNav.close')
                }
                onConfirm={closeModal}
                onClose={closeModal}
                title={t(`modal.${modalStatus}.title`)}
                status={modalStatus}
                testId={`${modalStatus}-modal`}
            >
                {t(`modal.${modalStatus}.text`)}
            </Modal>
        </>
    );
};
