import { Greeting, UserType } from '@cp-de/common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-10/common-utilities';
import { AbstractDataState, useGetSimpleApiData } from '@cp-shared-10/frontend-integration';
import { GreetingDataSelector } from './GreetingDataSelector';
import { fetchGreeting } from './GreetingSlice';
import { useUserTypeFlag } from '../../utils/useUserTypeFlag';

function useGreetingData(
    userType?: UserType,
    isFetchingAllowed = true,
): AbstractDataState<Greeting, DefaultBusinessMarketApiErrorCode> {
    return useGetSimpleApiData(
        fetchGreeting,
        GreetingDataSelector,
        false,
        {
            params: userType,
        },
        undefined,
        isFetchingAllowed,
    );
}

export const useGreeting = (): {
    data?: Greeting;
    isLoading: boolean;
} => {
    const { userType, isLoading } = useUserTypeFlag();
    const { data: greeting, isLoading: isLoadingGreeting } = useGreetingData(userType, !!userType);
    return {
        data: greeting,
        isLoading: isLoading || isLoadingGreeting,
    };
};
