import { RaCContract } from '@cp-de/common';
import { AbstractDataState, useGetSimpleApiData } from '@cp-shared-10/frontend-integration';

import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-10/common-utilities';
import { RaCContractsDataSelector } from './RaCContractsDataSelector';
import { fetchRaCContracts } from './RaCContractsSlice';

export function useRaCContracts(
    isFetchingAllowed = true,
): AbstractDataState<RaCContract[], DefaultBusinessMarketApiErrorCode> {
    return useGetSimpleApiData(
        fetchRaCContracts,
        RaCContractsDataSelector,
        false,
        undefined,
        undefined,
        isFetchingAllowed,
    );
}
