import React from 'react';
import { CustomerReservationIdentity } from '@cp-de/common';
import {
    DefinitionListHorizontal,
    DefinitionListItem,
    Notification,
    NotificationStatus,
} from '@cp-shared-10/frontend-ui';
import { Heading, Layout } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';

export type ConsultViewProps = {
    identityItems: CustomerReservationIdentity;
    successMessage?: string;
    successMessageTitle?: string;
    startEditing: () => void;
};

export const ConsultView: React.FC<ConsultViewProps> = ({
    identityItems,
    successMessage,
    successMessageTitle,
    // startEditing,
}) => {
    const { t } = useTranslation();

    if (!Object.keys(identityItems).length) {
        return null;
    }

    const getItemList = ({ firstName = '', lastName = '' }): DefinitionListItem[] => [
        {
            label: t('auto-abo-customer-details:identity.name'),
            value: firstName && lastName ? `${firstName} ${lastName}` : '-',
            testId: 'fullName',
        },
    ];

    return (
        <>
            <Layout>
                <Layout.Item default="1/2">
                    <Heading level={6} className="u-pt">
                        {t('auto-abo-customer-details:identity.headline')}
                    </Heading>
                </Layout.Item>
                {/* TODO return code below after BE is fixed */}
                {/* <Layout.Item default="1/2" className={'u-text-right'}>
                    <Button icon={'semantic-edit'} type="button" link simple onClick={startEditing} testId="editName" />
                </Layout.Item> */}
            </Layout>
            <DefinitionListHorizontal list={getItemList(identityItems)} className={'u-mb u-mt-small'} />
            {successMessage && (
                <Notification
                    className={'u-mt'}
                    testId={'success-notification'}
                    status={NotificationStatus.success}
                    text={successMessage}
                    headline={successMessageTitle}
                />
            )}
        </>
    );
};
