import React from 'react';
import { useTranslation } from 'react-i18next';

import { withLoadingAndNoConnectionHandler } from '../../integration-wrapper';
import { RegistrationFormUi } from './ui';
import { RegistrationLoadingPlaceholder } from '@cp-shared-10/frontend-ui';
import { usePrivacyPolicy } from '../../privacy-policy';
import { useUsageAgreement } from '../../usage-agreement';
import { useConsent } from '../../consent';

const RegistrationFormWithHandlers = withLoadingAndNoConnectionHandler(RegistrationFormUi);

export const RegistrationForm: React.FC = () => {
    const { data: consent, isLoading: isLoadingConsent, loadingError: loadingErrorConsent } = useConsent();

    const {
        cmsContent: privacyPolicy,
        isLoading: isLoadingPrivacyPolicy,
        loadingError: loadingErrorPrivacyPolicy,
    } = usePrivacyPolicy();
    const {
        cmsContent: usageAgreement,
        isLoading: isLoadingUsageAgreement,
        loadingError: loadingErrorUsageAgreement,
    } = useUsageAgreement();
    const { t } = useTranslation('registration');

    const isLoading = isLoadingPrivacyPolicy || isLoadingUsageAgreement || isLoadingConsent;
    const loadingError = loadingErrorPrivacyPolicy || loadingErrorUsageAgreement || loadingErrorConsent;

    return (
        <>
            <p className={'u-text-left'}>{t('form.info-text')}</p>
            <RegistrationFormWithHandlers
                isLoading={isLoading}
                errorTitle={''}
                errorText={t('error')}
                consent={consent}
                privacyPolicy={privacyPolicy?.content}
                usageAgreement={usageAgreement?.content}
                hasError={!!loadingError}
                loadingPlaceholder={<RegistrationLoadingPlaceholder />}
            />
        </>
    );
};
