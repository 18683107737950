import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Button, ButtonContainer, Form, Heading, Layout, Modal, Spinner } from '@vwfs-bronson/bronson-react';
import {
    CleaveInput,
    preventSubmit,
    useAnalyticsFormTracker,
    ValidatedInput,
    ValidatedSelect,
    ValidatedSelectItem,
} from '@cp-shared-10/frontend-ui';
import { useTranslationWithFormatting } from '../../../../localization/useTranslationWithFormatting';
import { AddContractValues, initialValues } from './initialValues';
import { TFunction } from 'i18next';
import {
    AddContractProductType,
    addContractValidationSchema,
    Contract,
    CustomerRegistrationError,
    getAddContractEndpoint,
    Registration,
    TooManyRequestsError,
} from '@cp-de/common';
import { dashboardPagePath } from '../../../navigation/paths';
import { getAbsoluteUrlWithParams, getUrlSearchParams } from '../../../../utils/urlSearchParams';
import { WithDefaultCpIntegrationErrors } from '@cp-shared-10/common-utilities';
import { CpDataApi } from '../../../../cp-xhr';
import { parseErrorResponse } from '@cp-shared-10/frontend-integration';
import { AddContractError } from './add-contract-error';
import { useHistory } from 'react-router-dom';
import { useMyProfile } from '../../../my-profile';
import { addContractSuccessfulStorageKey } from '../../../../config';

type AddContractFormProps = {
    contracts?: Contract[];
};

type RequestStateProps = {
    isLoading: boolean;
    errorCode?: WithDefaultCpIntegrationErrors<CustomerRegistrationError | TooManyRequestsError>;
    errorMessage?: string;
};

export const testIds = {
    lastName: 'lastName',
    birthDate: 'birthDate',
    zipCode: 'zipCode',
    contractNumber: 'contractNumber',
    product: 'product',
    submitButton: 'submitButton',
    backButton: 'backButton',
    modal: 'modal',
};

export const AddContractForm: React.FC<AddContractFormProps> = ({ contracts }) => {
    const { t } = useTranslationWithFormatting('add-contract');
    const addContractRef = React.useRef<null | HTMLDivElement>(null);
    const history = useHistory();
    const { onConfirmError } = useAnalyticsFormTracker({
        formValidationError: 'onAddContractError',
    });
    const [requestState, setRequestState] = useState<RequestStateProps>({
        isLoading: false,
        errorCode: undefined,
        errorMessage: undefined,
    });
    const [showModal, setShowModal] = useState<boolean>(false);
    const [is4Sales, setIs4Sales] = useState<boolean>(false);

    useEffect(() => {
        if (typeof addContractRef?.current?.scrollIntoView === 'function') {
            addContractRef.current.scrollIntoView();
        }
    }, [addContractRef]);

    const getProductsArray = (): string[] => {
        if (!contracts?.length) {
            return [
                AddContractProductType.FOUR_SALES,
                AddContractProductType.LEASE,
                AddContractProductType.FINANCING,
                AddContractProductType.SERVICE_MANAGEMENT,
            ];
        } else {
            return [AddContractProductType.FOUR_SALES];
        }
    };

    const getAvailableProducts = (t: TFunction): ValidatedSelectItem[] =>
        getProductsArray().map(type => {
            const translationType = type.toLowerCase().replace(/_/g, '-');
            return {
                label: t(`product.type.${translationType}`),
                value: type,
            };
        });

    const redirectUrl = getUrlSearchParams().get('redirectUrl') || getAbsoluteUrlWithParams(dashboardPagePath());

    const { data: myProfileData } = useMyProfile();

    const handleSubmit = (values: AddContractValues): void => {
        if (values.product) {
            setRequestState({ errorCode: undefined, isLoading: true });
            const { lastName, birthDate, zipCode, contractNumber, product } = values;
            const request: Registration = { lastName, birthDate, zipCode, contractNumber };

            CpDataApi.post(getAddContractEndpoint(product), request)
                .then(() => {
                    setRequestState({ isLoading: false });
                    window.localStorage.setItem(addContractSuccessfulStorageKey, 'true');
                    window.location.assign(redirectUrl);
                })
                .catch(error => {
                    const errorMessage = error.response?.data?.message;
                    let errorCode = parseErrorResponse<CustomerRegistrationError | TooManyRequestsError>(error).code;
                    if (error.response?.data === 'ThrottlerException: Too Many Requests') {
                        errorCode = 'TOO_MANY_REQUESTS';
                    }
                    setRequestState({ isLoading: false, errorCode, errorMessage });
                });
        }
    };

    const getValidationSchema = (is4Sales: boolean) => {
        const contractNumberFormatError = t('validation.contract-number.format');
        return addContractValidationSchema(is4Sales, {
            contractNumber: {
                empty: t('validation.contract-number.empty'),
                min: contractNumberFormatError,
                max: contractNumberFormatError,
                format: contractNumberFormatError,
                format4Sales: contractNumberFormatError,
            },
            zipCode: {
                empty: t('validation.zip-code.empty'),
                format: t('validation.zip-code.format'),
            },
            lastName: t('validation.last-name'),
            birthDate: {
                empty: t('validation.birth-date.empty'),
                format: t('validation.birth-date.format'),
                futureDate: t('validation.birth-date.future-date'),
                underage: t('validation.birth-date.underage'),
            },
            product: t('validation.product.required'),
        });
    };

    return (
        <div className={'u-pr u-pr-none@m u-mb-large'} ref={addContractRef}>
            <Heading level={3}>{t('title')}</Heading>
            <AddContractError errorCode={requestState.errorCode} errorMessage={requestState.errorMessage} />
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={getValidationSchema(is4Sales)}
            >
                {formik => (
                    <Form onSubmit={e => preventSubmit(e)} data-testid="addContractForm">
                        <Layout>
                            <Layout.Item>
                                <ValidatedSelect
                                    name="product"
                                    testId={testIds.product}
                                    id="add-contract-form-product"
                                    label={t('product.label')}
                                    emptyByDefault
                                    selectItems={getAvailableProducts(t)}
                                    onChange={(e: string): void => {
                                        if (e === AddContractProductType.FOUR_SALES) {
                                            setIs4Sales(true);
                                            const newValue = 'VM'.concat('', formik.values.contractNumber);
                                            formik.setFieldValue('contractNumber', newValue);
                                            formik.setFieldValue('lastName', myProfileData?.identity?.lastName);
                                            formik.setFieldValue('zipCode', myProfileData?.address?.zipCode);
                                        } else if (formik.values.contractNumber.match(RegExp('^VM'))) {
                                            setIs4Sales(false);
                                            const newValue = formik.values.contractNumber.substring(2);
                                            formik.setFieldValue('contractNumber', newValue);
                                            formik.setFieldValue('lastName', initialValues.lastName);
                                            formik.setFieldValue('zipCode', initialValues.zipCode);
                                        } else {
                                            setIs4Sales(false);
                                        }
                                    }}
                                />
                            </Layout.Item>
                            <Layout.Item>
                                <ValidatedInput
                                    disabled={is4Sales && myProfileData?.identity?.lastName !== undefined}
                                    label={t('last-name.label')}
                                    inputId="add-contract-form-last-name"
                                    name="lastName"
                                    testId={testIds.lastName}
                                    type="text"
                                />
                            </Layout.Item>
                            <Layout.Item default="1/2" s="1/1">
                                <CleaveInput
                                    cleaveOptions={{
                                        delimiter: '.',
                                        blocks: [2, 2, 4],
                                        numericOnly: true,
                                    }}
                                    inputMode={'numeric'}
                                    label={t('birth-date.label')}
                                    name={'birthDate'}
                                    testId={testIds.birthDate}
                                    inputId="add-contract-form-birth-date"
                                />
                            </Layout.Item>
                            <Layout.Item default="1/2" s="1/1">
                                <CleaveInput
                                    disabled={is4Sales && myProfileData?.address?.zipCode !== undefined}
                                    cleaveOptions={{
                                        delimiter: '',
                                        blocks: [5],
                                        numericOnly: true,
                                    }}
                                    inputMode={'numeric'}
                                    label={t('zip-code.label')}
                                    name={'zipCode'}
                                    testId={testIds.zipCode}
                                    inputId="add-contract-form-zip-code"
                                />
                            </Layout.Item>
                            <Layout.Item>
                                <ValidatedInput
                                    label={t('contract-number.label')}
                                    hint={
                                        <a onClick={(): void => setShowModal(true)}>
                                            {t('contract-number.link-label')}
                                        </a>
                                    }
                                    name={'contractNumber'}
                                    testId={testIds.contractNumber}
                                    inputId="add-contract-form-contract-number"
                                    withHtmlValidationWarning
                                />
                            </Layout.Item>
                            <Layout.Item>
                                <ButtonContainer center>
                                    <Button
                                        secondary
                                        onClick={(): void => history.push(dashboardPagePath())}
                                        testId={testIds.backButton}
                                    >
                                        {t('button.back')}
                                    </Button>
                                    <Button
                                        type="submit"
                                        onClick={() => {
                                            formik.submitForm();
                                            onConfirmError(formik.errors, formik.touched);
                                        }}
                                        testId={testIds.submitButton}
                                    >
                                        {t('button.submit')}
                                    </Button>
                                </ButtonContainer>
                            </Layout.Item>
                        </Layout>
                    </Form>
                )}
            </Formik>
            <Modal
                shown={showModal}
                buttonConfirmText={t('modal.close')}
                onClose={(): void => setShowModal(false)}
                onConfirm={(): void => setShowModal(false)}
                testId={testIds.modal}
            >
                <h3>{t('modal.title-1')}</h3>
                <p>{t('modal.text-1')}</p>
                <h3>{t('modal.title-2')}</h3>
                <p>{t('modal.text-2')}</p>
                <h3>{t('modal.title-3')}</h3>
                <p>{t('modal.text-3')}</p>
                <h3>{t('modal.title-4')}</h3>
                <p>{t('modal.text-4')}</p>
            </Modal>
            {requestState.isLoading && <Spinner fullPage={true}>{t('spinner-text')}</Spinner>}
        </div>
    );
};
