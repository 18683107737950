import { CpDataApi } from 'cp-xhr';
import React from 'react';

import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-10/frontend-storybook-extensions';
import { DefaultBusinessMarketApiError, DefaultBusinessMarketApiErrorCode } from '@cp-shared-10/common-utilities';

import { getPostboxDocumentsEndpoint, UserType } from '@cp-de/common';

import { PostboxWithAllValues } from './ExampleData';
import { ContractsMock } from '../contracts';
import { MockResponses as DownloadMockResponses } from './ui/PostboxDownloadResponseMocks';

const mockOptions: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: PostboxWithAllValues,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const PostboxMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: '',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions,
    url: getPostboxDocumentsEndpoint(UserType.STANDARD),
};

export const PostboxResponseMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [PostboxMock, ContractsMock, ...DownloadMockResponses]);

    return <div>{storyFn()}</div>;
};
