import { AoDContract } from '@cp-de/common';
import { AbstractDataState, useGetSimpleApiData } from '@cp-shared-10/frontend-integration';

import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-10/common-utilities';
import { fetchAoDContracts } from './AoDContractsSlice';
import { AoDContractsDataSelector } from './AoDContractsDataSelector';

export function useAoDContracts(
    isFetchingAllowed = true,
): AbstractDataState<AoDContract[], DefaultBusinessMarketApiErrorCode> {
    return useGetSimpleApiData(
        fetchAoDContracts,
        AoDContractsDataSelector,
        false,
        undefined,
        undefined,
        isFetchingAllowed,
    );
}
