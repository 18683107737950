import React from 'react';
import { Layout, Input } from '@vwfs-bronson/bronson-react';
import { CleaveInput, ValidatedInput, ValidatedSelect, ValidatedSelectItem } from '@cp-shared-10/frontend-ui';
import { useTranslation } from 'react-i18next';
import { FormOfAddress } from '../../enums';

export const PrivateAddressForm: React.FC = () => {
    const { t } = useTranslation('total-early-settlement');
    const selectItems: ValidatedSelectItem[] = [
        { value: FormOfAddress.MS, label: t('address-information.person.form-of-address.ms') },
        { value: FormOfAddress.MR, label: t('address-information.person.form-of-address.mr') },
    ];
    return (
        <Layout>
            <Layout.Item default="1/3" s="1/1">
                <ValidatedSelect
                    label={t('address-information.person.form-of-address.label')}
                    name={'person.formOfAddress'}
                    id={'form-of-address-selection'}
                    testId={'form-of-address-selection'}
                    selectItems={selectItems}
                    emptyByDefault={true}
                />
            </Layout.Item>
            <Layout.Item default="1/3" s="1/1">
                <ValidatedInput
                    label={t('address-information.person.first-name.label')}
                    name="person.firstName"
                    testId="person-first-name"
                    type="text"
                />
            </Layout.Item>
            <Layout.Item default="1/3" s="1/1">
                <ValidatedInput
                    label={t('address-information.person.last-name.label')}
                    name="person.lastName"
                    testId="person-last-name"
                    type="text"
                />
            </Layout.Item>
            <Layout.Item default="2/3" s="1/1">
                <ValidatedInput
                    label={t('address-information.street.label')}
                    name="person.streetName"
                    testId="person-street"
                    type="text"
                />
            </Layout.Item>
            <Layout.Item default="1/3" s="1/1">
                <ValidatedInput
                    label={t('address-information.house-number.label')}
                    name="person.houseNumber"
                    testId="person-house-number"
                    type="text"
                />
            </Layout.Item>
            <Layout.Item default="1/3" s="1/1">
                <CleaveInput
                    cleaveOptions={{
                        delimiter: '',
                        blocks: [5],
                        numericOnly: true,
                    }}
                    inputMode={'numeric'}
                    label={t('address-information.zip-code.label')}
                    name="person.zipCode"
                    testId="person-zip-code"
                />
            </Layout.Item>
            <Layout.Item default="1/3" s="1/1">
                <ValidatedInput
                    label={t('address-information.locality.label')}
                    name="person.locality"
                    testId="person-locality"
                    type="text"
                />
            </Layout.Item>
            <Layout.Item default="1/3" s="1/1">
                <div className={'u-mb-xsmall'}>{t('address-information.country.label')}</div>
                <Input disabled value={`${t('address-information.country.value')}`} testId={'person-country'} />
            </Layout.Item>
        </Layout>
    );
};
