import { CustomerReservationDetails } from '@cp-de/common';
import { useAnalyticsActionTracker } from '@cp-shared-10/frontend-ui';
import { ConsultView } from './consult-view/ConsultView';
import { EditView } from './edit-view/EditView';
import { changeAutoAboIdentityPath } from '../../../../../navigation/paths';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';

type IdentitySectionProps = { details: CustomerReservationDetails };

export const IdentitySection: React.FC<IdentitySectionProps> = ({ details }) => {
    const history = useHistory();
    const { path } = useRouteMatch();
    const { t, ready } = useTranslation('auto-abo-customer-details', { useSuspense: false });
    const [showEditSuccessMessage, setShowEditSuccessMessage] = useState<boolean>(false);

    const { identity = {} } = details;

    const backToNonEditMode = (): void => {
        history.push(path);
    };

    const { onAction } = useAnalyticsActionTracker('onEditProfileContact');
    const { onAction: onSuccess } = useAnalyticsActionTracker('onEditProfileContactSuccess');

    const startEditing = (): void => {
        onAction();
        setShowEditSuccessMessage(false);
        history.push(changeAutoAboIdentityPath());
    };

    return (
        <>
            {ready && (
                <Switch>
                    <Route exact path={changeAutoAboIdentityPath()}>
                        <EditView
                            setShowEditSuccessMessage={setShowEditSuccessMessage}
                            backToNonEditMode={backToNonEditMode}
                            onSuccess={onSuccess}
                        />
                    </Route>
                    <Route path={path}>
                        <ConsultView
                            identityItems={identity}
                            successMessage={showEditSuccessMessage ? t('identity.change-identity.text') : undefined}
                            successMessageTitle={
                                showEditSuccessMessage ? t('identity.change-identity.title') : undefined
                            }
                            startEditing={startEditing}
                        />
                    </Route>
                </Switch>
            )}
        </>
    );
};
