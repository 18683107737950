import React from 'react';
import { useTranslation } from 'react-i18next';
import { MyRequestUi } from './ui';
import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';
import { RequestLoadingPlaceholder, useAnalyticsPageViewTracker } from '@cp-shared-10/frontend-ui';
import { useMyProfileData } from '../my-profile';
import { useUserContractsDataAndFlags } from '../../Providers';
import { sortAutoAboContracts } from '../../utils';

const MyRequestWithHandlers = withLoadingAndNoConnectionHandler(MyRequestUi);

export const MyRequest: React.FC = () => {
    const { t } = useTranslation('my-request');

    useAnalyticsPageViewTracker('requests');

    const {
        contracts: { data: contracts, isLoading: isContractsLoading, loadingError: contractsLoadingError },
        aboContracts: { data: aboContracts, isLoading: isAboContractsLoading, loadingError: aboContractsLoadingError },
    } = useUserContractsDataAndFlags();

    const { data: myProfile, isLoading: isMyProfileLoading, isAod, isRac } = useMyProfileData();

    const isLoading = isContractsLoading || isAboContractsLoading || isMyProfileLoading;
    const loadingError = contractsLoadingError || aboContractsLoadingError;
    const sortedAutoAboContracts = sortAutoAboContracts(aboContracts);

    return (
        <MyRequestWithHandlers
            myProfile={myProfile}
            contracts={contracts}
            aboContracts={sortedAutoAboContracts}
            isLoading={isLoading}
            errorText={t('error.no-connection')}
            hasError={!!loadingError}
            loadingPlaceholder={<RequestLoadingPlaceholder withTextArea numberOfButtons={1} />}
            isAod={isAod}
            isRac={isRac}
        />
    );
};
