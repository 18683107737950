import { useMemo } from 'react';
import { AbstractDataState, useGetSimpleApiDataWithTransformer } from '@cp-shared-10/frontend-integration';
import { fetchContracts } from './ContractsSlice';
import { ContractsDataSelector } from './ContractsDataSelector';
import { Contract } from '@cp-de/common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-10/common-utilities';

type ContractTransformer = (contracts?: Contract[]) => Contract | undefined;

function getFilterForContractId(contractId: string): ContractTransformer {
    return contracts => (contracts ? contracts.filter(contract => contract.contractId === contractId)[0] : undefined);
}
/**
 * Fetches the contracts and filters for the contract with given contract id. If not found, undefined will be returned as data.
 * @param contractId Contract ID to get the contract for.
 */
export function useContract(
    contractId: string,
): AbstractDataState<Contract | undefined, DefaultBusinessMarketApiErrorCode> {
    const contractFilter = useMemo(() => getFilterForContractId(contractId), [contractId]);
    return useGetSimpleApiDataWithTransformer(fetchContracts, ContractsDataSelector, contractFilter);
}
