import React from 'react';
import { LoginChoice, LoginOptions } from '@cp-de/common';
import { Hr, Layout, Logo, ContentSection } from '@vwfs-bronson/bronson-react';
import { Spinner, useAnalyticsActionTracker, useAuthentication } from '@cp-shared-10/frontend-ui';
import { useTranslation } from 'react-i18next';
import { loginInProgressPagePath } from '../../navigation/paths';
import { ExternalLoginButton } from './external-login-button';
import { getAbsoluteUrlWithParams, getUrlSearchParams } from '../../../utils/urlSearchParams';
// eslint-disable-next-line import/named
import { capitalize } from 'lodash';
import { LoginChoiceForm } from './LoginChoiceForm';
import { idpHints } from './idpHints';

export const LoginChoiceUi: React.FC<{ loginChoice?: LoginChoice }> = ({ loginChoice }) => {
    const { i18n } = useTranslation();
    const { login } = useAuthentication();
    const language = i18n.languages[0];

    const { onAction } = useAnalyticsActionTracker('onExternalLogin');

    const preferredIdp = getUrlSearchParams().get('idp') as LoginOptions | null;
    const email = getUrlSearchParams().get('email');

    const commonLoginOptions = {
        redirectUri: getAbsoluteUrlWithParams(loginInProgressPagePath()),
        locale: language,
    };

    const redirectToIdentityKit = (email: string) => {
        onAction('VWFS');
        setTimeout(() => {
            login({
                ...commonLoginOptions,
                loginHint: email,
                idpHint: 'identitykit',
                prompt: 'login',
            });
        }, 200);
    };

    const redirectToExternalLogin = (loginOption: LoginOptions, loginHint = '') => {
        onAction(capitalize(loginOption));
        setTimeout(() => {
            login({
                ...commonLoginOptions,
                loginHint,
                idpHint: idpHints[loginOption],
            });
        }, 200);
    };

    if (!loginChoice) {
        return null;
    }

    if (preferredIdp) {
        redirectToExternalLogin(preferredIdp, email || undefined);
        return <Spinner fullPage center />;
    }

    return (
        <>
            <Logo className={'u-block-center'} />
            <ContentSection>
                <Layout>
                    <Layout.Item className={'u-text-center'}>
                        <h1>{loginChoice.headline}</h1>
                        <p dangerouslySetInnerHTML={{ __html: loginChoice.description }} />
                        {loginChoice.description2 && (
                            <p dangerouslySetInnerHTML={{ __html: loginChoice.description2 }} />
                        )}
                    </Layout.Item>
                    <Layout.Item>
                        <LoginChoiceForm loginChoice={loginChoice} redirectToIdentityKit={redirectToIdentityKit} />
                    </Layout.Item>
                    <Layout.Item>
                        <Hr />
                        <div className={'u-text-center'}>{loginChoice.separator}</div>
                    </Layout.Item>
                    <Layout.Item>
                        <Layout center>
                            {Object.values(LoginOptions).map((loginOption: LoginOptions) => (
                                <ExternalLoginButton
                                    imgSrc={loginChoice.loginSelectorImages[loginOption]}
                                    label={loginChoice.loginSelectorLabels[loginOption]}
                                    onClick={() => {
                                        redirectToExternalLogin(loginOption);
                                    }}
                                    loginOption={loginOption}
                                    key={loginOption}
                                    buttonsAriaLabels={loginChoice.loginSelectorButtons[loginOption]}
                                />
                            ))}
                        </Layout>
                    </Layout.Item>
                </Layout>
            </ContentSection>
        </>
    );
};
