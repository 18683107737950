import React, { useCallback } from 'react';
import { ComboboxOption } from 'types/bronson-react';
import { Combobox, FormField } from '@vwfs-bronson/bronson-react';
import { AutoAboContract, Contract } from '@cp-de/common';
import { LicensePlate } from '../../license-plate';
import { useTranslation } from 'react-i18next';
import { isDirectCredit } from '../../../utils';

type ContractSelectProps = {
    contracts?: Contract[];
    aboContracts?: AutoAboContract[];
    labelText: string;
    contractOnChange: (value: string) => void;
    contractNumber?: string;
};

export const ContractSelect: React.FC<ContractSelectProps> = ({
    contracts,
    aboContracts,
    labelText,
    contractOnChange,
    contractNumber,
}) => {
    const { t } = useTranslation('contracts');
    const handleOnChange = useCallback((selected: ComboboxOption[]): void => {
        if (Array.isArray(selected) && selected.length) {
            contractOnChange(selected[0].value);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    const getCustomSelectItem = (
        contractNumber: string,
        licensePlate?: string,
        carBrand?: string,
        carModel?: string,
        isDirectCreditGroup = false,
        isInReservation = false,
    ): JSX.Element => {
        const licensePlateComponent = ((!isInReservation && licensePlate) || !isDirectCreditGroup) && (
            <LicensePlate licensePlate={licensePlate} className="u-text-base" />
        );

        return (
            <Combobox.Item
                optionValue={contractNumber}
                optionKey={contractNumber}
                testId={contractNumber}
                key={contractNumber}
            >
                {licensePlateComponent ? (
                    <div>
                        {licensePlateComponent}
                        <br />
                        <strong>{contractNumber}</strong>
                        <br />
                        {`${carBrand || ''} ${carModel || ''}`}
                    </div>
                ) : (
                    <div>
                        <strong>{t('direct-credit-title')}</strong>
                        <br />
                        {contractNumber}
                    </div>
                )}
            </Combobox.Item>
        );
    };
    const getCustomSelectItems = (): JSX.Element[] => {
        const customSelectItemContracts = (contracts || []).map(contract => {
            const isDirectCreditGroup = isDirectCredit(contract);
            const { licensePlate, carBrand, carModel } = contract.vehicle || {};
            return getCustomSelectItem(contract.contractNumber, licensePlate, carBrand, carModel, isDirectCreditGroup);
        });
        const customSelectItemAboContracts = (aboContracts || []).map(
            ({ details, contractNumber, licensePlate, isInReservation }) => {
                const { carBrand, carModel } = details.vehicleDetails || {};
                return getCustomSelectItem(contractNumber, licensePlate, carBrand, carModel, false, isInReservation);
            },
        );
        return [...customSelectItemContracts, ...customSelectItemAboContracts];
    };
    return (
        <FormField labelText={labelText} labelId="contract-select" type="checkbox">
            <Combobox
                ariaLabelledBy="contract-select"
                name="contract"
                testId="contract"
                onChange={handleOnChange}
                defaultState={
                    contractNumber
                        ? {
                              value: [
                                  {
                                      key: contractNumber,
                                      value: contractNumber,
                                  },
                              ],
                          }
                        : undefined
                }
            >
                {getCustomSelectItems()}
            </Combobox>
        </FormField>
    );
};
