import React, { Context, createContext, FC, useContext } from 'react';
import {
    AoDContract,
    AutoAboContract,
    Contract,
    RaCContract,
    FourSalesContract,
    DigitalOfferDataFrontend,
} from '@cp-de/common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-10/common-utilities';
import { AbstractDataState } from '@cp-shared-10/frontend-integration';
import {
    useAutoAboContracts,
    useContracts,
    useAoDContracts,
    useRaCContracts,
    useFourSalesContracts,
} from './components/contracts';
import { useFetchingPermission } from './hooks/useFetchingPermission';
import { useDigitalOffer } from 'components/digital-offer-page';

type UserContractsContextState = {
    contracts: AbstractDataState<Contract[], DefaultBusinessMarketApiErrorCode>;
    aboContracts: AbstractDataState<AutoAboContract[], DefaultBusinessMarketApiErrorCode>;
    aodContracts: AbstractDataState<AoDContract[], DefaultBusinessMarketApiErrorCode>;
    racContracts: AbstractDataState<RaCContract[], DefaultBusinessMarketApiErrorCode>;
    fourSalesContracts: AbstractDataState<FourSalesContract[], DefaultBusinessMarketApiErrorCode>;
    digialOfferTransactions: AbstractDataState<DigitalOfferDataFrontend[], DefaultBusinessMarketApiErrorCode>;
    hasContextProvider: boolean;
    isLoadingPermission: boolean;
    isFetchingAboAllowed: boolean;
    isFetchingStandardAllowed: boolean;
    isFetchingAodAllowed: boolean;
    isFetchingRacAllowed: boolean;
    isFetchingFourSalesAllowed: boolean;
};

const createUserContractsContext = (): Context<UserContractsContextState> => {
    const UserContractsContext = createContext<UserContractsContextState>({
        contracts: {
            data: undefined,
            isLoading: false,
            loadingError: undefined,
            hasReceivedResponse: false,
            failedLoadingAttempts: 0,
        },
        aboContracts: {
            data: undefined,
            isLoading: false,
            loadingError: undefined,
            hasReceivedResponse: false,
            failedLoadingAttempts: 0,
        },
        aodContracts: {
            data: undefined,
            isLoading: false,
            loadingError: undefined,
            hasReceivedResponse: false,
            failedLoadingAttempts: 0,
        },
        racContracts: {
            data: undefined,
            isLoading: false,
            loadingError: undefined,
            hasReceivedResponse: false,
            failedLoadingAttempts: 0,
        },
        fourSalesContracts: {
            data: undefined,
            isLoading: false,
            loadingError: undefined,
            hasReceivedResponse: false,
            failedLoadingAttempts: 0,
        },
        digialOfferTransactions: {
            data: undefined,
            isLoading: false,
            loadingError: undefined,
            hasReceivedResponse: false,
            failedLoadingAttempts: 0,
        },
        hasContextProvider: false,
        isLoadingPermission: true,
        isFetchingAboAllowed: false,
        isFetchingStandardAllowed: false,
        isFetchingAodAllowed: false,
        isFetchingRacAllowed: false,
        isFetchingFourSalesAllowed: false,
    });
    UserContractsContext.displayName = 'UserContractsContext';
    return UserContractsContext;
};

function createUserContractsDataAndFlagsHook(
    UserContractsContext: Context<UserContractsContextState>,
): () => UserContractsContextState {
    return (): UserContractsContextState => {
        const context = useContext(UserContractsContext);
        if (!context.hasContextProvider)
            console.error('Seems that <UserContractsProvider> is missing in the components tree.');
        return context;
    };
}

export function createUserContractsProvider(): {
    UserContractsProvider: React.FC;
    UserContractsContext: Context<UserContractsContextState>;
    useUserContractsDataAndFlags: () => UserContractsContextState;
} {
    const UserContractsContext = createUserContractsContext();
    const UserContractsProvider: FC = ({ children }) => {
        const {
            isFetchingAboAllowed,
            isFetchingStandardAllowed,
            isFetchingAodAllowed,
            isFetchingRacAllowed,
            isFetchingFourSalesAllowed,
            isLoadingPermission,
        } = useFetchingPermission();
        const contracts = useContracts(isFetchingStandardAllowed);
        const aboContracts = useAutoAboContracts(isFetchingAboAllowed);
        const aodContracts = useAoDContracts(isFetchingAodAllowed);
        const racContracts = useRaCContracts(isFetchingRacAllowed);
        const fourSalesContracts = useFourSalesContracts(isFetchingFourSalesAllowed);
        const digialOfferTransactions = useDigitalOffer();

        return (
            <UserContractsContext.Provider
                value={{
                    contracts,
                    aboContracts,
                    aodContracts,
                    racContracts,
                    fourSalesContracts,
                    digialOfferTransactions,
                    hasContextProvider: true,
                    isLoadingPermission,
                    isFetchingAboAllowed,
                    isFetchingStandardAllowed,
                    isFetchingAodAllowed,
                    isFetchingRacAllowed,
                    isFetchingFourSalesAllowed,
                }}
            >
                {children}
            </UserContractsContext.Provider>
        );
    };
    UserContractsProvider.displayName = 'UserContractsContextProvider';
    const useUserContractsDataAndFlags = createUserContractsDataAndFlagsHook(UserContractsContext);
    return {
        UserContractsProvider,
        UserContractsContext,
        useUserContractsDataAndFlags,
    };
}
