import React from 'react';
import { DataOverview, Layout } from '@vwfs-bronson/bronson-react';
import { DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-10/frontend-ui';
import { ExpertReportFormValues } from '../types';
import {
    getCommonInsuranceSummaryItems,
    parseFilesName,
    translateResponsibleInsurenceButton,
    translateYesNoButtonValue,
    YesNoButtons,
} from '../../common';
import { ReportDamageResponsibleInsurance } from '@cp-de/common';
import { FormNavigationButtons } from '../../../form-navigation-buttons';
import { useTranslationWithFormatting } from '../../../../../localization/useTranslationWithFormatting';

type ExpertReportConfirmationViewProps = {
    onSubmit: () => void;
    handlePrev: (step?: 1 | 2) => void;
    expertReportValues: ExpertReportFormValues;
};

export const ExpertReportConfirmationView: React.FC<ExpertReportConfirmationViewProps> = ({
    onSubmit,
    handlePrev,
    expertReportValues,
}) => {
    const { t, f } = useTranslationWithFormatting('repair-approval');

    const onBackClick = (): void => handlePrev(2);
    const onEditStep1Click = (): void => handlePrev();
    const onEditStep2Click = (): void => handlePrev(2);

    const {
        responsibleInsurance,
        policyHolder,
        insuranceClaim,
        insurance,
        paymentToLoanContract,
        repairByPrepayment,
        attachments,
        damageDate,
    } = expertReportValues;

    const step1SummaryItems: DefinitionListItem[] = [
        {
            label: t('responsible-insurance.label'),
            value: responsibleInsurance
                ? translateResponsibleInsurenceButton(responsibleInsurance as ReportDamageResponsibleInsurance, t)
                : undefined,
        },
        {
            label: t('policy-holder.label'),
            value: policyHolder ? translateYesNoButtonValue(policyHolder as YesNoButtons, t) : undefined,
        },
        {
            label: t('insurance-claim-upload.label-short'),
            value: insuranceClaim.length ? parseFilesName(insuranceClaim) : undefined,
        },
        {
            label: t('common.damage-date'),
            value: damageDate ? damageDate : undefined,
        },
    ].filter(({ value }) => value);

    const step2SummaryItems = getCommonInsuranceSummaryItems(insurance, t, f);
    step2SummaryItems.push(
        ...[
            {
                label: t('expert-report.payment-to-loan-contract.label'),
                value: paymentToLoanContract
                    ? translateYesNoButtonValue(paymentToLoanContract as YesNoButtons, t)
                    : undefined,
            },
            {
                label: t('expert-report.repair-by-prepayment.label'),
                value: repairByPrepayment
                    ? translateYesNoButtonValue(repairByPrepayment as YesNoButtons, t)
                    : undefined,
            },
            {
                label: t('attachments-upload.label-short'),
                value: attachments?.length ? parseFilesName(attachments) : undefined,
            },
        ].filter(({ value }) => value),
    );

    return (
        <Layout>
            <Layout.Item>{t('confirmation-view.title')}</Layout.Item>
            <Layout.Item>
                <DataOverview
                    title={t('expert-report.step1.title')}
                    buttonLabel={t('translation:editableSectionNav.start')}
                    buttonProps={{ onClick: onEditStep1Click, testId: 'edit-step1' }}
                >
                    <DefinitionListHorizontal list={step1SummaryItems} />
                </DataOverview>
            </Layout.Item>
            <Layout.Item>
                <DataOverview
                    title={t('expert-report.step2.title')}
                    buttonLabel={t('translation:editableSectionNav.start')}
                    buttonProps={{ onClick: onEditStep2Click, testId: 'edit-step1' }}
                >
                    <DefinitionListHorizontal list={step2SummaryItems} />
                </DataOverview>
            </Layout.Item>
            <Layout.Item>
                <FormNavigationButtons
                    onPrimaryButtonClick={onSubmit}
                    onSecondaryButtonClick={onBackClick}
                    primaryButtonType={'confirm'}
                />
            </Layout.Item>
        </Layout>
    );
};
