import { AoDContract, AutoAboContract, Contract, FourSalesContract, RaCContract } from '@cp-de/common';
import { CombinedContractType, isStandardContract } from 'utils';
import { useAnalyticsActionTracker } from '@cp-shared-10/frontend-ui';
import { Card, Button, Accordion } from '@vwfs-bronson/bronson-react';

import React from 'react';

import { InactiveVehicleDetailsAndImage } from './InactiveVehicleDetailAndImage';
import { InactiveContractDetails } from './InactiveContractDetails';
import { confirmVehicleDetailsComposition } from 'components/contracts/utils';

export const DeferralLink: React.FC<{
    title: string;
    href: string;
    showDeferralLink: boolean;
    children: React.ReactNode;
}> = ({ title, href, showDeferralLink, children }) => {
    const { onAction } = useAnalyticsActionTracker('onOpenSuspendInstallment');

    const handleClick = (): void => {
        if (showDeferralLink) {
            onAction();
            window.open(href, '_blank');
        }
    };
    return (
        <Button
            link
            small
            className="u-pb-small"
            title={title}
            testId={'deferral-button'}
            element="a"
            href={href}
            icon="semantic-forward"
            iconReversed
            onClick={handleClick}
        >
            {children}
        </Button>
    );
};

export const InactiveContractsCard: React.FC<{
    contracts: CombinedContractType[];
}> = ({ contracts }) => {
    if (!contracts) {
        return null;
    }

    const isContractWithVin = !!contracts[0]?.vehicleData?.vin;

    return (
        <Card className="u-mb u-text-left">
            <React.Fragment>
                {(isContractWithVin || confirmVehicleDetailsComposition(contracts[0]?.details?.vehicleDetails)) &&
                    (isStandardContract(contracts[0]) ? (
                        <InactiveVehicleDetailsAndImage contract={contracts[0] as Contract} />
                    ) : (
                        <InactiveVehicleDetailsAndImage
                            reservationContract={
                                contracts[0] as AutoAboContract | FourSalesContract | AoDContract | RaCContract
                            }
                        />
                    ))}
                <Accordion headerSplit>
                    {contracts.map((contract, index) => {
                        return <InactiveContractDetails key={index} contract={contract} />;
                    })}
                </Accordion>
            </React.Fragment>
        </Card>
    );
};
