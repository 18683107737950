import { getGreetingEndpoint, Greeting } from '@cp-de/common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-10/common-utilities';
import { getSimpleDataFetchSlice } from '@cp-shared-10/frontend-integration';
import { AxiosRequestConfig } from 'axios';
import { CpDataApi } from 'cp-xhr';

const { reducer, fetchData } = getSimpleDataFetchSlice<Greeting, DefaultBusinessMarketApiErrorCode>({
    dataName: 'greeting',
    fetchCallback(config?: AxiosRequestConfig) {
        return CpDataApi.get(getGreetingEndpoint(config?.params)).then(response => response.data);
    },
});

export default reducer;
export const fetchGreeting = fetchData;
