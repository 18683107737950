import React from 'react';
import {
    FourSalesContract,
    formatAsDistance,
    formatAsCurrency,
    formatAsDate,
    FourSalesContractSatus,
} from '@cp-de/common';
import { DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-10/frontend-ui';
import { useTranslationWithFormatting } from '../../../../../localization/useTranslationWithFormatting';
import { Heading, Hr } from '@vwfs-bronson/bronson-react';
import { DriverCircleKey } from './enums';

export const FourSalesContractDetails: React.FC<{ contract?: FourSalesContract }> = ({ contract }) => {
    const { t, ready } = useTranslationWithFormatting('four-sales-contract-details', { useSuspense: false });

    if (!contract) {
        return null;
    }

    const {
        details: { contractDetails, customerDetails, financialProduct },
    } = contract;

    const showAccompaniedDriving = (value?: boolean): string =>
        value ? t('second-list.accompanied-driving-from-17.yes') : t('second-list.accompanied-driving-from-17.no');

    const showBirthDateAdditionalDriver = (value?: string): string => {
        switch (value) {
            case DriverCircleKey.B:
                return formatAsDate(contractDetails?.coverageDetail?.birthDateAdditionalDriver1) || '';
            case DriverCircleKey.C:
                const cBirthDateDriver1 = formatAsDate(contractDetails?.coverageDetail?.birthDateAdditionalDriver1);
                const cBirthDateDriver2 = formatAsDate(contractDetails?.coverageDetail?.birthDateAdditionalDriver2);
                return `${cBirthDateDriver1} | ${cBirthDateDriver2}` || '';
            case DriverCircleKey.D:
                const dBirthDateDriver1 = formatAsDate(contractDetails?.coverageDetail?.birthDateAdditionalDriver1);
                const dBirthDateDriver2 = formatAsDate(contractDetails?.coverageDetail?.birthDateAdditionalDriver2);
                const dBirthDateDriver3 = formatAsDate(contractDetails?.coverageDetail?.birthDateAdditionalDriver3);
                return `${dBirthDateDriver1} | ${dBirthDateDriver2} | ${dBirthDateDriver3}` || '';
            default:
                return '';
        }
    };

    const paymentMethodChoice = (value?: string): string => {
        switch (value) {
            case 'invoice':
                return t('first-list.payment-method.invoice');
            case 'sepa':
                return t('first-list.payment-method.sepa');
            case 'creaditcard':
                return t('first-list.payment-method.creditcard');
            case 'other':
                return t('first-list.payment-method.other');
            default:
                return '';
        }
    };

    const contractDetailsStatus = (value?: string): string => {
        switch (value) {
            case 'offer':
                return t('first-list.status.offer');
            case 'proposal':
                return t('first-list.status.proposal');
            case 'contract':
                return t('first-list.status.contract');
            case 'cancelled':
                return t('first-list.status.cancelled');
            case 'expired':
                return t('first-list.status.expired');
            default:
                return '';
        }
    };

    const contractDetailsFirstList: DefinitionListItem[] = [
        {
            label: t('first-list.status.label'),
            value:
                contractDetails?.status === FourSalesContractSatus.PROPOSAL
                    ? contractDetailsStatus(contractDetails.status)
                    : null,
            tooltip: t('first-list.status.tooltip'),
        },
        {
            label: t('first-list.commencement-of-contract'),
            value: contractDetails?.startDate ? formatAsDate(contractDetails.startDate) : '-',
        },
        {
            label: t('first-list.payment-method.label'),
            value: customerDetails?.paymentData?.paymentMethod
                ? paymentMethodChoice(customerDetails.paymentData.paymentMethod)
                : '-',
        },
        {
            label: t('first-list.main-due'),
            value: contractDetails?.coverageDetail?.contractRenewalDate
                ? contractDetails.coverageDetail.contractRenewalDate
                : '-',
        },
        {
            label: t('first-list.annual-amount'),
            value: financialProduct?.contract?.premiumDefault
                ? formatAsCurrency(financialProduct.contract.premiumDefault)
                : '-',
        },
        {
            label: t('first-list.professional-group'),
            value: contractDetails?.coverageDetail?.occupationTariffGroup
                ? contractDetails?.coverageDetail?.occupationTariffGroup
                : '-',
        },
    ].filter(item => !!item.value);

    const contractDetailsSecondList: DefinitionListItem[] = [
        {
            label: t('second-list.annual-milage'),
            value: financialProduct?.calculation?.yearlyMileage
                ? formatAsDistance(financialProduct.calculation.yearlyMileage)
                : '-',
        },
        {
            label: t('second-list.driver-circle'),
            value: contractDetails?.coverageDetail?.driverCircleKeyString
                ? contractDetails?.coverageDetail?.driverCircleKeyString
                : '-',
        },
        {
            label: t('second-list.date-of-birth-additional-driver'),
            value: contractDetails?.coverageDetail?.driverCircleKey
                ? showBirthDateAdditionalDriver(contractDetails?.coverageDetail?.driverCircleKey)
                : null,
        },
        {
            label: t('second-list.age-youngest-driver'),
            value:
                contractDetails?.coverageDetail?.driverCircleKey === DriverCircleKey.E &&
                contractDetails?.coverageDetail?.ageYoungestDriver
                    ? contractDetails.coverageDetail.ageYoungestDriver
                    : null,
        },
        {
            label: t('second-list.season-month'),
            value: contractDetails?.coverageDetail?.licensePlateSeason
                ? contractDetails.coverageDetail.licensePlateSeason
                : null,
        },
        {
            label: t('second-list.accompanied-driving-from-17.label'),
            value:
                contractDetails?.coverageDetail?.accompaniedDriving !== undefined
                    ? showAccompaniedDriving(contractDetails.coverageDetail.accompaniedDriving)
                    : null,
        },
    ].filter(item => !!item.value);

    const { thirdPartyLiabilityBonusMalus, fullyComprehensiveBonusMalus } = contractDetails?.coverageDetail || {};
    const noClaimsClassList: DefinitionListItem[] = [
        {
            label: t('no-calims-class.liability'),
            value: `${thirdPartyLiabilityBonusMalus || '-'} / ${fullyComprehensiveBonusMalus || '-'}`,
        },
    ];

    const scopeOfCoverageList: DefinitionListItem[] = [
        {
            label: t('scope-of-coverage.liability-insurance-sum.label'),
            value: contractDetails?.coverageDetail?.thirdPartyLiability
                ? contractDetails.coverageDetail.thirdPartyLiability
                : '-',
            tooltip: t('scope-of-coverage.liability-insurance-sum.tooltip'),
        },
        {
            label: t('scope-of-coverage.fully-comprehensive'),
            value: contractDetails?.coverageDetail?.comprehensiveCover
                ? contractDetails.coverageDetail.comprehensiveCover
                : '-',
        },
        {
            label: t('scope-of-coverage.additional-modules'),
            value: contractDetails?.coverageDetail?.additionalCoveragePackages?.length
                ? contractDetails.coverageDetail.additionalCoveragePackages.join(' | ')
                : '-',
        },
    ].filter(item => !!item.value);

    return (
        <>
            {ready && (
                <>
                    <DefinitionListHorizontal list={contractDetailsFirstList} />
                    <Hr className={'u-mb-none'} />
                    <DefinitionListHorizontal list={contractDetailsSecondList} />
                    <Hr className={'u-mb-none'} />
                    <>
                        <Heading level={6} className="u-pt">
                            {t('no-calims-class.title')}
                        </Heading>
                        <DefinitionListHorizontal list={noClaimsClassList} />
                    </>
                    <Hr className={'u-mb-none'} />
                    <>
                        <Heading level={6} className="u-pt">
                            {t('scope-of-coverage.title')}
                        </Heading>
                        <DefinitionListHorizontal list={scopeOfCoverageList} />
                    </>
                </>
            )}
        </>
    );
};
