import { AutoAboContract, Profile } from '@cp-de/common';
import { Notification, NotificationStatus } from '@cp-shared-10/frontend-ui';
import { ContactDetailsSection } from '../../contact-details-section/ContactDetailsSection';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AddressSection } from '../../address-section';
import { IdentitySection } from '../../identity-section';

type MyProfileUiProps = {
    myProfile?: Profile;
    isAod?: boolean;
    isRac?: boolean;
    aboContracts?: AutoAboContract[];
};

export const MyProfileUi: React.FC<MyProfileUiProps> = ({ myProfile, isAod, isRac, aboContracts }) => {
    const { t } = useTranslation('my-profile');
    const isAbo = Array.isArray(aboContracts) && !!aboContracts.length;

    if (!myProfile) {
        return null;
    }

    const isMixedCustomer = isAod || isRac || isAbo;
    const isChangeAllowed =
        myProfile.addressChangePermission === 'ALLOWED' || myProfile.nameChangePermission === 'ALLOWED';

    const properText = isMixedCustomer
        ? t('mixed-customer-notification')
        : isChangeAllowed
        ? t('change-info')
        : undefined;

    return (
        <>
            {!!properText && <Notification className="u-mb" status={NotificationStatus.info} text={properText} />}

            <IdentitySection profile={myProfile} isAbo={isAbo} />
            <AddressSection profile={myProfile} isAbo={isAbo} />
            <ContactDetailsSection contact={myProfile.contactDetails} isAbo={isAbo} />
        </>
    );
};
