import { AnalyticsProvider, MasterPage as MasterPageShared, useAuthentication } from '@cp-shared-10/frontend-ui';
import { Chatbot } from '../../components/chatbot';
import { IconFooter } from '../../components/icon-footer';
import { LegalFooter } from '../../components/legal-footer';
import { NavigationBar } from '../../components/navigation/NavigationBar';
import { currentBrand } from '../../config/config';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
    autoLogoutPath,
    landingPagePath,
    loginChoiceOfferPagePath,
    loginChoicePagePath,
    loginInProgressDigitalOfferPagePath,
    loginInProgressPagePath,
    registrationPagePath,
    verimiActivationPagePath,
} from '../../components/navigation/paths';
import { isIe } from '../../utils/common';
// eslint-disable-next-line import/named
import { v4 as uuidv4 } from 'uuid';
import { TimeOutNotification } from '../../components/time-out-notification';

const pagesWithoutChatbot = [
    loginChoicePagePath(),
    loginChoiceOfferPagePath(),
    '/customer-portal/de',
    landingPagePath(),
    autoLogoutPath(),
    verimiActivationPagePath(),
    loginInProgressPagePath(),
];

const pagesWithoutTimeOutNotification = [
    '/customer-portal/de',
    autoLogoutPath(),
    verimiActivationPagePath(),
    loginInProgressPagePath(),
    loginInProgressDigitalOfferPagePath(),
    registrationPagePath(),
];

const shouldDisplayChatbot = (isAuthenticated: boolean, pathname: string): boolean => {
    return (
        isAuthenticated &&
        !isIe() &&
        !pagesWithoutChatbot.find(pagePath => pagePath === pathname || `${pagePath}/` === pathname)
    );
};
const shouldDisplayTimeOutNotification = (pathname: string) =>
    !pagesWithoutTimeOutNotification.find(pagePath => pagePath === pathname || `${pagePath}/` === pathname);

const MasterPageComponent: React.FC<{ isAuthenticated: boolean; transactionId: string }> = memo(
    ({ isAuthenticated, transactionId, children }) => {
        const { t } = useTranslation();
        const location = useLocation();

        return (
            <AnalyticsProvider
                market={'DE'}
                isAuthenticated={isAuthenticated}
                version={'8'}
                releaseDate={'2023-09-27'}
                language={'de'}
                registrationPath={registrationPagePath()}
                loginPath={'/login-in-progress'}
                userGroup={'private'}
                brand={currentBrand}
                productId={transactionId}
            >
                {shouldDisplayTimeOutNotification(location.pathname) && <TimeOutNotification />}
                <MasterPageShared
                    navigationBar={<NavigationBar />}
                    iconFooter={<IconFooter />}
                    legalFooter={<LegalFooter />}
                    label={t('backToTop')}
                >
                    {children}
                    {shouldDisplayChatbot(isAuthenticated, location.pathname) && <Chatbot />}
                </MasterPageShared>
            </AnalyticsProvider>
        );
    },
);

MasterPageComponent.displayName = 'MasterPageComponent';

export const MasterPage: React.FC = ({ children }) => {
    const { isAuthenticated } = useAuthentication();
    const transactionId = uuidv4();
    return (
        <MasterPageComponent isAuthenticated={isAuthenticated} transactionId={transactionId}>
            {children}
        </MasterPageComponent>
    );
};
