import React from 'react';
import { Layout } from '@vwfs-bronson/bronson-react';
import { Skeleton } from '@cp-shared-10/frontend-ui';

export const ArrearPaymentLoadingPlaceholder: React.FC = () => (
    <>
        <Layout className="u-mb-small u-mh-xlarge">
            <Layout.Item default={'1/1'} className={'u-text-center'}>
                <Skeleton count={1} height={40} width={'80%'} />
            </Layout.Item>
            <Layout.Item default={'1/1'}>
                <Skeleton count={3} height={40} />
            </Layout.Item>
            <Layout.Item default={'1/4'} s={'1/1'} />
            <Layout.Item default={'1/4'} s={'1/1'}>
                <Skeleton height={40} width={200} />
            </Layout.Item>
            <Layout.Item default={'1/4'} s={'1/1'}>
                <Skeleton height={40} width={200} />
            </Layout.Item>
            <Layout.Item default={'1/4'} s={'1/1'} />
        </Layout>
    </>
);
