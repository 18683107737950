import { AoDContract, AutoAboContract, Contract, RaCContract, FourSalesContract } from '@cp-de/common';
import { ValidatedSelectItem } from '@cp-shared-10/frontend-ui';
import { TFunction } from 'i18next';

type FiltersType = {
    brandList: string[];
    contractTypes: ValidatedSelectItem[];
};

export const getFilters = (
    t: TFunction,
    contracts?: Contract[],
    autoAboContracts?: AutoAboContract[],
    aodContracts?: AoDContract[],
    racContracts?: RaCContract[],
    fourSalesContracts?: FourSalesContract[],
): FiltersType => {
    let output: FiltersType = {
        brandList: [],
        contractTypes: [
            {
                label: t('contracts:form-labels.ALL'),
                value: '',
            },
        ],
    };
    let tempOutput: FiltersType = { brandList: [], contractTypes: [] };

    const outputHelper = (input: FiltersType, tempInput: FiltersType): FiltersType => ({
        brandList: [...input.brandList, ...tempInput.brandList],
        contractTypes: [...input.contractTypes, ...tempInput.contractTypes],
    });

    if (Array.isArray(contracts) && contracts.length) {
        tempOutput = contracts.reduce(
            (acc: FiltersType, contract: Contract) => {
                if (contract.vehicle && contract.vehicle.carBrand) {
                    acc.brandList.push(contract.vehicle.carBrand);
                }
                if (contract.contractType) {
                    acc.contractTypes.push({
                        label: t(`contracts:form-labels.${contract.contractType}`),
                        value: contract.contractType,
                    });
                }
                return acc;
            },
            {
                brandList: [] as string[],
                contractTypes: [] as ValidatedSelectItem[],
            },
        );
        output = outputHelper(output, tempOutput);
    }

    if (Array.isArray(autoAboContracts) && autoAboContracts.length) {
        tempOutput = autoAboContracts.reduce(
            (acc: FiltersType, autoAboContract: AutoAboContract) => {
                acc.contractTypes.push({
                    label: t(`contracts:form-labels.${autoAboContract.contractType}`),
                    value: autoAboContract.contractType,
                });
                if (autoAboContract.details.vehicleDetails && autoAboContract.details.vehicleDetails.carBrand) {
                    acc.brandList.push(autoAboContract.details.vehicleDetails.carBrand);
                }
                return acc;
            },
            {
                brandList: [] as string[],
                contractTypes: [] as ValidatedSelectItem[],
            },
        );
        output = outputHelper(output, tempOutput);
    }

    if (Array.isArray(aodContracts) && aodContracts.length) {
        tempOutput = aodContracts.reduce(
            (acc: FiltersType, aodContract: AoDContract) => {
                if (aodContract.details.vehicleDetails && aodContract.details.vehicleDetails.carBrand) {
                    acc.brandList.push(aodContract.details.vehicleDetails.carBrand);
                }
                acc.contractTypes.push({
                    label: t(`contracts:form-labels.${aodContract.contractType}`),
                    value: aodContract.contractType,
                });
                return acc;
            },
            {
                brandList: [] as string[],
                contractTypes: [] as ValidatedSelectItem[],
            },
        );
        output = outputHelper(output, tempOutput);
    }

    if (Array.isArray(racContracts) && racContracts.length) {
        tempOutput = racContracts.reduce(
            (acc: FiltersType, racContract: RaCContract) => {
                if (racContract.details.vehicleDetails && racContract.details.vehicleDetails.carBrand) {
                    acc.brandList.push(racContract.details.vehicleDetails.carBrand);
                }
                acc.contractTypes.push({
                    label: t(`contracts:form-labels.${racContract.contractType}`),
                    value: racContract.contractType,
                });
                return acc;
            },
            {
                brandList: [] as string[],
                contractTypes: [] as ValidatedSelectItem[],
            },
        );
        output = outputHelper(output, tempOutput);
    }

    if (Array.isArray(fourSalesContracts) && fourSalesContracts.length) {
        tempOutput = fourSalesContracts.reduce(
            (acc: FiltersType, fourSalesContract: FourSalesContract) => {
                if (fourSalesContract.details.vehicleDetails && fourSalesContract.details.vehicleDetails.carBrand) {
                    acc.brandList.push(fourSalesContract.details.vehicleDetails.carBrand);
                }
                acc.contractTypes.push({
                    label: t(`contracts:form-labels.${fourSalesContract.contractType}`),
                    value: fourSalesContract.contractType,
                });
                return acc;
            },
            {
                brandList: [] as string[],
                contractTypes: [] as ValidatedSelectItem[],
            },
        );
        output = outputHelper(output, tempOutput);
    }
    return {
        // Remove duplicates
        brandList: output.brandList.filter((elem, index) => output.brandList.indexOf(elem) === index),
        contractTypes: output.contractTypes.filter(
            (elem, index, self) => index === self.findIndex(current => current.value === elem.value),
        ),
    };
};
