// LoginFormik.tsx
import React from 'react';
import { Formik } from 'formik';
import { LoginChoice } from '@cp-de/common';
import { ValidatedInput, preventSubmit } from '@cp-shared-10/frontend-ui';
import { Button, Fieldset, Form } from '@vwfs-bronson/bronson-react';
import { loginChoiceValidationSchema } from './loginChoiceValidationSchema';
import { LoginChoiceFormFields } from './LoginChoiceFormFields';

interface LoginFormikProps {
    loginChoice: LoginChoice;
    redirectToIdentityKit: (email: string) => void;
}

export const LoginChoiceForm: React.FC<LoginFormikProps> = ({ loginChoice, redirectToIdentityKit }) => (
    <Formik
        initialValues={{
            email: '',
        }}
        validationSchema={loginChoiceValidationSchema(loginChoice)}
        onSubmit={(values: LoginChoiceFormFields): void => {
            redirectToIdentityKit(values.email);
        }}
    >
        {formik => (
            <Form onSubmit={e => preventSubmit(e)}>
                <Fieldset>
                    <Fieldset.Row>
                        <ValidatedInput
                            className="u-text-left"
                            label={loginChoice.email.label}
                            inputId="email-login-choice"
                            name="email"
                            inputMode={'email'}
                            testId={'email'}
                            addonText={<img aria-hidden="true" src={loginChoice.email.icon} alt="FS.ID" height={40} />}
                            reversed
                        />
                        <p className={'u-font-size-fs-1'}>{loginChoice.email.hint}</p>
                    </Fieldset.Row>
                    <Fieldset.Row className={'u-text-center'}>
                        <Button full={false} onClick={formik.submitForm} testId={'continueButton'}>
                            {loginChoice.continue}
                        </Button>
                    </Fieldset.Row>
                </Fieldset>
            </Form>
        )}
    </Formik>
);
