import React, { useState } from 'react';
import {
    Contract,
    EarlyTerminationError,
    FinancialDetails,
    getEarlyTerminationEndpoint,
    EarlyTerminationRequest,
} from '@cp-de/common';
import { ContractHeader } from '../../contract-header';
import { Card, Fieldset, Form, Layout, Button, ButtonContainer } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import {
    Notification,
    NotificationStatus,
    ValidatedInput,
    preventSubmit,
    Spinner,
    useAnalyticsActionTracker,
    useAnalyticsPageViewTracker,
} from '@cp-shared-10/frontend-ui';
import { Formik, FormikErrors, FormikProps } from 'formik';
import { dashboardPagePath } from '../../navigation/paths';
import { useHistory } from 'react-router-dom';
import { Base64File } from '@cp-shared-10/apis';
import { FileUpload } from '../file-upload';
import { getBase64, getFileType } from '../../../utils';

import { CpDataApi } from 'cp-xhr';
import { parseErrorResponse } from '@cp-shared-10/frontend-integration';
import { WithDefaultCpIntegrationErrors } from '@cp-shared-10/common-utilities';
import { validationSchema } from './validation';
import { EarlyTerminationModal } from '../modal';
import { DealerDetails } from '../dealer-details';
// eslint-disable-next-line import/named
import { isEmpty } from 'lodash';

export type EarlyTerminationFormType = {
    mileage: string;
    file: File[];
};

export type EarlyTerminateProps = {
    contract?: Contract;
    financialDetails?: FinancialDetails;
};

export const EarlyTerminationUi: React.FC<EarlyTerminateProps> = ({ contract, financialDetails }) => {
    const { t } = useTranslation('early-termination');
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    useAnalyticsPageViewTracker(
        'earlyTerminationCreditContract',
        !!contract && !!financialDetails && contract.earlyTermination?.type === 'Email',
    );
    useAnalyticsPageViewTracker(
        'earlyTerminationLeasingContract',
        !!contract && !!financialDetails && contract.earlyTermination?.type === 'Dealer',
    );
    const { onAction } = useAnalyticsActionTracker('onEarlyTerminationContractValidationError');
    const initialListErrors = 'mileage, file';

    const getErrors = (errors: { [k: string]: string | string[] | FormikErrors<File>[] | undefined }) =>
        Object.keys(errors).join(`, `);

    useAnalyticsPageViewTracker('earlyTerminationCreditRequest', !!showModal);

    if (!contract || !financialDetails) {
        return null;
    }

    const initialValues: EarlyTerminationFormType = {
        mileage: '',
        file: [],
    };

    const maxFileSize = 4 * 1024 * 1024;
    const validFileFormats = ['pdf', 'jpeg', 'jpg', 'png'];
    const goBackToDashboard = (): void => history.push(dashboardPagePath());

    const handleChange = (formik: FormikProps<EarlyTerminationFormType>) => (): void => {
        formik.setFieldTouched('mileage');
    };

    const mapFromValues = async (formValues: EarlyTerminationFormType): Promise<EarlyTerminationRequest> => {
        const { mileage, file } = formValues;
        const filePromises: Promise<Base64File>[] = file.map(fileMap => getBase64(fileMap));

        return Promise.all([...filePromises]).then(file => ({
            mileage: parseInt(mileage),
            documentFile: file[0],
        }));
    };

    const areFileValid = (mappedValues: EarlyTerminationRequest): boolean => {
        const result =
            mappedValues.documentFile.size <= maxFileSize &&
            validFileFormats.includes(getFileType(mappedValues.documentFile.filename));
        return result;
    };

    const checkCauseOfError = async (
        formValues: EarlyTerminationFormType,
        errors: { [k: string]: string | string[] | FormikErrors<File>[] | undefined },
    ) => {
        if (!formValues.file.length) {
            !!errors.mileage ? onAction(initialListErrors) : onAction('file');
        } else {
            const mappedValues = await mapFromValues(formValues);
            !areFileValid(mappedValues) ? onAction(initialListErrors) : onAction(getErrors(errors));
        }
    };
    const handleSubmit = async (formValues: EarlyTerminationFormType) => {
        const mappedValues = await mapFromValues(formValues);
        if (!areFileValid(mappedValues)) {
            onAction('file');
            return;
        }
        setLoading(true);
        CpDataApi.post(getEarlyTerminationEndpoint(contract.contractId), mappedValues)
            .catch(error => {
                const parsedError = parseErrorResponse<WithDefaultCpIntegrationErrors<EarlyTerminationError>>(error)
                    .code;
                setIsError(!!parsedError);
            })
            .finally(() => {
                setLoading(false);
                setShowModal(true);
            });
    };

    return (
        <>
            {loading && <Spinner fullPage={true} />}
            <Card element={'article'}>
                <ContractHeader contract={contract} isDeferralLinkShown={false} />
            </Card>
            <br />
            {contract.earlyTermination?.type === 'Email' ? (
                <>
                    <Notification className="u-mb c-notification--context" status={NotificationStatus.info}>
                        <span dangerouslySetInnerHTML={{ __html: t('SMV-info') }} />
                    </Notification>
                    <Card element={'article'}>
                        <Layout>
                            <Layout.Item className="u-mb">
                                <div>{t('description')}</div>
                            </Layout.Item>
                        </Layout>
                        <Formik
                            initialValues={initialValues}
                            onSubmit={handleSubmit}
                            validationSchema={validationSchema(t)}
                        >
                            {formik => (
                                <Form onSubmit={e => preventSubmit(e)}>
                                    <Fieldset>
                                        <Fieldset.Row>
                                            <Layout>
                                                <Layout.Item default="1/4" s="1/1">
                                                    <ValidatedInput
                                                        label={t('form.mileage.label')}
                                                        name="mileage"
                                                        testId="mileage"
                                                        onChange={handleChange(formik)}
                                                    />
                                                </Layout.Item>
                                            </Layout>
                                        </Fieldset.Row>
                                    </Fieldset>
                                    <Layout>
                                        <Layout.Item>
                                            <div>{t('termination-info')}</div>
                                        </Layout.Item>
                                        <Layout.Item>
                                            <FileUpload
                                                name={'file'}
                                                maxFileSize={maxFileSize}
                                                validFileFormats={validFileFormats}
                                            />
                                        </Layout.Item>
                                    </Layout>
                                    <Fieldset>
                                        <Fieldset.Row>
                                            <ButtonContainer center>
                                                <Button
                                                    type="button"
                                                    secondary
                                                    onClick={goBackToDashboard}
                                                    testId="cancelButton"
                                                >
                                                    {t('form.go-back')}
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        if (!isEmpty(formik.errors) || isEmpty(formik.touched)) {
                                                            if (isEmpty(formik.errors)) {
                                                                onAction(initialListErrors);
                                                            } else {
                                                                checkCauseOfError(formik.values, formik.errors);
                                                            }
                                                        }
                                                        formik.handleSubmit();
                                                    }}
                                                    testId="submitButton"
                                                    type="submit"
                                                >
                                                    {t('form.confirm')}
                                                </Button>
                                            </ButtonContainer>
                                        </Fieldset.Row>
                                    </Fieldset>
                                </Form>
                            )}
                        </Formik>
                    </Card>
                    <EarlyTerminationModal
                        shown={showModal}
                        error={isError}
                        onClose={(): void => setShowModal(false)}
                        onConfirm={goBackToDashboard}
                    />
                </>
            ) : contract.earlyTermination?.type === 'Dealer' ? (
                <>
                    <Notification className="u-mb c-notification--context" status={NotificationStatus.info}>
                        <span dangerouslySetInnerHTML={{ __html: t('leasing-contract-info.before-dealer-data') }} />
                        <br />
                        <DealerDetails {...{ ...contract.earlyTermination }} />
                        <br />
                        <span dangerouslySetInnerHTML={{ __html: t('leasing-contract-info.after-dealer-data') }} />
                    </Notification>
                    <ButtonContainer center>
                        <Button secondary onClick={goBackToDashboard} testId="confirmButton">
                            {t('confirm-button')}
                        </Button>
                    </ButtonContainer>
                </>
            ) : null}
        </>
    );
};
