import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    DataOverview,
    Notification,
    NotificationStatus,
    preventSubmit,
    UiBlockingSpinner,
    ValidatedInput,
} from '@cp-shared-10/frontend-ui';
import { Button, ButtonContainer, Fieldset, Form, CustomRangeSlider } from '@vwfs-bronson/bronson-react';
import { EditStatus } from '../../../base-section/EditStatus';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FinancialDetails, formatAsDistance, MileageChange } from '@cp-de/common';
import { CpDataApi } from '../../../../../../cp-xhr';
import { testIds } from '../../Helper';
import { useMyProfile } from '../../../../../my-profile/overview/useMyProfile';

export type MileageEditViewProps = {
    financialDetails: FinancialDetails;
    cancelEditing: () => void;
    finishEditing: (newEditStatus: EditStatus) => void;
};

type ChangeMileageData = {
    readonly email: string;
    readonly mileage: number;
};

export const MileageEditView: React.FC<MileageEditViewProps> = ({ financialDetails, cancelEditing, finishEditing }) => {
    const { t } = useTranslation('contracts');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { data: myProfileData, isLoading: isMyProfileLoading } = useMyProfile();

    const yearlyMileage = financialDetails.yearlyMileage;
    const changeMileageOptions = financialDetails.changeMileageOptions || [];
    const range = {
        min: changeMileageOptions[0],
        max: changeMileageOptions[changeMileageOptions.length - 1],
        step:
            (changeMileageOptions[changeMileageOptions.length - 1] - changeMileageOptions[0]) /
            (changeMileageOptions.length - 1),
    };
    const initialValues: ChangeMileageData = {
        email: myProfileData?.contactDetails?.email || '',
        // adding 0.1 to mileage resolved range slider issue where it is not possible to reselect initial value
        mileage:
            yearlyMileage && changeMileageOptions.find((item: number) => item === yearlyMileage)
                ? yearlyMileage + 0.1
                : range.min + 0.1,
    };
    const [lastEnteredValue, setLastEnteredValue] = useState<number>(initialValues.mileage);

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required(t('financial-details.yearly-mileage-section.edit-view.mileage.validation.emailRequired'))
            .email(t('financial-details.yearly-mileage-section.edit-view.mileage.validation.emailRequired')),
    });

    const onSubmit = (changeMileageData: ChangeMileageData): void => {
        if (financialDetails._links?.changeMileage && lastEnteredValue) {
            setIsSubmitting(true);
            const body: MileageChange = {
                mileage: lastEnteredValue,
                email: changeMileageData.email,
            };
            CpDataApi.post(financialDetails._links?.changeMileage, body)
                .then(() => {
                    finishEditing(EditStatus.SUCCESS);
                })
                .catch(() => {
                    finishEditing(EditStatus.ERROR);
                })
                .finally(() => setIsSubmitting(false));
        }
    };

    const currentYear = new Date().getFullYear();

    return (
        <DataOverview title={t('financial-details.yearly-mileage-section.edit-view.title')} withoutCardEffect={true}>
            <Notification className={'u-mb'} status={NotificationStatus.info}>
                {t('financial-details.yearly-mileage-section.edit-view.mileage.info')}
                <span
                    dangerouslySetInnerHTML={{
                        __html: t('financial-details.yearly-mileage-section.edit-view.mileage.text', {
                            date1: '01.01.' + (currentYear - 2),
                            date2: '31.12.' + (currentYear + 1),
                        }),
                    }}
                />
            </Notification>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
                {(formik): JSX.Element => (
                    <Form onSubmit={e => preventSubmit(e)}>
                        <UiBlockingSpinner isBlocking={isSubmitting || isMyProfileLoading}>
                            <Fieldset>
                                <Fieldset.Row>
                                    <ValidatedInput
                                        label={t(
                                            'financial-details.yearly-mileage-section.edit-view.mileage.email-label',
                                        )}
                                        name="email"
                                        testId={testIds.email}
                                        type="email"
                                    />
                                </Fieldset.Row>
                                <Fieldset.Row>
                                    <label className="c-form-field__label">
                                        {t('financial-details.yearly-mileage-section.edit-view.mileage.mileage-label')}
                                    </label>
                                    <CustomRangeSlider
                                        onChange={(value): void => {
                                            if (typeof value === 'number') {
                                                setLastEnteredValue(Math.round(value));
                                            }
                                            if (typeof value === 'string') {
                                                setLastEnteredValue(Math.round(parseInt(value)));
                                            }
                                        }}
                                        tooltips={{
                                            to: function noRefCheck(value: number): string {
                                                return formatAsDistance(Math.round(value));
                                            },
                                        }}
                                        range={{
                                            max: range.max,
                                            min: range.min,
                                        }}
                                        step={range.step}
                                        value={lastEnteredValue}
                                        testId={testIds.mileage}
                                    />
                                </Fieldset.Row>
                            </Fieldset>
                            <Fieldset>
                                <Fieldset.Row>
                                    <ButtonContainer className={'u-mt'} center>
                                        <Button type="reset" secondary onClick={cancelEditing} testId={testIds.cancel}>
                                            {t('translation:editableSectionNav.cancel')}
                                        </Button>
                                        <Button
                                            type="submit"
                                            onClick={formik.submitForm}
                                            testId={testIds.submit}
                                            disabled={lastEnteredValue === yearlyMileage}
                                        >
                                            {t(
                                                'financial-details.yearly-mileage-section.edit-view.mileage.confirm-button',
                                            )}
                                        </Button>
                                    </ButtonContainer>
                                </Fieldset.Row>
                            </Fieldset>
                        </UiBlockingSpinner>
                    </Form>
                )}
            </Formik>
        </DataOverview>
    );
};
