import { TransactionItem, getTransactionsEndpoint } from '@cp-de/common';
import { getSimpleDataFetchSlice } from '@cp-shared-10/frontend-integration';
import { CpDataApi } from '../../cp-xhr';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-10/common-utilities';

const { reducer, fetchData } = getSimpleDataFetchSlice<TransactionItem[], DefaultBusinessMarketApiErrorCode>({
    dataName: 'transactions',
    fetchCallback() {
        return CpDataApi.get(getTransactionsEndpoint()).then(response => response.data);
    },
});

export default reducer;
export const fetchTransactions = fetchData;
