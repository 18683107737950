import React from 'react';
import { Layout, Input } from '@vwfs-bronson/bronson-react';
import { CleaveInput, ValidatedInput } from '@cp-shared-10/frontend-ui';
import { useTranslation } from 'react-i18next';

export const BusinessAddressForm: React.FC = () => {
    const { t } = useTranslation('total-early-settlement');
    return (
        <Layout>
            <Layout.Item default="1/1" s="1/1">
                <ValidatedInput
                    label={t('address-information.business.company-name.label')}
                    name="business.companyName"
                    testId="business-company-name"
                    type="text"
                />
            </Layout.Item>
            <Layout.Item default="2/3" s="1/1">
                <ValidatedInput
                    label={t('address-information.street.label')}
                    name="business.streetName"
                    testId="business-street"
                    type="text"
                />
            </Layout.Item>
            <Layout.Item default="1/3" s="1/1">
                <ValidatedInput
                    label={t('address-information.house-number.label')}
                    name="business.houseNumber"
                    testId="business-house-number"
                    type="text"
                />
            </Layout.Item>
            <Layout.Item default="1/3" s="1/1">
                <CleaveInput
                    cleaveOptions={{
                        delimiter: '',
                        blocks: [5],
                        numericOnly: true,
                    }}
                    inputMode={'numeric'}
                    label={t('address-information.zip-code.label')}
                    name="business.zipCode"
                    testId="business-zip-code"
                />
            </Layout.Item>
            <Layout.Item default="1/3" s="1/1">
                <ValidatedInput
                    label={t('address-information.locality.label')}
                    name="business.locality"
                    testId="business-locality"
                    type="text"
                />
            </Layout.Item>
            <Layout.Item default="1/3" s="1/1">
                <div className={'u-mb-xsmall'}>{t('address-information.country.label')}</div>
                <Input disabled value={`${t('address-information.country.value')}`} testId="business-country" />
            </Layout.Item>
        </Layout>
    );
};
