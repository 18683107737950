import {
    DownloadLink as DownloadLinkShared,
    Notification,
    NotificationStatus,
    Spinner,
} from '@cp-shared-10/frontend-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

export type DownloadLinkProps = {
    isDownloading: boolean;
    downloadFile: () => void;
    showError: boolean;
};

export const DownloadLink: React.FC<DownloadLinkProps> = ({ isDownloading, downloadFile, showError }) => {
    const { t } = useTranslation('contracts');

    const ibanSectionTranslationKeyPrefix = 'financial-details.iban-section';
    const sepaDownloadDisplayTranslationKeyPrefix = `${ibanSectionTranslationKeyPrefix}.sepa-mandate-download-display`;

    return isDownloading ? (
        <Spinner small />
    ) : (
        <>
            <DownloadLinkShared
                label={t(`${sepaDownloadDisplayTranslationKeyPrefix}.download-link`)}
                onClick={downloadFile}
                testId={'sepa-download-link'}
            />
            {showError && (
                <Notification
                    testId={'download-error-notification'}
                    status={NotificationStatus.error}
                    className={`u-mt`}
                >
                    {t(`${sepaDownloadDisplayTranslationKeyPrefix}.download-failure`)}
                </Notification>
            )}
        </>
    );
};
