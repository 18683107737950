import { Card, ContentSection, Layout } from '@vwfs-bronson/bronson-react';
import { ContractHeaderLoadingPlaceholder, Skeleton } from '@cp-shared-10/frontend-ui';
import React from 'react';

export const CancelContractLoadingPlaceholder: React.FC = () => (
    <>
        <Card element="article">
            <ContractHeaderLoadingPlaceholder summaryElements={1} />
        </Card>
        <ContentSection className={'u-text-center'} pageWrapSize="medium">
            <Layout>
                <Layout.Item>
                    <Skeleton height={40} width={600} />
                </Layout.Item>
                <Layout.Item className={'u-mt'}>
                    <Skeleton count={4} height={40} />
                </Layout.Item>
                <Layout.Item className={'u-mt-large'}>
                    <Skeleton count={2} height={50} />
                </Layout.Item>
            </Layout>
            <Skeleton height={40} width={200} className="u-mt u-m-xsmall" count={2} />
        </ContentSection>
    </>
);
