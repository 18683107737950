import { FinancialDetails, formatAsDistance } from '@cp-de/common';
import {
    DataOverview,
    DefinitionListHorizontal,
    DefinitionListItem,
    Notification,
    NotificationStatus,
} from '@cp-shared-10/frontend-ui';
import { useTranslationWithFormatting } from '../../../../../localization/useTranslationWithFormatting';
import React, { useEffect, useRef } from 'react';
import { EditStatus } from '../../base-section/EditStatus';

export type ConsultViewProps = {
    startEditing: () => void;
    financialDetails: FinancialDetails;
    lastEditStatus: EditStatus;
    isInactive?: boolean;
};

export const ConsultView: React.FC<ConsultViewProps> = ({
    startEditing,
    financialDetails,
    lastEditStatus,
    isInactive,
}) => {
    const { t } = useTranslationWithFormatting('contracts');

    const submitSuccessRef = useRef<null | HTMLDivElement>(null);

    useEffect(() => {
        if (
            [EditStatus.SUCCESS, EditStatus.ERROR].includes(lastEditStatus) &&
            typeof submitSuccessRef?.current?.scrollIntoView === 'function'
        ) {
            submitSuccessRef.current.scrollIntoView();
        }
    }, [lastEditStatus]);

    if (!financialDetails?.yearlyMileage) {
        return null;
    }

    const mileageItems: DefinitionListItem[] = [
        {
            label: t('financial-details.yearly-mileage-section.label'),
            tooltip: t('financial-details.yearly-mileage-section.tooltip'),
            value: formatAsDistance(financialDetails.yearlyMileage),
        },
    ];

    return (
        <DataOverview
            title={t('financial-details.yearly-mileage-section.headline.non-edit-mode')}
            buttonLabel={!isInactive ? ' ' : undefined}
            buttonProps={{ onClick: startEditing, testId: 'editButton' }}
            withoutCardEffect={true}
        >
            <DefinitionListHorizontal list={mileageItems} />
            <div ref={submitSuccessRef}>
                {lastEditStatus === EditStatus.SUCCESS && (
                    <Notification
                        className={'u-mt'}
                        testId={'success-notification'}
                        status={NotificationStatus.success}
                    >
                        {t('financial-details.yearly-mileage-section.change-successful')}
                    </Notification>
                )}
                {lastEditStatus === EditStatus.ERROR && (
                    <Notification className={'u-mt'} testId={'error-notification'} status={NotificationStatus.error}>
                        {t('financial-details.yearly-mileage-section.change-unsuccessful')}
                    </Notification>
                )}
            </div>
        </DataOverview>
    );
};
