import { InfoIcon, ValidatedInput } from '@cp-shared-10/frontend-ui';
import { Fieldset, FormField, Layout } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { CountryCodeSelection } from './country-code-selection/CountryCodeSelection';

export const PhoneInput: React.FC<{
    testId: string;
    inputName: string;
    selectName: string;
    tooltip?: string;
    label: string;
}> = ({ testId, inputName, selectName, tooltip, children, label }) => {
    const infoIcon = tooltip ? <InfoIcon text={tooltip} /> : null;

    return (
        <Fieldset.Row>
            <div className={'u-mb-xsmall c-form-field__label'}>
                {label}
                {infoIcon}
            </div>
            <Layout className={'o-layout--flush'}>
                <Layout.Item default={'1/3'}>
                    <CountryCodeSelection name={selectName} testId={testId} />
                </Layout.Item>
                <Layout.Item default={'2/3'}>
                    <FormField type="input">
                        <ValidatedInput
                            reversed
                            type={'tel'}
                            testId={`${testId}-input`}
                            inputMode={'tel'}
                            name={inputName}
                            label={''}
                        >
                            {children}
                        </ValidatedInput>
                    </FormField>
                </Layout.Item>
            </Layout>
        </Fieldset.Row>
    );
};
