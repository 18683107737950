import React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollToTopOnNavigation, Notification, NotificationStatus } from '@cp-shared-10/frontend-ui';

export const testId = 'registrationErrorNotification';

export const ConsentError: React.FC<{
    error?: boolean;
}> = ({ error }) => {
    const { t } = useTranslation('consent');

    if (!error) {
        return null;
    }

    return (
        <ScrollToTopOnNavigation>
            <Notification className="u-mb c-notification--context" status={NotificationStatus.warning}>
                {t('error')}
            </Notification>
        </ScrollToTopOnNavigation>
    );
};
