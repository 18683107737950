import React, { useState } from 'react';
import { getCustomerHappinessIndexEndpoint } from '@cp-de/common';
import { useAnalyticsActionTracker } from '@cp-shared-10/frontend-ui';
import { CpDataApi } from 'cp-xhr';
import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';
import { CustomerHappinessIndexUi } from './ui/CustomerHappinessIndexUi';
import { useCustomerHappinessIndex } from './useCustomerHappinessIndex';

const CustomerHappinessIndexWithLoadingHandler = withLoadingAndNoConnectionHandler(CustomerHappinessIndexUi);

export const CustomerHappinessIndex: React.FC = () => {
    const { data: userData, isLoading, loadingError } = useCustomerHappinessIndex();

    const email = userData?.email;
    const firstName = userData?.firstName;
    const lastName = userData?.lastName;

    const { onAction } = useAnalyticsActionTracker('chi');

    const [isOpeningSurvey, setIsOpeningSurvey] = useState(false);
    const [isSurveyError, setIsSurveyError] = useState(false);

    const handleButtonClick = (): void => {
        onAction();
        setIsOpeningSurvey(true);

        const request = firstName && lastName && email ? { firstName, lastName, email } : undefined;

        CpDataApi.post(getCustomerHappinessIndexEndpoint('de'), request)
            .then(response => {
                setIsOpeningSurvey(false);
                window.open(response?.data?.redirectUrl, '_blank');
            })
            .catch(() => {
                setIsOpeningSurvey(false);
                setIsSurveyError(true);
            });
    };

    if (isLoading || loadingError || email === undefined) {
        return null;
    }

    return (
        <CustomerHappinessIndexWithLoadingHandler
            isLoading={isOpeningSurvey}
            hasError={isSurveyError}
            handleButtonClick={handleButtonClick}
        />
    );
};
