import React from 'react';
import { getChangeNameEndpoint, UserType } from '@cp-de/common';
import { CpDataApi } from 'cp-xhr';
import { DefaultBusinessMarketApiError, DefaultBusinessMarketApiErrorCode } from '@cp-shared-10/common-utilities';
import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-10/frontend-storybook-extensions';

const mockOptions: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const IdentityMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'Change name',
    method: 'put',
    defaultMockOption: 'Success',
    mockOptions,
    url: getChangeNameEndpoint(),
};

export const AutoAboIdentityMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'AutoAbo change name',
    method: 'put',
    defaultMockOption: 'Success',
    mockOptions,
    url: getChangeNameEndpoint(UserType.AUTO_ABO),
};

export const IdentityResponseMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [IdentityMock, AutoAboIdentityMock]);

    return <div>{storyFn()}</div>;
};
