import React from 'react';

import { Faq } from '@cp-de/common';
import { Faq as FaqShared, HeroImage } from '@cp-shared-10/frontend-ui';
import { ContentSection } from '@vwfs-bronson/bronson-react';
import { FaqCMS } from '@cp-shared-10/apis';

type FaqUiProps = {
    faq?: Faq;
};

export const FaqUi: React.FC<FaqUiProps> = ({ faq }) =>
    faq ? (
        <>
            {faq.teaser && (
                <HeroImage
                    title={faq.teaser.title}
                    imageUrl={faq.teaser.imageUrl}
                    shallow={true}
                    inverted={faq.teaser.isInverted}
                />
            )}
            {(faq.content || []).map((data, index: number) => (
                <FaqContent {...data} index={index} key={`FAQ-section-${index}`} />
            ))}
        </>
    ) : null;

const FaqContent: React.FC<FaqCMS & { index: number }> = ({ header, items, descriptionText, itemGroups, index }) => (
    <ContentSection pageWrapSize="medium" className={index !== 0 ? 'u-pt-none' : ''}>
        {items?.length || itemGroups?.length ? (
            <FaqShared header={header} items={items} descriptionText={descriptionText} itemGroups={itemGroups} />
        ) : (
            <>
                <h2>{header}</h2>
                <div>
                    <p>{descriptionText}</p>
                </div>
            </>
        )}
    </ContentSection>
);
