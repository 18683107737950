import React from 'react';
import { useTranslation } from 'react-i18next';

import { getDisplayName } from '@cp-shared-10/frontend-ui';
import { Contract, ContractType } from '@cp-de/common';

import { useFinancialDetails } from '../contracts/financial-details';
import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';

export type withFinancialDetailsDataProps = {
    contract?: Contract;
};

type withFinancialDetailsProps = {
    contract: Contract;
};

const withContractExtensionEnd = <TProps extends object>(
    WrappedComponent: React.FC<TProps>,
): React.FC<TProps & withFinancialDetailsProps> => {
    const Wrapper: React.FC<TProps & withFinancialDetailsProps> = ({
        contract,
        ...props
    }: withFinancialDetailsProps) => {
        const ComponentWithHocs = withLoadingAndNoConnectionHandler(WrappedComponent);

        const { data: financialDetails, isLoading, loadingError } = useFinancialDetails(contract.contractId);
        const { t } = useTranslation('request-document');
        return (
            <ComponentWithHocs
                {...(props as TProps)}
                errorText={t('error-message')}
                isLoading={isLoading}
                hasError={!!loadingError}
                contract={contract}
                contractExtensionEnd={financialDetails?.contractExtensionEnd}
            />
        );
    };

    Wrapper.displayName = `withContractExtensionEnd(${getDisplayName(WrappedComponent)})`;

    return Wrapper;
};

export const withContractExtensionEndForLeasingContracts = <TProps extends object>(
    WrappedComponent: React.FC<TProps>,
): React.FC<TProps & withFinancialDetailsDataProps> => {
    const Wrapper: React.FC<TProps & withFinancialDetailsDataProps> = ({
        contract,
        ...props
    }: withFinancialDetailsDataProps) => {
        if (contract && contract?.contractType === ContractType.LEASING) {
            const ComponentWithHocs = withContractExtensionEnd(WrappedComponent);
            return <ComponentWithHocs {...(props as TProps)} contract={contract} />;
        }
        return <WrappedComponent {...(props as TProps)} contract={contract} />;
    };

    Wrapper.displayName = `withContractExtensionEndForLeasingContracts(${getDisplayName(WrappedComponent)})`;

    return Wrapper;
};
