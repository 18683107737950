import {
    Contract,
    ContractStatus,
    ContractType,
    Dealer,
    DealerAddress,
    DealerContact,
    getFinancialDetailsEndpoint,
    getServiceRequestsEndpoint,
    PaymentStatus,
    PendingContracts,
    ProductType,
    Vehicle,
    getAmortizationTableDownloadEndpoint,
    getRepairApprovalEndpoint,
    ReferralOption,
    getEarlySettlementRequestEndpoint,
    AutoAboContract,
    ReservationContractStatus,
} from '@cp-de/common';
import { VehicleImageView } from '@cp-shared-10/apis';
import { formatCpDate, ISO_DATE_FORMAT } from '@cp-shared-10/common-utilities';

const AudiExampleCar: Vehicle = {
    carBrand: 'Audi',
    carModel: 'A4',
    licensePlate: 'B DU 4020',
};

const SkodaExampleCar: Vehicle = {
    carBrand: 'Škoda',
    carModel: 'Octavia',
    licensePlate: 'B SK 8080',
};

const VWExampleCar: Vehicle = {
    carBrand: 'VW',
    carModel: 'ID3',
    licensePlate: 'B XX 5050',
};

const SeatExampleCar: Vehicle = {
    carBrand: 'Seat',
    carModel: 'Leon',
    licensePlate: 'B LE 1980',
};

export const ExampleCarWithMissingCarBrand: Vehicle = {
    carModel: 'Rapid',
    licensePlate: 'B DU 4121',
};

export const dealerEmail: Dealer = {
    type: 'Email',
};

const dealerAddress: DealerAddress = {
    street: 'Sunrise Avenue',
    houseNumber: '13B',
    zipCode: '10179',
    city: 'Berlin',
};

const dealerContact: DealerContact = {
    email: 'example@digitalunit.berlin',
    phone: '0151 12345678',
};

export const dealerWithAllData: Dealer = {
    type: 'Dealer',
    name: 'Autohaus XYZ',
    address: dealerAddress,
    contact: dealerContact,
};

export const dealerWithMissingData: Dealer = {
    type: 'Dealer',
    address: dealerAddress,
};

export const dealerWithOnlyNameAndPhone: Dealer = {
    type: 'Dealer',
    name: 'Autohaus XYZ',
    contact: { phone: '0151 12345678' },
};

export const ActiveFinancingContract: Contract = {
    contractExceedanceIndicator: false,
    contractExtensionIndicator: false,
    contractId: 'secret10058587',
    contractNumber: '10058587',
    contractStatus: ContractStatus.ACTIVE,
    customerNumber: '',
    endDate: formatCpDate('2024-07-01', ISO_DATE_FORMAT).toCpDate(),
    nextDueAmount: 500,
    nextDueDate: '2020-08-08',
    overpayment: false,
    earlyTermination: {
        status: true,
        type: 'Email',
    },
    paymentPlan: [
        {
            paymentAmount: 400,
            paymentDate: '2019-05-26',
            paymentRateNumber: 1,
            paymentReceiptDate: '2019-05-26',
            outstandingPayment: undefined,
            paymentStatus: undefined,
        },
        {
            paymentAmount: 400,
            paymentDate: '2019-06-26',
            paymentRateNumber: 2,
            paymentReceiptDate: '2019-06-26',
            paymentStatus: PaymentStatus.OFFEN,
            outstandingPayment: undefined,
        },
        {
            paymentAmount: 400,
            paymentDate: '2019-07-26',
            paymentRateNumber: 3,
            paymentReceiptDate: '2019-07-26',
            paymentStatus: PaymentStatus.VOLLBEZAHLT,
            outstandingPayment: undefined,
        },
        {
            outstandingPayment: 310,
            paymentAmount: 400,
            paymentDate: '2019-08-26',
            paymentRateNumber: 4,
            paymentReceiptDate: '2019-08-26',
            paymentStatus: PaymentStatus.TEILBEZAHLT,
        },
        {
            outstandingPayment: 400,
            paymentAmount: 400,
            paymentDate: '2021-09-26',
            paymentRateNumber: 5,
            paymentReceiptDate: '2019-09-26',
            paymentStatus: undefined,
        },
    ],
    contractType: ContractType.FINANCING,
    productType: ProductType.VARIO_CREDIT,
    startDate: '2018-07-31',
    vehicle: AudiExampleCar,
    _links: {
        financialDetails: getFinancialDetailsEndpoint('secret10058587'),
        getServiceRequest: getServiceRequestsEndpoint('secret10058587'),
        postServiceRequest: getServiceRequestsEndpoint('secret10058587'),
        amortizationTableDownload: getAmortizationTableDownloadEndpoint('secret10058587'),
        repairApproval: getRepairApprovalEndpoint('secret10058587'),
        earlySettlementRequest: getEarlySettlementRequestEndpoint('secret10058587'),
    },
    changePaymentDate: {
        dateOfNextInstallment: ['2023-03-05', '2023-03-10'],
        feeAmount: 25,
        feeCurrency: 'EUR',
        currentDate: '2023-02-25',
        lastInstalmentDate: '2023-03-17',
        balloonInstalmentDate: '2023-03-20',
    },
};

export const ActiveFinancingContractWithoutNextDueAmount: Contract = {
    ...ActiveFinancingContract,
    nextDueAmount: undefined,
    paymentPlan: [],
};

export const ActiveFinancingContractWithZeroNextDueAmount: Contract = {
    ...ActiveFinancingContract,
    nextDueAmount: 0,
    paymentPlan: [],
};

export const ActiveFinancingContractWithOutstandingPayment: Contract = {
    ...ActiveFinancingContract,
    outstandingPayment: 120,
};

export const ActiveLeasingContract: Contract = {
    contractExceedanceIndicator: false,
    contractExtensionIndicator: false,
    contractId: 'secret820832921',
    contractNumber: 'A355344',
    contractStatus: ContractStatus.ACTIVE,
    customerNumber: '820832921',
    endDate: '2030-08-01',
    nextDueAmount: 400,
    nextDueDate: '2020-08-08',
    overpayment: false,
    paymentPlan: [],
    productType: ProductType.LEASING,
    contractType: ContractType.LEASING,
    startDate: '2018-07-31',
    vehicle: VWExampleCar,
    _links: {
        financialDetails: getFinancialDetailsEndpoint('secret820832921'),
        getServiceRequest: getServiceRequestsEndpoint('secret820832921'),
        postServiceRequest: getServiceRequestsEndpoint('secret820832921'),
    },
};

export const LeasingContractWithoutCustomerNumber: Contract = {
    ...ActiveLeasingContract,
    customerNumber: undefined,
};

export const ExpiredLeasingContract: Contract = {
    contractExceedanceIndicator: false,
    contractExtensionIndicator: false,
    contractId: 'secret8208329211',
    contractNumber: 'A3553441',
    contractStatus: ContractStatus.EXPIRED,
    customerNumber: '8208329211',
    endDate: '2020-08-01',
    nextDueAmount: 400,
    nextDueDate: '2020-07-08',
    overpayment: false,
    paymentPlan: [],
    productType: ProductType.LEASING,
    contractType: ContractType.LEASING,
    startDate: '2018-07-31',
    vehicle: VWExampleCar,
    _links: {
        financialDetails: getFinancialDetailsEndpoint('secret820832921'),
        getServiceRequest: getServiceRequestsEndpoint('secret820832921'),
        postServiceRequest: getServiceRequestsEndpoint('secret820832921'),
    },
};

export const ActiveServiceManagementContract: Contract = {
    contractExceedanceIndicator: false,
    contractExtensionIndicator: false,
    contractId: 'secret820832922',
    contractNumber: 'A355345',
    contractStatus: ContractStatus.ACTIVE,
    customerNumber: '820832922',
    endDate: '2030-08-01',
    nextDueAmount: 400,
    nextDueDate: '2020-08-08',
    overpayment: false,
    paymentPlan: [],
    productType: ProductType.SERVICE_LEASING_CONTRACT,
    contractType: ContractType.SERVICE_MANAGEMENT,
    startDate: '2018-07-31',
    vehicle: VWExampleCar,
    _links: {
        financialDetails: getFinancialDetailsEndpoint('secret820832922'),
    },
};

export const ExceededActiveLeasingContract: Contract = {
    contractExceedanceIndicator: true,
    contractExtensionIndicator: false,
    contractId: 'secret820838621',
    contractNumber: '820838621',
    contractStatus: ContractStatus.ACTIVE,
    customerNumber: 'C82083862',
    endDate: '2030-08-01',
    nextDueAmount: 0,
    nextDueDate: '2020-08-08',
    overpayment: false,
    paymentPlan: [],
    productType: ProductType.LEASING,
    contractType: ContractType.LEASING,
    startDate: '2018-07-31',
    vehicle: VWExampleCar,
    _links: {
        financialDetails: getFinancialDetailsEndpoint('secret820838621'),
    },
};

export const ExpiredFinancingContract: Contract = {
    contractExceedanceIndicator: false,
    contractExtensionIndicator: false,
    contractId: 'secret10058524',
    contractNumber: '10058524',
    contractStatus: ContractStatus.EXPIRED,
    customerNumber: '',
    endDate: '2022-07-01',
    nextDueAmount: 500,
    nextDueDate: '2020-08-08',
    overpayment: false,
    paymentPlan: [],
    contractType: ContractType.FINANCING,
    productType: ProductType.VARIO_CREDIT,
    startDate: '2018-07-31',
    vehicle: SkodaExampleCar,
    _links: {
        financialDetails: getFinancialDetailsEndpoint('secret10058524'),
    },
};

export const TerminatedFinancingContract: Contract = {
    contractExceedanceIndicator: false,
    contractExtensionIndicator: false,
    contractId: 'secret10058591',
    contractNumber: '10058591',
    contractStatus: ContractStatus.TERMINATED,
    customerNumber: '',
    endDate: '2022-07-01',
    nextDueAmount: 500,
    nextDueDate: '2020-08-08',
    overpayment: false,
    paymentPlan: [],
    contractType: ContractType.FINANCING,
    productType: ProductType.VARIO_CREDIT,
    startDate: '2018-07-31',
    vehicle: SeatExampleCar,
    _links: {
        financialDetails: getFinancialDetailsEndpoint('secret10058591'),
    },
};

export const pendingContracts: PendingContracts = {
    arePendingContractsAvailable: true,
};

export const pendingContractsNotAvailable: PendingContracts = {
    arePendingContractsAvailable: false,
};

export const ContractsWithMultipleLeasing: Contract[] = [
    ActiveFinancingContract,
    ActiveLeasingContract,
    ActiveLeasingContract,
    ActiveServiceManagementContract,
    ActiveLeasingContract,
    ActiveFinancingContract,
    TerminatedFinancingContract,
    ExpiredFinancingContract,
];

export const LeasingContractsWithoutCustomerNumber: Contract[] = [
    LeasingContractWithoutCustomerNumber,
    LeasingContractWithoutCustomerNumber,
    LeasingContractWithoutCustomerNumber,
];

export const ActiveYoungFinancingContract: Contract = {
    contractExceedanceIndicator: false,
    contractExtensionIndicator: false,
    contractId: 'secret10058587',
    contractNumber: '10058587',
    contractStatus: ContractStatus.ACTIVE,
    customerNumber: '',
    endDate: formatCpDate('2022-07-01', ISO_DATE_FORMAT).toCpDate(),
    nextDueAmount: 500,
    nextDueDate: '2020-08-08',
    overpayment: false,
    earlyTermination: {
        status: true,
        type: 'Email',
    },
    paymentPlan: [
        {
            paymentAmount: 400,
            paymentDate: '2019-05-26',
            paymentRateNumber: 1,
            paymentReceiptDate: '2019-05-26',
            outstandingPayment: undefined,
            paymentStatus: undefined,
        },
        {
            paymentAmount: 400,
            paymentDate: '2019-06-26',
            paymentRateNumber: 2,
            paymentReceiptDate: '2019-06-26',
            paymentStatus: PaymentStatus.OFFEN,
            outstandingPayment: undefined,
        },
        {
            paymentAmount: 400,
            paymentDate: '2019-07-26',
            paymentRateNumber: 3,
            paymentReceiptDate: '2019-07-26',
            paymentStatus: PaymentStatus.VOLLBEZAHLT,
            outstandingPayment: undefined,
        },
        {
            outstandingPayment: 310,
            paymentAmount: 400,
            paymentDate: '2019-08-26',
            paymentRateNumber: 4,
            paymentReceiptDate: '2019-08-26',
            paymentStatus: PaymentStatus.TEILBEZAHLT,
        },
        {
            outstandingPayment: 400,
            paymentAmount: 400,
            paymentDate: '2021-09-26',
            paymentRateNumber: 5,
            paymentReceiptDate: '2019-09-26',
            paymentStatus: undefined,
        },
    ],
    contractType: ContractType.FINANCING,
    productType: ProductType.VARIO_CREDIT,
    startDate: '2022-03-31',
    vehicle: AudiExampleCar,
    _links: {
        financialDetails: getFinancialDetailsEndpoint('secret10058587'),
        getServiceRequest: getServiceRequestsEndpoint('secret10058587'),
        postServiceRequest: getServiceRequestsEndpoint('secret10058587'),
        amortizationTableDownload: getAmortizationTableDownloadEndpoint('secret10058587'),
        repairApproval: getRepairApprovalEndpoint('secret10058587'),
    },
};

export const YoungFinancialContractsWithAllValues: Contract[] = [
    ActiveYoungFinancingContract,
    ActiveServiceManagementContract,
    ActiveLeasingContract,
    TerminatedFinancingContract,
    ExpiredFinancingContract,
];

export const YoungActiveLeasingContract: Contract = {
    contractExceedanceIndicator: false,
    contractExtensionIndicator: false,
    contractId: 'secret820832921',
    contractNumber: 'A355344',
    contractStatus: ContractStatus.ACTIVE,
    customerNumber: '820832921',
    endDate: '2030-08-01',
    nextDueAmount: 400,
    nextDueDate: '2020-08-08',
    overpayment: false,
    paymentPlan: [],
    productType: ProductType.LEASING,
    contractType: ContractType.LEASING,
    startDate: '2022-03-31',
    vehicle: VWExampleCar,
    _links: {
        financialDetails: getFinancialDetailsEndpoint('secret820832921'),
        getServiceRequest: getServiceRequestsEndpoint('secret820832921'),
        postServiceRequest: getServiceRequestsEndpoint('secret820832921'),
    },
};

export const YoungLeasingContractsWithAllValues: Contract[] = [
    ActiveFinancingContract,
    ActiveServiceManagementContract,
    YoungActiveLeasingContract,
    TerminatedFinancingContract,
    ExpiredFinancingContract,
];

export const ContractWithDealerInformation: Contract = {
    ...ActiveFinancingContract,
    contractId: 'secretDealer10058587',
    contractNumber: 'Dealer10058587',
    _links: {
        financialDetails: getFinancialDetailsEndpoint('secretDealer10058587'),
        getServiceRequest: getServiceRequestsEndpoint('secretDealer10058587'),
        postServiceRequest: getServiceRequestsEndpoint('secretDealer10058587'),
        amortizationTableDownload: getAmortizationTableDownloadEndpoint('secretDealer10058587'),
        repairApproval: getRepairApprovalEndpoint('secretDealer10058587'),
        earlySettlementRequest: getEarlySettlementRequestEndpoint('secret10058587'),
    },
    dealer: dealerWithAllData,
    vehicleReturnInfo: {
        vehicleReturnAllowed: true,
        referralToDealerAllowed: true,
        referralOption: ReferralOption.REFERRAL_TO_DEALER,
    },
};

export const ContractWithoutDealerInformation: Contract = {
    ...ActiveFinancingContract,
    contractId: 'secretDealer10058587',
    contractNumber: 'Dealer10058587',
    _links: {
        financialDetails: getFinancialDetailsEndpoint('secretDealer10058587'),
        getServiceRequest: getServiceRequestsEndpoint('secretDealer10058587'),
        postServiceRequest: getServiceRequestsEndpoint('secretDealer10058587'),
        amortizationTableDownload: getAmortizationTableDownloadEndpoint('secretDealer10058587'),
        repairApproval: getRepairApprovalEndpoint('secretDealer10058587'),
    },
    vehicleReturnInfo: {
        vehicleReturnAllowed: true,
        referralToDealerAllowed: true,
        referralOption: ReferralOption.REFERRAL_TO_DEALER,
    },
};

export const ContractWithDealerInformationOfChoice: Contract = {
    ...ActiveFinancingContract,
    contractId: 'secretDealer10058587',
    contractNumber: 'Dealer10058587',
    _links: {
        financialDetails: getFinancialDetailsEndpoint('secretDealer10058587'),
        getServiceRequest: getServiceRequestsEndpoint('secretDealer10058587'),
        postServiceRequest: getServiceRequestsEndpoint('secretDealer10058587'),
        amortizationTableDownload: getAmortizationTableDownloadEndpoint('secretDealer10058587'),
        repairApproval: getRepairApprovalEndpoint('secretDealer10058587'),
    },
    vehicleReturnInfo: {
        vehicleReturnAllowed: true,
        referralToDealerAllowed: true,
        referralOption: ReferralOption.REFERRAL_TO_DEALER_OF_CHOICE,
    },
};

export const ContractsWithAllValues: Contract[] = [
    ActiveFinancingContract,
    ActiveServiceManagementContract,
    ActiveLeasingContract,
    TerminatedFinancingContract,
    ExpiredFinancingContract,
    ContractWithDealerInformation,
];

export const ContractWithoutVehicleReturnAllowed: Contract = {
    ...ActiveFinancingContract,
    vehicleReturnInfo: {
        vehicleReturnAllowed: false,
        referralToDealerAllowed: true,
    },
};

export const ContractWithoutReferralToDealerAllowed: Contract = {
    ...ActiveFinancingContract,
    vehicleReturnInfo: {
        vehicleReturnAllowed: true,
        referralToDealerAllowed: false,
    },
};

export const ContractWithVehicleDetailsAndImage: Contract = {
    contractStatus: ContractStatus.ACTIVE,
    contractId: '123456',
    contractNumber: 'JK89',
    vehicleData: {
        vin: 'VW1234567890ACC',
        brand: 'Audi',
        modelName: 'A1',
        modelYear: 2017,
        bodyColorName: 'White',
        bodyColorFinish: 'Metalic',
        fuelType: 'Benzin',
        enginePower: {
            value: '70',
            unit: 'kW',
        },
        transmission: 'Manual',
    },
    vehicleImagesData: {
        vin: 'VW1234567890ACC',
        images: [
            {
                url: '/contents/vwfs/vw_default.svg',
                view: VehicleImageView.EXTERIOR_FRONT_LEFT,
            },
        ],
    },
    vehicle: {
        licensePlate: '1234NB',
    },
};

export const ContractWithVehicleDetailsAndImageForAutoAbo: AutoAboContract = {
    contractId: 'WywGxNn63XBnL2f_BEgm8b8rWD7N-UnTcv7rXxtxJGI',
    contractNumber: '1234567890',
    licensePlate: 'GD908111',
    monthlyPayment: '535.16',
    monthlyMileage: 41,
    minimumContractPeriod: '3',
    startDate: '2022-08-30T22:00:00.000Z',
    contractType: ContractType.AUTO_ABO,
    details: {
        contractDetails: {
            product: '001;G0015',
            startDate: '2022-08-31',
            endDate: '2022-09-16',
            vehicleClass: 'VWFS AutoAbo ID.4',
            status: ReservationContractStatus.SUBSCRIPTION_RESERVATION,
        },
        vehicleDetails: {
            registrationNumber: 'GD908111',
            vin: 'VIN1233333',
            carBrand: 'Audi',
            carModel: 'A3',
            bodyColorName: 'grey',
            bodyColorFinish: 'shine',
            trimValue: 'Attraction',
            engineSizeValue: '2.0',
            engineTechnology: 'TDI',
            enginePowerValue: '110',
            enginePowerUnit: 'kW',
            fuelType: 'Diesel',
            modelYear: 2006,
            transmission: 'automatic',
            carImage: 'http://foto123.jpg',
        },
    },
    isInReservation: false,
    additionalProducts: [
        {
            code: 'CDW_NORMAL',
            codeFamily: 'CDW',
            isSelected: true,
            name: 'CDW_0',
            qualifiers: [
                {
                    id: 'insurance_text',
                    stringValue: '123',
                },
            ],
        },
    ],
};
