import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const timeOutNotificationSlice = createSlice({
    name: 'timeOutNotificationSlice',
    initialState: {
        isSessionExpired: false,
    },
    reducers: {
        setSessionExpired(state, action: PayloadAction<boolean>) {
            state.isSessionExpired = action.payload;
        },
    },
});

export const timeOutNotificationStateReducer = timeOutNotificationSlice.reducer;
export const timeOutNotificationStateActions = timeOutNotificationSlice.actions;
