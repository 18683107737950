import React from 'react';
import { YesNoButtons, RadioButton } from '../types';
import { ValidatedRadioButtonGroup } from '@cp-shared-10/frontend-ui';
import { useTranslation } from 'react-i18next';

type ResponsibleInsuranceRadioGroupProps = {
    name: string;
    label: string;
    tooltip?: string;
    onChange?: (val: YesNoButtons) => void;
    isMandatory?: boolean;
};

export const YesNoRadioGroup: React.FC<ResponsibleInsuranceRadioGroupProps> = ({
    name,
    label,
    tooltip,
    onChange,
    isMandatory = true,
}) => {
    const { t } = useTranslation();

    const yesAndNoButtons: RadioButton[] = [
        { label: t(`translation:yes-no-radio-buttons.${YesNoButtons.yes}`), value: YesNoButtons.yes },
        { label: t(`translation:yes-no-radio-buttons.${YesNoButtons.no}`), value: YesNoButtons.no },
    ];

    return (
        <ValidatedRadioButtonGroup
            label={label}
            tooltip={tooltip}
            radioButtons={yesAndNoButtons}
            name={name}
            testId={name}
            isMandatory={isMandatory}
            handleChange={
                onChange
                    ? (evt: React.ChangeEvent<HTMLInputElement>): void => {
                          onChange(evt.target.value as YesNoButtons);
                      }
                    : undefined
            }
        />
    );
};
