import React from 'react';
import { Contract } from '@cp-de/common';

import { LicensePlate } from '../../license-plate';
import { useTranslation } from 'react-i18next';
import { getCarInformationFromCombinedContract, getContractCategoryTranslationKey } from '../../contract-header';
import { CombinedContractType, isFourSalesContract, isStandardContract } from '../../../utils';

export const ContractFilterItem: React.FC<{ contract: CombinedContractType }> = ({ contract }) => {
    const { t } = useTranslation('contracts');

    const { i18n } = useTranslation();
    const language = i18n.languages[0];
    const carInformation = getCarInformationFromCombinedContract(contract, language);
    const contractCategory = isFourSalesContract(contract)
        ? t('form-labels.FOUR_SALES')
        : t(getContractCategoryTranslationKey(contract as Contract));
    const licensePlate = isStandardContract(contract)
        ? contract.vehicle?.licensePlate
        : contract.details?.vehicleDetails?.registrationNumber;

    return (
        <>
            <div>
                <LicensePlate licensePlate={licensePlate} size={'xsmall'} className={'u-text-base'}></LicensePlate>
                <br />
                <strong> {carInformation}</strong>
                <br />
                {contractCategory + ' ' + contract.contractNumber}
            </div>
        </>
    );
};
