import { YesNoButtons } from './types';
import { FormikProps } from 'formik';
import * as Yup from 'yup';
import { ExpertReportStep1FormValues, ExpertReportStep2FormValues } from '../expert-report/types';
import { TheftStep1FormValues, TheftStep2FormValues } from '../theft/types';
import { TFunction } from 'i18next';
import { TotalLossStep1FormValues, TotalLossStep2FormValues } from '../total-loss/types';
import { ReportDamageResponsibleInsurance, getValue } from '@cp-de/common';
import { InsuranceOrganizationFormValues } from './common-insurance-form';
import { DefinitionListItem } from '@cp-shared-10/frontend-ui';
import { AnyObject } from 'yup/lib/types';
import { MixedSchema } from 'yup/lib/mixed';
import { TranslationFormat, FormatterFunction } from '../../../../localization/useTranslationWithFormatting';

export const maxFileSize = 4 * 1024 * 1024;

export const validFileFormats = ['pdf', 'jpeg', 'jpg', 'png', 'PDF', 'JPEG', 'JPG', 'PNG'];

export const areFilesInCorrectType = (files: File[]): boolean =>
    files.every((file: File) => {
        const ext = file.name.split('.');
        return validFileFormats.includes(ext[ext.length - 1]);
    });

export const isSingleFileWeightInLimit = (files: File[]): boolean =>
    files.every((file: File) => file.size <= maxFileSize);

export const areFilesValid = (files: File[]): boolean =>
    areFilesInCorrectType(files) && isSingleFileWeightInLimit(files);

export const buttonsToBoolean = (buttons: string): boolean | undefined => {
    return buttons === YesNoButtons.yes ? true : buttons === YesNoButtons.no ? false : undefined;
};

export const booleanToButtons = (value: boolean | undefined): string => {
    if (value === undefined) {
        return '';
    }
    return value ? YesNoButtons.yes : YesNoButtons.no;
};

export const buttonsToBooleanOrUndefined = (buttons: string): boolean | undefined => {
    if (!buttons) {
        return undefined;
    }

    return buttonsToBoolean(buttons);
};

export const translateYesNoButtonValue = (button: YesNoButtons, t: TFunction): string => {
    return button === YesNoButtons.yes
        ? t('translation:yes-no-radio-buttons.yes')
        : t('translation:yes-no-radio-buttons.no');
};

export const translateResponsibleInsurenceButton = (button: ReportDamageResponsibleInsurance, t: TFunction): string => {
    switch (button) {
        case ReportDamageResponsibleInsurance.NONE:
            return t('responsible-insurance.buttons.none.label');
        case ReportDamageResponsibleInsurance.OWN:
            return t('responsible-insurance.buttons.own');
        case ReportDamageResponsibleInsurance.OTHER_PARTY:
            return t('responsible-insurance.buttons.other');
        default:
            return '';
    }
};

export const parseFilesName = (files: File[]): string => {
    return files.map(file => file.name).join(', ');
};

export const resetField = (
    {
        setFieldValue,
        setFieldTouched,
    }:
        | FormikProps<ExpertReportStep1FormValues>
        | FormikProps<ExpertReportStep2FormValues>
        | FormikProps<TheftStep1FormValues>
        | FormikProps<TheftStep2FormValues>
        | FormikProps<TotalLossStep1FormValues>
        | FormikProps<TotalLossStep2FormValues>,
    name: string,
): void => {
    setFieldValue(name, '', false);
    setFieldTouched(name, false, false);
};

export const resetFileUpload = (
    {
        setFieldValue,
        setFieldTouched,
    }:
        | FormikProps<ExpertReportStep1FormValues>
        | FormikProps<ExpertReportStep2FormValues>
        | FormikProps<TheftStep1FormValues>
        | FormikProps<TheftStep2FormValues>
        | FormikProps<TotalLossStep1FormValues>
        | FormikProps<TotalLossStep2FormValues>,
    name: string,
): void => {
    setFieldValue(name, [], false);
    setFieldTouched(name, false, false);
};

export const getYesNoValidation = (
    t: TFunction,
): MixedSchema<YesNoButtons | undefined, AnyObject, NonNullable<YesNoButtons | undefined>> =>
    Yup.mixed<YesNoButtons>()
        .oneOf(Object.values(YesNoButtons))
        .required(t('form.error.empty-radio-buttons'));

export const getCommonInsuranceSummaryItems = (
    insurance: InsuranceOrganizationFormValues,
    t: TFunction,
    f: FormatterFunction,
): DefinitionListItem[] => {
    const translationPrefix = 'common.insurance-form';
    const {
        name,
        address: { postOfficeBox, streetName, houseNumber, zipCode, locality },
        contact: { faxNumber, email },
        claimId,
        damageAmount,
    } = insurance;
    return [
        {
            label: t(`${translationPrefix}.name`),
            value: name,
        },
        {
            label: t(`${translationPrefix}.post-office-box.label`),
            value: postOfficeBox,
        },
        {
            label: t(`${translationPrefix}.street-name`),
            value: streetName,
        },
        {
            label: t(`${translationPrefix}.house-number`),
            value: houseNumber,
        },
        {
            label: t(`${translationPrefix}.zip-code`),
            value: zipCode,
        },
        {
            label: t(`${translationPrefix}.locality`),
            value: locality,
        },
        {
            label: t(`${translationPrefix}.email`),
            value: email ? email : undefined,
        },
        {
            label: t(`${translationPrefix}.fax-number`),
            value: faxNumber,
        },
        {
            label: t(`${translationPrefix}.claim-id`),
            value: claimId,
        },
        {
            label: t(`${translationPrefix}.damage-amount`),
            value: damageAmount ? f(getValue(damageAmount), TranslationFormat.CURRENCY) : undefined,
        },
    ].filter(({ value }) => value);
};
