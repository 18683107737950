import { getContractsEndpoint, UserType } from '@cp-de/common';
import { DefaultBusinessMarketApiError, DefaultBusinessMarketApiErrorCode } from '@cp-shared-10/common-utilities';
import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-10/frontend-storybook-extensions';
import React from 'react';
import { CpDataApi } from '../../../cp-xhr';
import { AutoAboContractsWithDifferentValues } from './ExampleData';

const mockOptions: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: AutoAboContractsWithDifferentValues,
        delay: 1000,
    },
    'With empty contracts list': {
        status: 200,
        responseBody: [],
        delay: 1000,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const AutoAboContractsMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'AutoAbo Contracts Response',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions,
    url: getContractsEndpoint(UserType.AUTO_ABO),
};

export const AutoAboContractsResponseMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, AutoAboContractsMock);

    return <div>{storyFn()}</div>;
};
