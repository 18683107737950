import { ContactDetails } from '@cp-de/common';
import { DefinitionListItem } from '@cp-shared-10/frontend-ui';
import { changeContactDetailsPath } from '../../navigation/paths';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { ProfileSection, ProfileSectionEditButtonProps } from '../profile-section';
import { ChangeContactDetails } from './change-contact-details/ChangeContactDetails';
import { EditStatus } from './EditStatus';

type ContactDetailsProps = {
    contact?: ContactDetails;
    isAbo?: boolean;
};

export const ContactDetailsSection: React.FC<ContactDetailsProps> = ({ contact, isAbo }) => {
    const { path } = useRouteMatch();
    const history = useHistory();
    const { t } = useTranslation('my-profile');
    const [lastEditStatus, setLastEditStatus] = useState<EditStatus>(EditStatus.NOT_PERFORMED);

    if (!contact) return null;

    const startEditing = (): void => {
        history.push(changeContactDetailsPath());
    };

    const finishEditing = (newEditStatus: EditStatus, isError?: boolean): void => {
        setLastEditStatus(newEditStatus);
        if (!isError) history.push(path);
    };

    const cancelEditing = (): void => {
        setLastEditStatus(EditStatus.NOT_PERFORMED);
        history.push(path);
    };

    const contactItems: DefinitionListItem[] = [
        {
            label: t('contact.phone-number'),
            value: contact?.mobile?.formattedNumber || '-',
        },
        {
            label: t('contact.e-mail'),
            value: contact?.email || '-',
        },
    ];

    const editButtonProps: ProfileSectionEditButtonProps = {
        label: t('contact.edit-button'),
        onClick: () => startEditing(),
        testId: 'editContactButton',
    };

    const prefix = 'contact.change-contact.notifications.success';

    const successMessage =
        lastEditStatus === EditStatus.SUCCESS
            ? {
                  title: t(`${prefix}.title`),
                  text: t(`${prefix}.text`),
              }
            : undefined;

    return (
        <Switch>
            {isAbo && (
                <Route exact path={changeContactDetailsPath()}>
                    <ChangeContactDetails
                        finishEditing={finishEditing}
                        cancelEditing={cancelEditing}
                        contact={contact}
                    />
                </Route>
            )}
            <Route path={path}>
                <ProfileSection
                    headline={t('contact.headline')}
                    items={contactItems}
                    editButtonProps={isAbo ? editButtonProps : undefined}
                    successMessage={successMessage?.text}
                    successMessageTitle={successMessage?.title}
                />
            </Route>
        </Switch>
    );
};
