import { getContractsEndpoint } from '@cp-de/common';
import { DefaultBusinessMarketApiError, DefaultBusinessMarketApiErrorCode } from '@cp-shared-10/common-utilities';
import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-10/frontend-storybook-extensions';
import React from 'react';
import { CpDataApi } from '../../cp-xhr';
import { ContractsWithAllValues } from './examples/ExampleData';
import { AutoAboContractsMock } from './auto-abo';
import { AoDContractsMock, RaCContractsMock } from './reservations';
import { FourSalesContractsMock } from './four-sales/FourSalesContractsResponseMock';

const mockOptions: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 201,
        responseBody: ContractsWithAllValues,
        delay: 1000,
    },
    'With empty contracts list': {
        status: 200,
        responseBody: [],
        delay: 1000,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const ContractsMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'Contracts Response',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions,
    url: getContractsEndpoint(),
};

export const ContractsResponseMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [
        ContractsMock,
        AutoAboContractsMock,
        AoDContractsMock,
        RaCContractsMock,
        FourSalesContractsMock,
    ]);

    return <div>{storyFn()}</div>;
};
