import { CpDataApi } from 'cp-xhr';
import React from 'react';

import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-10/frontend-storybook-extensions';
import { DefaultBusinessMarketApiError, DefaultBusinessMarketApiErrorCode } from '@cp-shared-10/common-utilities';
import { getLicensingAuthority } from '@cp-de/common';

import { MyProfileWithAllValues } from '../../../../../my-profile/overview';
import { LicensingAuthoritiesWithAllValues } from '../../../../../my-profile/address-section/change-address/request-registration-certificate/licensing-authorities';

const mockOptions: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: LicensingAuthoritiesWithAllValues,
    },
    'Success with more than 6 entries': {
        status: 200,
        responseBody: [
            ...LicensingAuthoritiesWithAllValues,
            ...LicensingAuthoritiesWithAllValues,
            ...LicensingAuthoritiesWithAllValues,
            ...LicensingAuthoritiesWithAllValues,
        ],
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const LicensingAuthoritiesMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'LicensingAuthorities',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions,
    url: getLicensingAuthority(MyProfileWithAllValues.address?.zipCode || ''),
};

export const LicensingAuthorityResponseMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [LicensingAuthoritiesMock]);

    return <div>{storyFn()}</div>;
};
