import React from 'react';
import { useHistory } from 'react-router-dom';

import { AutoLogoutSuccess } from '@cp-de/common';

import { HeroImage } from '@cp-shared-10/frontend-ui';
import { loginChoicePagePath } from '../../navigation/paths';

export const AutoLogoutSuccessUi: React.FC<{ autoLogoutSuccess?: AutoLogoutSuccess }> = ({ autoLogoutSuccess }) => {
    const history = useHistory();

    if (!autoLogoutSuccess) {
        return null;
    }

    return (
        <HeroImage
            title={autoLogoutSuccess?.title || ''}
            subTitle={autoLogoutSuccess?.subTitle}
            buttonText={autoLogoutSuccess?.buttonText}
            clickHandler={() => history.push(loginChoicePagePath())}
        />
    );
};
