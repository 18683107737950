import React from 'react';
// eslint-disable-next-line import/named
import { capitalize } from 'lodash';
import { Layout } from '@vwfs-bronson/bronson-react';
import { LoginOptions } from '@cp-de/common';

type ExternalLoginButtonProps = {
    onClick: () => void;
    imgSrc: string;
    loginOption: LoginOptions;
    label: string;
    buttonsAriaLabels: string;
};

export const ExternalLoginButton: React.FC<ExternalLoginButtonProps> = ({
    onClick,
    imgSrc,
    loginOption,
    label,
    buttonsAriaLabels,
}) => {
    const buttonStyle = {
        border: 'none',
        boxShadow: '0 1px 5px 0 rgba(0,0,0,0.2)',
        width: '44px',
        height: '44px',
    };

    return (
        <Layout.Item default="1/6" s="1/3" className="u-text-center">
            <button
                className="c-btn  c-btn--icon-only  c-btn--secondary  c-btn--round"
                aria-label={buttonsAriaLabels}
                style={buttonStyle}
                onClick={onClick}
                data-testid={`${loginOption}Button`}
            >
                <img aria-hidden="true" alt={`${capitalize(loginOption)} Login`} src={imgSrc} />
            </button>
            <div aria-label={buttonsAriaLabels} className={'u-mt-xsmall'}>
                {label}
            </div>
        </Layout.Item>
    );
};
