import { getProfileEndpoint, Profile, UserType } from '@cp-de/common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-10/common-utilities';
import { getSimpleDataFetchSlice } from '@cp-shared-10/frontend-integration';
import { CpDataApi } from 'cp-xhr';

const { reducer, fetchData } = getSimpleDataFetchSlice<Profile, DefaultBusinessMarketApiErrorCode>({
    dataName: 'myProfileAutoAbo',
    fetchCallback() {
        return CpDataApi.get(getProfileEndpoint(UserType.AUTO_ABO)).then(response => response.data);
    },
});

export default reducer;
export const fetchMyProfileAutoAbo = fetchData;
