import React from 'react';
import { AoDContract, AutoAboContract, Contract, RaCContract, ConsentStatus, FourSalesContract } from '@cp-de/common';
// eslint-disable-next-line import/named
import { isEmpty } from 'lodash';
import { Notification, NotificationStatus } from '@cp-shared-10/frontend-ui';
import { PendingContractNotification } from '../../notifications/pending-contract-notification';
import { useTranslation } from 'react-i18next';
import { ConsentNotification } from '../../notifications/consent-notification/ConsentNotification';

type ContractsNotificationsProps = {
    isFetchingStandardAllowed: boolean;
    isFetchingAboAllowed: boolean;
    isFetchingFourSalesAllowed: boolean;
    isFetchingAodAllowed: boolean;
    isFetchingRacAllowed: boolean;
    arePendingContractsAvailable?: boolean;
    contractsLoadingError: boolean;
    aboContractsLoadingError: boolean;
    fourSalesContractsLoadingError: boolean;
    aodContractsLoadingError: boolean;
    racContractsLoadingError: boolean;
    contracts?: Contract[];
    autoAboContracts?: AutoAboContract[];
    fourSalesContracts?: FourSalesContract[];
    aodContracts?: AoDContract[];
    racContracts?: RaCContract[];
    consent?: ConsentStatus;
};

export const ContractsNotifications: React.FC<ContractsNotificationsProps> = ({
    contracts,
    autoAboContracts,
    fourSalesContracts,
    aodContracts,
    racContracts,
    arePendingContractsAvailable = false,
    contractsLoadingError,
    aboContractsLoadingError,
    fourSalesContractsLoadingError,
    aodContractsLoadingError,
    racContractsLoadingError,
    isFetchingStandardAllowed,
    isFetchingAboAllowed,
    isFetchingFourSalesAllowed,
    isFetchingAodAllowed,
    isFetchingRacAllowed,
    consent,
}) => {
    const { t } = useTranslation(['auto-abo-contracts', 'four-sales-contracts', 'reservation-contract', 'contracts']);
    const pendingNotification = (
        <PendingContractNotification
            testId={'pending-contracts-notification'}
            className={
                !!autoAboContracts?.length || !!aodContracts?.length
                    ? 'u-mb c-notification--context'
                    : 'c-notification--context'
            }
        />
    );

    const getNotification = (
        type:
            | 'noAboContracts'
            | 'noFourSalesContracts'
            | 'noStandardContracts'
            | 'noReservationContracts'
            | 'noContracts'
            | 'noAboConnection'
            | 'noFourSalesConnection'
            | 'noReservationConnection'
            | 'noStandardConnection'
            | 'noConnection',
    ): JSX.Element => {
        let notificationText: string;
        let notificationStatus = NotificationStatus.error;

        switch (type) {
            case 'noAboContracts':
                notificationText = t('auto-abo-contracts:notification.no-abo-contracts');
                notificationStatus = NotificationStatus.warning;
                break;
            case 'noFourSalesContracts':
                notificationText = t('four-sales-contracts:notification.no-four-sales-contracts');
                notificationStatus = NotificationStatus.warning;
                break;
            case 'noStandardContracts':
                notificationText = t('contracts:notification.no-standard-contracts');
                notificationStatus = NotificationStatus.warning;
                break;
            case 'noReservationContracts':
                notificationText = t('reservation-contract:notification.no-reservations');
                notificationStatus = NotificationStatus.warning;
                break;
            case 'noContracts':
                notificationText = t('contracts:notification.no-contracts');
                notificationStatus = NotificationStatus.warning;
                break;
            case 'noAboConnection':
                notificationText = t('auto-abo-contracts:notification.no-abo-connection');
                break;
            case 'noFourSalesConnection':
                notificationText = t('four-sales-contracts:notification.no-four-sales-connection');
                break;
            case 'noReservationConnection':
                notificationText = t('reservation-contract:notification.no-reservation-connection');
                break;
            case 'noStandardConnection':
                notificationText = t('contracts:notification.no-standard-connection');
                break;
            case 'noConnection':
                notificationText = t('contracts:notification.no-connection');
                break;
            default:
                notificationText = '';
                break;
        }

        return (
            <Notification
                status={notificationStatus}
                className="u-mb c-notification--context"
                testId={`${type}-notification`}
            >
                {notificationText}
            </Notification>
        );
    };

    const shouldFetchStandardAndReservations =
        (isFetchingAodAllowed || isFetchingRacAllowed) && isFetchingStandardAllowed;
    const shouldFetchStandardAndAbo = isFetchingAboAllowed && isFetchingStandardAllowed;

    const getNotifications = (): JSX.Element | null | undefined => {
        if (shouldFetchStandardAndReservations) {
            if (
                (isEmpty(aodContracts || racContracts) && contractsLoadingError) ||
                (isEmpty(contracts) && (aodContractsLoadingError || racContractsLoadingError)) ||
                (contractsLoadingError && (aodContractsLoadingError || racContractsLoadingError))
            ) {
                //If user can fetch standard and reservation contracts, but one endpoint fails and the other returns empty array
                return getNotification('noConnection');
            } else if (!isEmpty(aodContracts || racContracts) && contractsLoadingError) {
                //If user can fetch standard and reservation contracts, but standard endpoint fails and reservation returns contract
                return getNotification('noStandardConnection');
            } else if (!isEmpty(contracts) && (aodContractsLoadingError || racContractsLoadingError)) {
                //If user can fetch standard and reservation contracts, but reservation endpoint fails and standard returns contract
                return getNotification('noReservationConnection');
            } else if (isEmpty(contracts) && isEmpty(aodContracts || racContracts)) {
                //If user can fetch standard and reservation contracts, but both endpoints return empty array
                return arePendingContractsAvailable ? pendingNotification : getNotification('noContracts');
            }
        } else if (shouldFetchStandardAndAbo) {
            if (
                (isEmpty(contracts) && aboContractsLoadingError) ||
                (isEmpty(autoAboContracts) && contractsLoadingError) ||
                (contractsLoadingError && aboContractsLoadingError)
            ) {
                //If user can fetch standard and abo contracts, but one endpoint fails and the other returns empty array
                return getNotification('noConnection');
            } else if (!isEmpty(autoAboContracts) && contractsLoadingError) {
                //If user can fetch standard and abo contracts, but standard endpoint fails and abo returns contract
                return getNotification('noStandardConnection');
            } else if (!isEmpty(contracts) && aboContractsLoadingError) {
                //If user can fetch standard and abo contracts, but abo endpoint fails and standard returns contract
                return getNotification('noAboConnection');
            } else if (isEmpty(contracts) && isEmpty(autoAboContracts)) {
                //If user can fetch standard and abo contracts, but both endpoints return empty array
                return arePendingContractsAvailable ? pendingNotification : getNotification('noContracts');
            }
        } else if (
            isFetchingStandardAllowed &&
            !isFetchingAboAllowed &&
            !isFetchingFourSalesAllowed &&
            !isFetchingAodAllowed &&
            !isFetchingRacAllowed &&
            contractsLoadingError
        ) {
            //If user has only standard contract and endpoint fails
            return getNotification('noStandardConnection');
        } else if (
            !isFetchingStandardAllowed &&
            (isFetchingAodAllowed || isFetchingRacAllowed) &&
            !isFetchingAboAllowed &&
            !isFetchingFourSalesAllowed &&
            (aodContractsLoadingError || racContractsLoadingError)
        ) {
            //If user has reservation contract and endpoint fails
            return getNotification('noReservationConnection');
        } else if (
            !isFetchingStandardAllowed &&
            !isFetchingAodAllowed &&
            !isFetchingRacAllowed &&
            !isFetchingFourSalesAllowed &&
            isFetchingAboAllowed &&
            aboContractsLoadingError
        ) {
            //If user has only abo type of contract and endpoint fails
            return getNotification('noAboConnection');
        } else if (
            !isFetchingStandardAllowed &&
            !isFetchingAodAllowed &&
            !isFetchingRacAllowed &&
            isFetchingFourSalesAllowed &&
            !isFetchingAboAllowed &&
            fourSalesContractsLoadingError
        ) {
            //If user has only four sales type of contract and endpoint fails
            return getNotification('noFourSalesConnection');
        } else if (
            isFetchingStandardAllowed &&
            !isFetchingAodAllowed &&
            !isFetchingRacAllowed &&
            !isFetchingAboAllowed &&
            !isFetchingFourSalesAllowed &&
            isEmpty(contracts)
        ) {
            //If user has only standard contract and endpoint returns empty array
            return arePendingContractsAvailable ? pendingNotification : getNotification('noStandardContracts');
        } else if (
            !isFetchingStandardAllowed &&
            (isFetchingAodAllowed || isFetchingRacAllowed) &&
            !isFetchingAboAllowed &&
            !isFetchingFourSalesAllowed &&
            isEmpty(aodContracts || racContracts)
        ) {
            //If user has only reservation  contract and endpoint returns empty array
            return arePendingContractsAvailable ? pendingNotification : getNotification('noReservationContracts');
        } else if (
            !isFetchingStandardAllowed &&
            !isFetchingAodAllowed &&
            !isFetchingRacAllowed &&
            isFetchingAboAllowed &&
            !isFetchingFourSalesAllowed &&
            isEmpty(autoAboContracts)
        ) {
            //If user has only abo type of contract and endpoint returns empty array
            return getNotification('noAboContracts');
        } else if (
            !isFetchingStandardAllowed &&
            !isFetchingAodAllowed &&
            !isFetchingRacAllowed &&
            !isFetchingAboAllowed &&
            isFetchingFourSalesAllowed &&
            isEmpty(fourSalesContracts)
        ) {
            //If user has only four sales type of contract and endpoint returns empty array
            return getNotification('noFourSalesContracts');
        } else return null;
    };
    return (
        <>
            {getNotifications()}
            {!consent?.usageAgreement && <ConsentNotification className="u-mb" />}
        </>
    );
};
