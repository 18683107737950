import { hasError, isLoading, notInitiated } from '@cp-shared-10/frontend-ui';
import { useConsent } from '../../components/consent';
import { useWhatsNewNotification } from '../../components/whats-new-notification/useWhatsNewNotification';
import CryptoJS from 'crypto-js';
import React, { FC } from 'react';
import { Redirect } from 'react-router-dom';
import { useRegistrationFlow } from '../../auth';
import { CenteredSpinner } from '../../components/centered-spinner';
import {
    connectionProblemPagePath,
    consentPagePath,
    dashboardPagePath,
    registrationPagePath,
    whatsNewPagePath,
} from '../../components/navigation/paths';
import { whatsNewPageContentLocalStorageKey } from '../../config';
import { RedirectWithParams } from '../../utils/redirectWithParams';

export const LoginInProgressPage: FC = () => {
    const { cmsContent: whatsNewNotification, isLoading: isLoadingWhatsNewContent } = useWhatsNewNotification();
    const { state: registrationState, initiate: initiateRegistration } = useRegistrationFlow();

    const { data: consent, isLoading: isLoadingConsent, loadingError: loadingErrorConsent } = useConsent();
    const localStorageCurrentWhatsNewPageContent = window.localStorage.getItem(whatsNewPageContentLocalStorageKey);
    const latestWhatsNewPageContent = whatsNewNotification?.textField || '';
    const hashedLatestWhatsNewPageContent = CryptoJS.MD5(latestWhatsNewPageContent).toString();

    const redirectToWhatsNewPage = localStorageCurrentWhatsNewPageContent !== hashedLatestWhatsNewPageContent;

    if (notInitiated(registrationState)) {
        initiateRegistration();
        return <CenteredSpinner />;
    }

    if (hasError(registrationState) || loadingErrorConsent) {
        return <Redirect to={connectionProblemPagePath()} />;
    }

    if (isLoading(registrationState) || isLoadingConsent) {
        return <CenteredSpinner />;
    }

    if (!registrationState.isAuthorized && !registrationState.hookPageNeeded) {
        return <RedirectWithParams to={registrationPagePath()} />;
    }

    if (!consent?.usageAgreement) {
        return <Redirect to={consentPagePath()} />;
    }

    if (isLoadingWhatsNewContent) {
        return <CenteredSpinner />;
    }

    return (
        <Redirect
            to={
                redirectToWhatsNewPage && false // delete this line to enable redirecting to what's new page
                    ? whatsNewPagePath()
                    : dashboardPagePath()
            }
        />
    );
};
