import React, { useState } from 'react';
import {
    DigitalOfferDataFrontend,
    formatAsCurrency,
    formatAsDate,
    formatAsDistance,
    formatAsFuelConsumptionWLTP,
    formatAsEmissionWLTP,
    formatAsFromPrice,
    formatAsDuration,
    formatAsDownPayment,
    formatAsBaloonPayment,
    DigitalOfferProductType,
    DigitalOfferFinancialProduct,
} from '@cp-de/common';
import {
    Heading,
    Paragraph,
    Layout,
    ProductCard,
    Button,
    DescriptionList,
    Footnote,
    Tag,
    TagList,
    StatusMessage,
    CardsSlider,
    Card,
    Modal,
    Carousel,
} from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { NotificationStatus, Notification } from '@cp-shared-10/frontend-ui';
import { loginChoicePagePath } from 'components/navigation/paths';
import { CpDataApi } from 'cp-xhr';
import { CenteredSpinner } from 'components/centered-spinner';

export const DigitalOfferPageUi: React.FC<{ digitalOfferData?: DigitalOfferDataFrontend[] }> = ({
    digitalOfferData: digitalOfferDataFromApi,
}) => {
    const [digitalOfferData, setDigitalOfferData] = useState(digitalOfferDataFromApi);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedVehicleLinkToDelete, setSelectedVehicleLinkToDelete] = useState<string>('');
    const [deletionLoading, setDeletionLoading] = useState(false);
    const [deleteError, setDeleteError] = useState<boolean>(false);
    const [deletionSuccess, setDelitionSuccess] = useState<boolean>(false);

    const { t } = useTranslation('digital-offer');

    type MarketingCardButton = {
        text: string;
        link: string;
    };

    type MarketingCardProduct = {
        icon: string;
        headline: string;
        content: string;
        button: MarketingCardButton;
    };

    const marketingCards: MarketingCardProduct[] = t('marketingCards.products', { returnObjects: true });
    const linksToDealers: MarketingCardProduct[] = t('suitableCarsNotification.links', { returnObjects: true });

    const submitDeleteVehicle = async () => {
        if (selectedVehicleLinkToDelete) {
            setDeletionLoading(true);
            setDelitionSuccess(false);

            CpDataApi.delete(selectedVehicleLinkToDelete)
                .then(() => {
                    setDeletionLoading(false);
                    setShowDeleteModal(false);
                    if (digitalOfferData) {
                        const updatedOfferData = digitalOfferData.filter(
                            vehicle => vehicle.deleteLink !== selectedVehicleLinkToDelete,
                        );
                        setDigitalOfferData(updatedOfferData);
                    }
                })
                .catch(() => {
                    setDeletionLoading(false);
                    setDeleteError(true);
                });
        }
    };

    const handleDeleteVehicle = () => {
        if (deleteError) {
            setShowDeleteModal(false);
            setDeleteError(false);
        } else if (deletionSuccess) {
            setShowDeleteModal(false);
            setDelitionSuccess(false);
        } else {
            submitDeleteVehicle();
        }
    };

    const deletionLoadingErrorOrSuccess = deleteError || deletionLoading || deletionSuccess;

    const renderDeletionModalOkButton = () => {
        if (deleteError || deletionSuccess) {
            return t('translation:editableSectionNav.ok');
        }

        if (deletionLoading) {
            // hide button
            return undefined;
        }

        return t('delete-modal.buttons.confirm');
    };

    const handleCloseModal = (): void => {
        if (!deletionLoading) {
            setShowDeleteModal(false);
            setDeleteError(false);
            setDelitionSuccess(false);
        }
    };

    const renderDeletionModalContent = () => {
        if (deletionLoading) {
            return <CenteredSpinner />;
        }
        if (deleteError) {
            return (
                <Notification status={NotificationStatus.error} className={'u-mb'}>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: t('delete-modal.messages.error'),
                        }}
                    />
                </Notification>
            );
        }
        if (deletionSuccess) {
            return t('delete-modal.messages.success');
        }
        if (selectedVehicleLinkToDelete) {
            return <Paragraph>{t('delete-modal.text')}</Paragraph>;
        }
    };

    function sortFinancialProducts(products?: DigitalOfferFinancialProduct[]): DigitalOfferFinancialProduct[] {
        if (!products?.length) {
            return [];
        }

        const order = {
            PRIVATELEASE: 1,
            AUTOCREDIT: 2,
            CASHPURCHASE: 3,
        };

        return products.slice().sort((a, b) => order[a.product] - order[b.product]);
    }

    if (digitalOfferData && digitalOfferData?.length > 0) {
        return (
            <>
                {showDeleteModal && (
                    <Modal
                        buttonCancelLabel={t('delete-modal.buttons.cancel')}
                        //hides the cancel button on deletionLoading
                        buttonCancelText={deletionLoadingErrorOrSuccess ? undefined : t('delete-modal.buttons.cancel')}
                        buttonConfirmLabel={renderDeletionModalOkButton()}
                        buttonConfirmText={renderDeletionModalOkButton()}
                        onCancel={handleCloseModal}
                        onClose={handleCloseModal}
                        onClickOutside={handleCloseModal}
                        onConfirm={() => handleDeleteVehicle()}
                        confirmationDisabled={deletionLoading}
                        hideCloseButton={deletionLoading}
                        shown={showDeleteModal}
                        title={t('delete-modal.heading')}
                    >
                        {renderDeletionModalContent()}
                    </Modal>
                )}

                <Heading level={2}>{t('heading')}</Heading>
                <Paragraph>{t('subHeading')}</Paragraph>

                <Layout className="u-mb-large">
                    {digitalOfferData.map((offerData, index) => {
                        const { vehicleData, financialProducts, dealerData } = offerData;

                        const isReserverd = offerData.statusType === 'reserved';

                        const renderRedirectButton = offerData.redirectLink ? (
                            <Button
                                onClick={() => window.open(offerData.redirectLink as string)}
                                key={`dealerButton-${index}`}
                            >
                                {t('offerCard.primaryButton')}
                            </Button>
                        ) : (
                            <React.Fragment key={`dealerButton-${index}`}></React.Fragment>
                        );

                        const hasAllDealerDataInformations = dealerData.city || dealerData.companyName;

                        const sortedFinancialProducts = sortFinancialProducts(financialProducts);

                        return (
                            <Layout.Item default="1/4" m="1/2" s="1/1" key={index}>
                                <ProductCard
                                    disabled={isReserverd}
                                    status={
                                        isReserverd && (
                                            <StatusMessage label={t('offerCard.reseverd')} status="warning" />
                                        )
                                    }
                                    // use tagListTitle instead of productTitle to show the title above the tagList
                                    tagListTitle={`${vehicleData?.manufacturer} ${vehicleData?.modelName}`}
                                    tagList={
                                        <TagList>
                                            {vehicleData?.initialRegistrationDate && (
                                                <Tag>{formatAsDate(vehicleData.initialRegistrationDate)}</Tag>
                                            )}
                                            {vehicleData?.fuelType && (
                                                <Tag>{t(`tags.fuelType.${vehicleData.fuelType}`)}</Tag>
                                            )}
                                            {vehicleData?.odometer && (
                                                <Tag>{formatAsDistance(vehicleData.odometer)}</Tag>
                                            )}
                                            {vehicleData?.fuelconsumptionWLTP && (
                                                <Tag>
                                                    {formatAsFuelConsumptionWLTP(vehicleData.fuelconsumptionWLTP)}
                                                </Tag>
                                            )}
                                            {vehicleData?.emissionWLTP && (
                                                <Tag>{formatAsEmissionWLTP(vehicleData.emissionWLTP)}</Tag>
                                            )}
                                            {vehicleData?.efficiencyClass && (
                                                <Tag>{t(`tags.efficiencyClass.${vehicleData.efficiencyClass}`)}</Tag>
                                            )}
                                            {vehicleData?.emissionClass && (
                                                <Tag>{t(`tags.emissionClass.${vehicleData.emissionClass}`)}</Tag>
                                            )}
                                        </TagList>
                                    }
                                    carousel={
                                        <Carousel
                                            objectFitContain
                                            counterEnforced
                                            buttonsOnHover
                                            fullscreen
                                            lazyLoad={2}
                                        >
                                            {vehicleData.images.map((image, imageIndex) => (
                                                <Carousel.Image
                                                    key={imageIndex}
                                                    altText={`image-${imageIndex}`}
                                                    imgSrc={image.m}
                                                    imgSrcSet={`
                                                        ${image.s} 350w,
                                                        ${image.m} 520w,
                                                        ${image.l} 1280w,
                                                        ${image.xl} 1600w"
                                                    `}
                                                    imgSizes={`
                                                        (max-width: 359px) 350px,
                                                        (max-width: 519px) 520px,
                                                        (max-width: 1279px) 1280px,
                                                        1600px"
                                                    `}
                                                />
                                            ))}
                                        </Carousel>
                                    }
                                    text={
                                        <>
                                            <Paragraph>
                                                <strong>{t('offerCard.subHeading')}</strong>
                                            </Paragraph>
                                            <DescriptionList horizontal shortTerms>
                                                {sortedFinancialProducts.map(
                                                    (
                                                        financialProduct: DigitalOfferFinancialProduct,
                                                        findex: number,
                                                    ) => {
                                                        const productTerms = {
                                                            [DigitalOfferProductType.AUTOCREDIT]: t(
                                                                'offerCard.autocredit',
                                                            ),
                                                            [DigitalOfferProductType.PRIVATELEASE]: t(
                                                                'offerCard.privatelease',
                                                            ),
                                                            [DigitalOfferProductType.CASHPURCHASE]: t(
                                                                'offerCard.cashpurchase',
                                                            ),
                                                        };
                                                        const termText = productTerms[financialProduct.product] || '';

                                                        let price;
                                                        switch (financialProduct.product) {
                                                            case DigitalOfferProductType.AUTOCREDIT:
                                                                price = formatAsFromPrice(
                                                                    financialProduct.subsequentInstallmentTotal,
                                                                );
                                                                break;
                                                            case DigitalOfferProductType.PRIVATELEASE:
                                                                price = formatAsFromPrice(
                                                                    financialProduct.subsequentInstallmentTotal,
                                                                );
                                                                break;
                                                            case DigitalOfferProductType.CASHPURCHASE:
                                                                price = formatAsCurrency(offerData.retailPriceAmount);
                                                                break;
                                                            default:
                                                                break;
                                                        }

                                                        const isNotCashpurchase =
                                                            financialProduct.product !==
                                                            DigitalOfferProductType.CASHPURCHASE;

                                                        const isAutocredit =
                                                            financialProduct.product ===
                                                            DigitalOfferProductType.AUTOCREDIT;

                                                        return (
                                                            <DescriptionList.Group
                                                                termText={<b>{termText}</b>}
                                                                className="u-mb-small"
                                                                key={`financialProduct-${index}-${findex}`}
                                                            >
                                                                <DescriptionList.Detail>
                                                                    {price && (
                                                                        <Paragraph className="u-text-brand u-mb-xxsmall">
                                                                            {price}
                                                                        </Paragraph>
                                                                    )}

                                                                    {isNotCashpurchase && (
                                                                        <Paragraph
                                                                            className="u-font-size-fs-2"
                                                                            style={{ fontWeight: 400 }}
                                                                        >
                                                                            {/* Following vales are marked as numbers, because I think the
                                                                            old Typescript Version can't understand the Types proberly. */}
                                                                            {formatAsDuration(
                                                                                financialProduct.duration as number,
                                                                            )}{' '}
                                                                            |{' '}
                                                                            {formatAsDistance(
                                                                                financialProduct.yearlyMileage as number,
                                                                            )}{' '}
                                                                            |{' '}
                                                                            {formatAsDownPayment(
                                                                                financialProduct.downPayment as number,
                                                                                isAutocredit,
                                                                            )}
                                                                            {isAutocredit &&
                                                                                financialProduct.balloonPaymentAmount &&
                                                                                ` | ${formatAsBaloonPayment(
                                                                                    financialProduct.balloonPaymentAmount as number,
                                                                                )}`}
                                                                        </Paragraph>
                                                                    )}
                                                                </DescriptionList.Detail>
                                                            </DescriptionList.Group>
                                                        );
                                                    },
                                                )}
                                            </DescriptionList>
                                        </>
                                    }
                                    buttons={[
                                        renderRedirectButton,
                                        <Button
                                            onClick={() => {
                                                setSelectedVehicleLinkToDelete(offerData.deleteLink);
                                                setShowDeleteModal(true);
                                            }}
                                            small
                                            iconReversed
                                            link
                                            icon="semantic-delete"
                                            key={`deleteButton-${index}`}
                                        >
                                            {t('offerCard.deleteButton')}
                                        </Button>,
                                    ]}
                                    footnotes={
                                        hasAllDealerDataInformations ? (
                                            <Footnote.Item>
                                                <Footnote.Item className="u-font-size-fs-2">
                                                    {offerData?.dealerData?.companyName}, {offerData?.dealerData?.city}
                                                </Footnote.Item>
                                            </Footnote.Item>
                                        ) : (
                                            undefined
                                        )
                                    }
                                />
                            </Layout.Item>
                        );
                    })}

                    <Layout.Item default="4/4">
                        <Paragraph className='"u-font-size-fs-1'>
                            <span dangerouslySetInnerHTML={{ __html: t('disclaimer') }} />
                        </Paragraph>
                    </Layout.Item>
                </Layout>

                <Heading level={2}>{t('marketingCards.heading')}</Heading>
                <CardsSlider groupCells>
                    {marketingCards.map((item, index) => (
                        <CardsSlider.Item key={index}>
                            <Card
                                element="article"
                                icon={item.icon}
                                title={<span dangerouslySetInnerHTML={{ __html: item.headline }} />}
                            >
                                <Paragraph>{item.content}</Paragraph>
                                <Button
                                    link
                                    icon="semantic-forward"
                                    iconReversed
                                    small
                                    onClick={() => window.open(item.button.link)}
                                >
                                    {item.button.text}
                                </Button>
                            </Card>
                        </CardsSlider.Item>
                    ))}
                </CardsSlider>
            </>
        );
    }

    return (
        <>
            <Notification className="u-mb" headline={t('errors.noOffers.heading')} status={NotificationStatus.warning}>
                <span
                    dangerouslySetInnerHTML={{
                        __html: t('errors.noOffers.text', { loginPagePath: loginChoicePagePath() }),
                    }}
                />
            </Notification>

            <Heading level={3}>{t('suitableCarsNotification.heading')}</Heading>
            <Paragraph>{t('suitableCarsNotification.text')}</Paragraph>
            <ul>
                {linksToDealers.map((link, i) => (
                    <li key={`dealerLink-${i}`}>
                        <span
                            dangerouslySetInnerHTML={{
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                __html: (link as any) as string,
                            }}
                        />
                    </li>
                ))}
            </ul>
            <Paragraph>{t('suitableCarsNotification.closingSentence')}</Paragraph>
        </>
    );
};
