import React from 'react';
import { DataOverview, Button, ButtonContainer } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { Notification, NotificationStatus } from '@cp-shared-10/frontend-ui';
import { useHistory } from 'react-router-dom';

import { myProfilePagePath } from '../../navigation/paths';

export const testIds = {
    cancel: 'cancel',
    submit: 'submit',
};

export const OnlineBankingRedirection: React.FC = () => {
    const { t } = useTranslation('my-profile');
    const history = useHistory();

    const redirect = (): void => {
        window.open('https://banking.vwfs.de/PBODE-FE-UI/#/login', '_blank');
    };

    const cancel = (): void => {
        history.push(myProfilePagePath());
    };

    return (
        <DataOverview title={t('online-banking-redirection.title')}>
            <Notification
                status={NotificationStatus.info}
                headline={t('online-banking-redirection.notification.headline')}
                text={t('online-banking-redirection.notification.text')}
            />
            <ButtonContainer nav className="u-mt">
                <Button secondary onClick={cancel} testId={testIds.cancel}>
                    {t('translation:editableSectionNav.cancel')}
                </Button>
                <Button onClick={redirect} testId={testIds.submit}>
                    {t('online-banking-redirection.continue')}
                </Button>
            </ButtonContainer>
        </DataOverview>
    );
};
