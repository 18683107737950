import { getProfileEndpoint, Profile } from '@cp-de/common';
import { getSimpleDataFetchSlice } from '@cp-shared-10/frontend-integration';
import { CpDataApi } from 'cp-xhr';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-10/common-utilities';

const { reducer, fetchData } = getSimpleDataFetchSlice<Profile, DefaultBusinessMarketApiErrorCode>({
    dataName: 'myProfile',
    fetchCallback() {
        return CpDataApi.get(getProfileEndpoint()).then(response => response.data);
    },
});

export default reducer;
export const fetchMyProfile = fetchData;
