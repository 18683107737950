import { Base64File, VehicleImageServiceResponse, VehicleServiceResponse } from '@cp-shared-10/apis';
import { CPDate } from '@cp-shared-10/common-utilities';
import { Dealer, EarlyTermination } from '../../dealer';
import { CommonContract, ContractType } from '../common-contract';
import { ReservationContractStatus } from '../common-reservation';

export interface Contract extends CommonContract {
    /**
     * Enum specifying whether this Contract is Active, Expired or terminated
     */
    contractStatus: ContractStatus;

    /**
     * Boolean flag indicating whether contract extension exists
     */
    contractExtensionIndicator?: boolean;

    /**
     * Boolean flag indicating whether contract exceedance exists
     */
    contractExceedanceIndicator?: boolean;

    /**
     * Customer number on this Contract
     */
    customerNumber?: string;

    /**
     * Contract end date
     */
    endDate?: CPDate;

    /**
     * Contract next due amount
     */
    nextDueAmount?: number;

    /**
     * Contract next due date
     */
    nextDueDate?: CPDate;

    /**
     * The amount of the past due payment on this contract
     */
    outstandingPayment?: number;

    /**
     * Boolean flag indicating whether this Contract has an overpayment/pre-payment amount
     */
    overpayment?: boolean;

    /**
     * Type of contract (Finance, Leasing or SMV)
     */
    contractType?: ContractType;

    /**
     * The product type of this contract
     */
    productType?: ProductType;

    /**
     * Product subtype as seen in Product Mapping here: https://digitalunit-fs.atlassian.net/wiki/spaces/CPV/pages/2001404502/1st+Level+of+Information+DE
     */
    productSubtype?: string;

    productGroup?: string;

    /**
     * Dealer which is working on this contract
     */
    dealer?: Dealer;

    /**
     * Payment plan for this contract
     */
    paymentPlan?: PaymentOverview[];

    /**
     * Contract start date
     */
    startDate?: CPDate;

    /**
     * Vehicle which this contract is for
     */
    vehicle?: Vehicle;

    /**
     * Contract links
     */
    _links?: ContractLinks;

    /**
     * Early termination indicator and dealer which is working on this contract
     */
    earlyTermination?: EarlyTermination;

    /**
     * Vehicle return Info with details and flag if vehicle is allowed for return
     */
    vehicleReturnInfo?: VehicleReturnInfo;

    /**
     * Vehicle data from vehicle service by VIN
     */
    vehicleData?: VehicleServiceResponse;

    /**
     * Vehicle image data from vehicle service by VIN
     */
    vehicleImagesData?: VehicleImageServiceResponse;

    /**
     * Change Payment Date with dates of next installment, fee amount and currency
     */
    changePaymentDate?: {
        dateOfNextInstallment?: string[];
        feeAmount?: number;
        feeCurrency?: string;
        currentDate?: string;
        lastInstalmentDate?: string;
        balloonInstalmentDate?: string;
        hasOutstandingInstalment?: boolean;
    };
}

export type PendingContracts = {
    arePendingContractsAvailable: boolean;
};

export type PaymentOverview = {
    /**
     * Outstanding payment amount
     */
    outstandingPayment?: number;

    /**
     * Payment number in payment plan
     */
    paymentRateNumber: number;

    /**
     * Payment date
     */
    paymentDate: CPDate;

    /**
     * Payment amount
     */
    paymentAmount: number;

    /**
     * Payment status
     */
    paymentStatus?: PaymentStatus;

    /**
     * Day of payment
     */
    paymentReceiptDate?: CPDate;
};

export type Vehicle = {
    /**
     * Vehicle car brand
     */
    carBrand?: string;

    /**
     * Vehicle Car model
     */
    carModel?: string;

    /**
     * Vehicle license plate
     */
    licensePlate?: string;

    /**
     * Vehicle identification number
     */
    vehicleIdentificationNumber?: string;
};

export enum PaymentStatus {
    VOLLBEZAHLT = 'VOLLBEZAHLT',
    TEILBEZAHLT = 'TEILBEZAHLT',
    OFFEN = 'OFFEN',
}

export enum ProductType {
    CLASSIC_CREDIT = 'CLASSIC_CREDIT',
    INDIVIDUAL_CREDIT = 'INDIVIDUAL_CREDIT',
    AUTO_CREDIT = 'AUTO_CREDIT',
    VARIO_CREDIT = 'VARIO_CREDIT',
    OTHER_FINANCING_CONTRACT = 'OTHER_FINANCING_CONTRACT',
    LEASING = 'LEASING',
    SHORT_TERM_LEASING = 'SHORT_TERM_LEASING',
    SERVICE_LEASING_CONTRACT = 'SERVICE_LEASING_CONTRACT',
    SHORT_TERM_LEASING_WITH_SERVICE = 'SHORT_TERM_LEASING_WITH_SERVICE',
    SERVICE_AND_MANAGEMENT_CONTRACT = 'SERVICE_AND_MANAGEMENT_CONTRACT',
}

export enum ContractStatus {
    ACTIVE = 'ACTIVE',
    EXPIRED = 'EXPIRED',
    TERMINATED = 'TERMINATED',
}

export const CombinedStatus = {
    ...ContractStatus,
    ...ReservationContractStatus,
};

export type CombinedContactStatus = typeof CombinedStatus;

export type ContractLinks = {
    // Add contract links as new features are implemented
    financialDetails?: string;
    getServiceRequest?: string;
    postServiceRequest?: string;
    amortizationTableDownload?: string;
    executeEarlyTermination?: string;
    repairApproval?: string;
    earlySettlementRequest?: string;
};

export interface EarlyTerminationRequest {
    mileage: number;
    documentFile: Base64File;
}

export enum EarlyTerminationError {
    EARLY_TERMINATION_NOT_ALLOWED = 'EARLY_TERMINATION_NOT_ALLOWED',
    WRONG_OR_INVALID_DOCUMENT = 'WRONG_OR_INVALID_DOCUMENT',
    VIRUS_SCAN_ERROR = 'VIRUS_SCAN_ERROR',
}

export type VehicleReturnInfo = {
    vehicleReturnAllowed: boolean;
    referralToDealerAllowed?: boolean;
    referralOption?: ReferralOption;
};

export enum ReferralOption {
    REFERRAL_TO_DEALER = 'ReferralToDealer',
    REFERRAL_TO_DEALER_OF_CHOICE = 'ReferralToDealerOfChoice',
}

export interface ChangePaymentDateRequest {
    dateOfNextInstallment: string;
    customerMailAddress: string;
    feeAmount: number;
    feeCurrency: string;
    customerConsent: boolean;
}

export type CancelReservationEmailRequest = {
    status: string;
    data: {
        excessMileagePriceAmount?: number;
        miete6ContractId: string;
        customerData: {
            firstName?: string;
            lastName?: string;
            contactData?: {
                email?: string;
            };
        };
        vehicleData: {
            model: {
                name?: string;
                vehicleImageUrl?: string;
                brand?: string;
                year?: number;
                colorExterior?: string;
                transmissionType?: string;
                engine?: {
                    fuelType?: string;
                    power?: string;
                };
            };
            acrissCode?: string;
        };
        dealerData: {
            companyName?: string;
            contactData?: {
                email?: string;
                telephoneCountryCode?: string;
                telephoneNumber?: string;
            };
            address: {
                street?: string;
                houseNumber?: string;
                zipCode?: string;
                city?: string;
                state?: string;
            };
            openingHours?: string;
        };
        financialProduct: {
            contract: {
                startDate?: string;
                startTime?: string;
                endDate?: string;
                endTime?: string;
            };
            calculation: {
                contractMileage?: string;
                excessMileagePriceAmount?: number;
            };
            additionalProducts?: string;
            totalAmount?: {
                grossAmount?: number;
            };
        };
    };
    eventDefinitionKey: string;
    isPersonAccount: boolean;
    receiverEmail?: string;
};
