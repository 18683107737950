import { AutoAboContract, formatAsCurrency, formatAsDistance } from '@cp-de/common';
import { DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-10/frontend-ui';
import React from 'react';
import {
    TranslationFormat,
    useTranslationWithFormatting,
} from '../../../../../../localization/useTranslationWithFormatting';
// eslint-disable-next-line import/named
import { compact } from 'lodash';
import { useTranslation } from 'react-i18next';

export const AboBaseSection: React.FC<{ contract: AutoAboContract; isInactive?: boolean }> = ({
    contract,
    isInactive,
}) => {
    const { t, f, ready } = useTranslationWithFormatting('auto-abo-contract-details', { useSuspense: false });
    const { t: autoAboTranslation } = useTranslation('auto-abo-contracts');

    const {
        details: { contractDetails },
        monthlyMileage,
        monthlyPayment,
        minimumContractPeriod,
    } = contract;
    const { startDate, vehicleClass } = contractDetails || {};

    const itemList: DefinitionListItem[] = compact([
        {
            label: t('start-date'),
            value: startDate ? f(startDate, TranslationFormat.DATE) : '',
        },
        {
            label: t('vehicle-class'),
            value: vehicleClass,
        },
        isInactive && {
            label: autoAboTranslation('monthly-payment.label'),
            value: monthlyPayment ? formatAsCurrency(Number(monthlyPayment)) : '',
        },
        isInactive && {
            label: autoAboTranslation('monthly-mileage'),
            value: monthlyMileage ? formatAsDistance(monthlyMileage) : '',
        },
        isInactive &&
            minimumContractPeriod && {
                label: autoAboTranslation('minimum-contract-period'),
                value: minimumContractPeriod + ' Monate',
            },
    ]).filter(item => !!item.value);

    return <>{ready && <DefinitionListHorizontal list={itemList} />}</>;
};
