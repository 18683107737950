import { CpDataApi } from 'cp-xhr';
import React from 'react';

import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-10/frontend-storybook-extensions';
import { DefaultBusinessMarketApiError, DefaultBusinessMarketApiErrorCode } from '@cp-shared-10/common-utilities';
import { getContractsEndpoint } from '@cp-de/common';

import { requestCategories } from './request-document-component/request-document-form/examples/RequestCategories';
import { FinancialDetailsWithAllValues } from '../contracts/financial-details/ExampleData';
import { LicensingAuthoritiesMock } from './request-document-component/request-document-form/request-registration-certificate';
import { MyProfileMock } from '../my-profile/overview';
import {
    ActiveFinancingContract,
    ActiveLeasingContract,
    ContractsWithAllValues as Contracts,
} from '../contracts/examples/ExampleData';

const mockOptions: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: requestCategories,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

const mockOptionsContracts: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: Contracts,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

const mockOptionsFinancialDetails: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: FinancialDetailsWithAllValues,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const RequestDocumentMockFinancing: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'Request Categories response',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions,
    url: ActiveFinancingContract._links?.getServiceRequest ?? '',
};

export const RequestDocumentMockLeasing: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'Request Categories response',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions,
    url: ActiveLeasingContract._links?.getServiceRequest ?? '',
};

export const ContractsMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'Contract Response',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions: mockOptionsContracts,
    url: getContractsEndpoint(),
};

export const FinancialDetailsMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'Financial Details Response',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions: mockOptionsFinancialDetails,
    url: ActiveLeasingContract._links?.financialDetails ?? '',
};

export const RequestDocumentResponseMockWrapperForFinancingContract = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [
        RequestDocumentMockFinancing,
        ContractsMock,
        LicensingAuthoritiesMock,
        MyProfileMock,
    ]);

    return <div>{storyFn()}</div>;
};

export const RequestDocumentResponseMockWrapperForLeasingContract = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [
        RequestDocumentMockLeasing,
        ContractsMock,
        FinancialDetailsMock,
        LicensingAuthoritiesMock,
        MyProfileMock,
    ]);

    return <div>{storyFn()}</div>;
};
