import React, { Fragment } from 'react';
import {
    Fieldset,
    Form,
    FormField,
    Heading,
    Input,
    Layout,
    RadioButton,
    FormFieldRadioButtonGroup,
    Notification,
    NotificationsGroup,
} from '@vwfs-bronson/bronson-react';
import { InfoIcon, preventSubmit, useAnalyticsPageViewTracker } from '@cp-shared-10/frontend-ui';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { RepairApprovalClaimType, RepairApprovalRequest } from '@cp-de/common';
import { ClaimType } from './enum';
import { FormNavigationButtons } from '../../form-navigation-buttons';

// eslint-disable-next-line @typescript-eslint/no-empty-function
const emptyFn = () => {};

type FormViewProps = {
    updateRecipient: (value: string, fieldName: string) => void;
    updateClaimType: (value: string) => void;
    updateClaimId: (value: string) => void;
    updateLicensePlate: (value: string) => void;
    updateZIPCode: (value: string) => void;
    invalidFields: { [k: string]: boolean };
    repairApproval: RepairApprovalRequest;
    handleNext: () => void;
    handlePrev: () => void;
};

export const testIds = {
    cancel: 'cancel',
    submit: 'submit',
    streetName: 'streetName',
    houseNumber: 'houseNumber',
    zipCode: 'zipCode',
    city: 'city',
    name: 'name',
    postOfficeBox: 'postOfficeBox',
    locality: 'locality',
    claimId: 'claimId',
    licensePlate: 'licensePlate',
    claimType: 'claimType',
};

export const FormView: React.FC<FormViewProps> = ({
    repairApproval,
    updateRecipient,
    updateClaimType,
    updateClaimId,
    updateLicensePlate,
    updateZIPCode,
    invalidFields,
    handleNext,
    handlePrev,
}) => {
    const { t } = useTranslation('repair-approval');
    const claimType: RepairApprovalClaimType[] = [ClaimType.one, ClaimType.two];

    useAnalyticsPageViewTracker('reportDamageRepairApproval');

    return (
        <Fragment>
            <Layout center>
                <Heading className="u-mt-xlarge" level={4}>
                    {t('step2.hint.title')}
                </Heading>
                <Heading className="u-mb u-text-muted" level={5}>
                    {t('step2.hint.content')}
                </Heading>

                <Layout.Item default="2/3" m={'1/1'}>
                    <Formik
                        initialValues={repairApproval.recipient ? repairApproval.recipient : repairApproval}
                        onSubmit={emptyFn}
                    >
                        {() => (
                            <Form onSubmit={e => preventSubmit(e)} data-testid="fillForm">
                                <Fieldset>
                                    <Fieldset.Row>
                                        <Layout>
                                            <Layout.Item default="1/2" s="1/1">
                                                <FormField labelText={t('form.name')} type="input">
                                                    <Input
                                                        error={invalidFields['name']}
                                                        name="name"
                                                        testId={testIds.name}
                                                        type="text"
                                                        value={repairApproval?.recipient?.name}
                                                        onChange={e => {
                                                            updateRecipient(
                                                                (e.target as HTMLInputElement).value,
                                                                'name',
                                                            );
                                                        }}
                                                    />
                                                    {invalidFields['name'] ? (
                                                        <span className="u-text-alert u-font-size-fs-1">
                                                            {t('form.error.empty-name')}
                                                        </span>
                                                    ) : null}
                                                </FormField>
                                            </Layout.Item>
                                            <Layout.Item default="1/2" s="1/1">
                                                <FormField
                                                    labelText={t('form.post-office-box.label')}
                                                    type="input"
                                                    infoIcon={<InfoIcon text={t('form.post-office-box.tooltip')} />}
                                                >
                                                    <Input
                                                        type="text"
                                                        error={invalidFields['postOfficeBox']}
                                                        name="postOfficeBox"
                                                        id="post-office-box.tooltip"
                                                        testId={testIds.postOfficeBox}
                                                        value={repairApproval?.recipient?.postOfficeBox}
                                                        onChange={e => {
                                                            updateRecipient(
                                                                (e.target as HTMLInputElement).value,
                                                                'postOfficeBox',
                                                            );
                                                        }}
                                                    />
                                                    {invalidFields['postOfficeBox'] ? (
                                                        <span className="u-text-alert u-font-size-fs-1">
                                                            {t('form.error.empty-street-house-nummer')}
                                                        </span>
                                                    ) : null}
                                                </FormField>
                                            </Layout.Item>
                                        </Layout>
                                    </Fieldset.Row>
                                    <Fieldset.Row>
                                        <Layout>
                                            <Layout.Item default="3/4" s="1/1">
                                                <FormField labelText={t('form.street')} type="input">
                                                    <Input
                                                        name="streetName"
                                                        error={invalidFields['streetName']}
                                                        testId={testIds.streetName}
                                                        type="text"
                                                        value={repairApproval?.recipient?.streetName}
                                                        onChange={e => {
                                                            updateRecipient(
                                                                (e.target as HTMLInputElement).value,
                                                                'streetName',
                                                            );
                                                        }}
                                                    />
                                                    {invalidFields['streetName'] ? (
                                                        <span className="u-text-alert u-font-size-fs-1">
                                                            {t('form.error.empty-street-house-nummer')}
                                                        </span>
                                                    ) : null}
                                                </FormField>
                                            </Layout.Item>
                                            <Layout.Item default="1/4" s="1/1">
                                                <FormField labelText={t('form.house-number')} type="input">
                                                    <Input
                                                        name="houseNumber"
                                                        error={invalidFields['houseNumber']}
                                                        testId={testIds.houseNumber}
                                                        type="text"
                                                        value={repairApproval?.recipient?.houseNumber}
                                                        onChange={e => {
                                                            updateRecipient(
                                                                (e.target as HTMLInputElement).value,
                                                                'houseNumber',
                                                            );
                                                        }}
                                                    />
                                                    {invalidFields['houseNumber'] ? (
                                                        <span className="u-text-alert u-font-size-fs-1">
                                                            {t('form.error.empty-street-house-nummer')}
                                                        </span>
                                                    ) : null}
                                                </FormField>
                                            </Layout.Item>
                                        </Layout>
                                    </Fieldset.Row>
                                    <Fieldset.Row>
                                        <Layout>
                                            <Layout.Item default="1/4" s="1/1">
                                                <FormField labelText={t('form.ZIP')} type="input">
                                                    <Input
                                                        type="text"
                                                        error={invalidFields['zipCode']}
                                                        name="zipCode"
                                                        testId={testIds.zipCode}
                                                        value={repairApproval?.recipient?.zipCode}
                                                        onChange={e => {
                                                            updateZIPCode((e.target as HTMLInputElement).value);
                                                        }}
                                                    />
                                                    {invalidFields['zipCode'] ? (
                                                        repairApproval?.recipient?.zipCode?.length === 0 ? (
                                                            <span className="u-text-alert u-font-size-fs-1">
                                                                {t('form.error.empty-ZIP')}
                                                            </span>
                                                        ) : (
                                                            <span className="u-text-alert u-font-size-fs-1">
                                                                {t('form.error.zip-code')}
                                                            </span>
                                                        )
                                                    ) : null}
                                                </FormField>
                                            </Layout.Item>
                                            <Layout.Item default="3/4" s="1/1">
                                                <FormField labelText={t('form.city')} type="input">
                                                    <Input
                                                        name="locality"
                                                        error={invalidFields['locality']}
                                                        testId={testIds.locality}
                                                        type="text"
                                                        value={repairApproval?.recipient?.locality}
                                                        onChange={e => {
                                                            updateRecipient(
                                                                (e.target as HTMLInputElement).value,
                                                                'locality',
                                                            );
                                                        }}
                                                    />
                                                    {invalidFields['locality'] ? (
                                                        <span className="u-text-alert u-font-size-fs-1">
                                                            {t('form.error.empty-city')}
                                                        </span>
                                                    ) : null}
                                                </FormField>
                                            </Layout.Item>
                                        </Layout>
                                    </Fieldset.Row>

                                    <Fieldset.Row>
                                        <Layout>
                                            <Layout.Item>
                                                <NotificationsGroup className="u-mt">
                                                    <Notification visible showCloseButton={false}>
                                                        <span
                                                            dangerouslySetInnerHTML={{
                                                                __html: t('form.insurance-info-box'),
                                                            }}
                                                        />
                                                    </Notification>
                                                </NotificationsGroup>
                                            </Layout.Item>
                                        </Layout>
                                    </Fieldset.Row>

                                    <Fieldset.Row>
                                        <Layout>
                                            <Layout.Item default="1/1" s="1/2">
                                                <FormFieldRadioButtonGroup>
                                                    {claimType.map((claimType, index) => (
                                                        <RadioButton
                                                            className=""
                                                            testId={testIds.claimType}
                                                            key={index}
                                                            value={claimType}
                                                            name="claimType"
                                                            checked={repairApproval?.claimType === claimType}
                                                            onChange={() => updateClaimType(claimType)}
                                                        >
                                                            <div
                                                                className="c-form-field__label"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: t(
                                                                        `form.insurance-radiobutton.${
                                                                            claimType === 'OwnDamageClaim'
                                                                                ? 'hull'
                                                                                : 'liability'
                                                                        }`,
                                                                    ),
                                                                }}
                                                            />
                                                        </RadioButton>
                                                    ))}
                                                </FormFieldRadioButtonGroup>
                                                {invalidFields['claimType'] ? (
                                                    <span className="u-text-alert u-font-size-fs-1">
                                                        {t('form.error.empty-radio-buttons')}
                                                    </span>
                                                ) : null}
                                            </Layout.Item>
                                        </Layout>
                                    </Fieldset.Row>

                                    <Fieldset.Row>
                                        <Layout>
                                            <Layout.Item default="1/2" s="1/1">
                                                <FormField labelText={t('form.damage-number')} type="input">
                                                    <Input
                                                        error={invalidFields['claimId']}
                                                        name="claimId"
                                                        testId={testIds.claimId}
                                                        type="text"
                                                        value={repairApproval?.claimId}
                                                        onChange={e => {
                                                            updateClaimId((e.target as HTMLInputElement).value);
                                                        }}
                                                    />
                                                    {invalidFields['claimId'] ? (
                                                        <span className="u-text-alert u-font-size-fs-1">
                                                            {t('form.error.empty-claimId')}
                                                        </span>
                                                    ) : null}
                                                </FormField>
                                            </Layout.Item>
                                            {repairApproval.claimType === 'LiabilityClaim' ? (
                                                <Layout.Item default="1/2" s="1/1">
                                                    <FormField labelText={t('form.license-plate')} type="input">
                                                        <Input
                                                            error={invalidFields['licensePlate']}
                                                            name="licensePlate"
                                                            testId={testIds.licensePlate}
                                                            type="text"
                                                            value={repairApproval?.licensePlate}
                                                            onChange={e => {
                                                                updateLicensePlate(
                                                                    (e.target as HTMLInputElement).value,
                                                                );
                                                            }}
                                                        />
                                                        {invalidFields['licensePlate'] ? (
                                                            <span className="u-text-alert u-font-size-fs-1">
                                                                {t('form.error.empty-license-plate')}
                                                            </span>
                                                        ) : null}
                                                    </FormField>
                                                </Layout.Item>
                                            ) : null}
                                        </Layout>
                                    </Fieldset.Row>
                                </Fieldset>
                            </Form>
                        )}
                    </Formik>
                </Layout.Item>
                <Layout.Item>
                    <FormNavigationButtons onPrimaryButtonClick={handleNext} onSecondaryButtonClick={handlePrev} />
                </Layout.Item>
            </Layout>
        </Fragment>
    );
};
