import React from 'react';

import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';

import { InboxUi } from './ui';
import { useInbox } from './useInbox';
import { useTranslation } from 'react-i18next';
import { MyDocumentsLoadingPlaceholder } from '@cp-shared-10/frontend-ui';
import { CombinedContractType, isStandardContract } from '../../utils';

const InboxWithHandlers = withLoadingAndNoConnectionHandler(InboxUi);

type InboxProps = {
    contractId?: string;
    allContracts?: CombinedContractType[];
    isLoadingContracts?: boolean;
    isFourSalesContractsLoading?: boolean;
    isFetchingInboxAllowed?: boolean;
};

export const Inbox: React.FC<InboxProps> = ({
    contractId,
    allContracts,
    isLoadingContracts,
    isFourSalesContractsLoading,
    isFetchingInboxAllowed,
}) => {
    const { t } = useTranslation('postbox');

    const { data: inboxDocuments, isLoading: isDocumentsLoading, loadingError: loadingErrorInboxDocuments } = useInbox(
        isFetchingInboxAllowed,
    );

    const isLoading = isDocumentsLoading || !!isLoadingContracts || !!isFourSalesContractsLoading;
    const isInboxDocumentsForbiddenError = loadingErrorInboxDocuments?.status === 403;

    let contractNumber;

    if (contractId) {
        const contract = allContracts?.find(contract => contract.contractId === contractId);
        contractNumber = contract
            ? isStandardContract(contract)
                ? contract.contractNumber
                : contract.contractId
            : undefined;
    }
    return (
        <>
            <InboxWithHandlers
                isLoading={isLoading}
                contracts={allContracts}
                contractNumber={contractNumber}
                inboxDocumentsOriginal={inboxDocuments}
                hasError={!!loadingErrorInboxDocuments && !isInboxDocumentsForbiddenError}
                errorText={t('error-messages.connection-error-inbox', {
                    id: contractNumber,
                    category: 'customerPortalIncident',
                })}
                loadingPlaceholder={<MyDocumentsLoadingPlaceholder />}
            />
        </>
    );
};
