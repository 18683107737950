import React from 'react';
import { Notification, NotificationStatus } from '@cp-shared-10/frontend-ui';
import { ListUi } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { ShippingReason } from '../../../../../request-document/request-document-component/request-document-form/utils/categoryFields';
import { Contract } from '@cp-de/common';
import { isAccipiensContract } from '../../../../../../utils';
import { postboxPagePathWithContractId } from '../../../../../navigation/paths';

const camelToSnakeCase = (str: string): string =>
    str.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`).substring(1);

export const RequestRegistrationCertificateInfo: React.FC<{
    hideSuccessMessage?: boolean;
    shippingReason: ShippingReason | '';
    contract: Contract;
}> = ({ hideSuccessMessage, shippingReason, contract }) => {
    const { t } = useTranslation('my-profile');

    const isAccipiens = isAccipiensContract(contract);

    const listItems = [
        {
            title: t('request-registration-certificate.step-one.title'),
            text: t('request-registration-certificate.step-one.text'),
        },
        {
            title: t('request-registration-certificate.step-two.title'),
            text: t(
                'request-registration-certificate.step-two.text',
                shippingReason && {
                    shippingReason: t(
                        `request-registration-certificate.shipping-reason.${camelToSnakeCase(shippingReason)}`,
                    ),
                },
            ),
        },
        {
            title: t('request-registration-certificate.step-three.title'),
            text: t('request-registration-certificate.step-three.text'),
        },
        {
            title: t('request-registration-certificate.step-four.title'),
            text: t('request-registration-certificate.step-four.text'),
        },
        {
            title: t('request-registration-certificate.step-five.title'),
            text: t('request-registration-certificate.step-five.text'),
        },
    ];

    return (
        <>
            {!hideSuccessMessage && (
                <Notification status={NotificationStatus.success}>{t('change-address.success')}</Notification>
            )}
            {shippingReason && (
                <>
                    <div className="u-mt">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: isAccipiens
                                    ? t('request-registration-certificate.info-text-for-accipiens', {
                                          shippingReason: t(
                                              `request-registration-certificate.shipping-reason.${camelToSnakeCase(
                                                  shippingReason,
                                              )}`,
                                          ),
                                          link: `${postboxPagePathWithContractId(contract.contractId)}`,
                                      })
                                    : t('request-registration-certificate.info-text', {
                                          shippingReason: t(
                                              `request-registration-certificate.shipping-reason.${camelToSnakeCase(
                                                  shippingReason,
                                              )}`,
                                          ),
                                      }),
                            }}
                        />
                    </div>
                    <div className="u-mt">
                        <h6>
                            {t('request-registration-certificate.steps-title', {
                                shippingReason: t(
                                    `request-registration-certificate.shipping-reason.${camelToSnakeCase(
                                        shippingReason,
                                    )}`,
                                ),
                            })}
                        </h6>
                    </div>
                    <div className="u-mt">
                        <ListUi ordered>
                            {listItems.map(item => (
                                <ListUi.Item key={`list${item.title}${item.text}`}>
                                    <div>
                                        <h6>{item.title}</h6>
                                        <div>{item.text}</div>
                                    </div>
                                </ListUi.Item>
                            ))}
                        </ListUi>
                    </div>
                    <div className="u-mv">{t('request-registration-certificate.info-text-two')}</div>
                </>
            )}
        </>
    );
};
