import React, { useEffect, useState } from 'react';
import { Notification, NotificationStatus } from '@cp-shared-10/frontend-ui';
import { useTranslation } from 'react-i18next';
import { addContractSuccessfulStorageKey } from '../../../config';

export const AddContractSuccess: React.FC = () => {
    const { t } = useTranslation('add-contract-success');
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (window.localStorage.getItem(addContractSuccessfulStorageKey) === 'true') {
            setIsVisible(true);
            window.localStorage.setItem(addContractSuccessfulStorageKey, 'false'); // deactivate for refresh
        }
    }, []);

    const rightPaddingClass = 'u-pr u-pr-none@m';

    return (
        <div className={rightPaddingClass}>
            <Notification
                visible={isVisible}
                status={NotificationStatus.success}
                className="u-mb"
                showCloseButton
                onCloseClick={() => setIsVisible(false)}
            >
                {t('message')}
            </Notification>
        </div>
    );
};
