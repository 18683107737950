import React from 'react';
import {
    HeroImage,
    openInSameWindow,
    useAnalyticsErrorPageTracker,
    StaticPageLoadingPlaceholder,
} from '@cp-shared-10/frontend-ui';
import { withLoadingAndNoConnectionHandler } from '../../integration-wrapper';
import { useForbiddenErrorPage } from './useForbiddenErrorPage';

export const ForbiddenErrorPage: React.FC = () => {
    const { cmsContent: forbiddenErrorPage, isLoading, loadingError } = useForbiddenErrorPage();

    const HeroImageWithHandlers = withLoadingAndNoConnectionHandler(HeroImage);

    useAnalyticsErrorPageTracker('accessDeniedError', !!forbiddenErrorPage);
    return (
        <HeroImageWithHandlers
            isLoading={isLoading}
            hasError={!!loadingError}
            pretitle={forbiddenErrorPage?.pretitle}
            title={forbiddenErrorPage?.title || ''}
            subTitle={forbiddenErrorPage?.subTitle}
            buttonText={forbiddenErrorPage?.buttonText}
            clickHandler={openInSameWindow(forbiddenErrorPage?.buttonUrl)}
            loadingPlaceholder={<StaticPageLoadingPlaceholder withHeroTeaser={false} />}
            inverted
        />
    );
};
