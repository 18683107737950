import React, { useState } from 'react';
import { CpDataApi } from 'cp-xhr';
import {
    AddressChange,
    AddressChange as ChangeAddressValues,
    AddressChangeError,
    getChangeAddressEndpoint,
    UserType,
} from '@cp-de/common';
import { Spinner } from '@cp-shared-10/frontend-ui';
import { WithDefaultCpIntegrationErrors } from '@cp-shared-10/common-utilities';
import { parseErrorResponse } from '@cp-shared-10/frontend-integration';

import { ChangeAddressForm } from './change-address-form';
import { AddressSelection } from './address-selection';

type ChangeAddressProps = {
    initialValues: ChangeAddressValues;
    onCancel: (formValues: ChangeAddressValues) => void;
    onSubmitSuccess: () => void;
    onSubmit: (formValues: ChangeAddressValues) => void;
    isAbo?: boolean;
    isContractView?: boolean;
};

export const ChangeAddress: React.FC<ChangeAddressProps> = ({
    onSubmit,
    onSubmitSuccess,
    onCancel,
    isAbo,
    isContractView,
    ...props
}) => {
    type RequestProps = {
        isLoading: boolean;
        error?: WithDefaultCpIntegrationErrors<AddressChangeError>;
        addressSuggestions?: ChangeAddressValues[];
        zipCode?: string;
        requestMade: boolean;
    };

    const [request, setRequest] = useState<RequestProps>({ isLoading: false, requestMade: false });

    const showAddressForm = (): void => {
        setRequest({ ...request, addressSuggestions: undefined, requestMade: false });
    };

    const submit = (formValues: ChangeAddressValues): void => {
        onSubmit(formValues);
        setRequest({ ...request, isLoading: true, error: undefined });
        const changeAddressEndpoint: string = isAbo
            ? getChangeAddressEndpoint(UserType.AUTO_ABO)
            : getChangeAddressEndpoint();
        CpDataApi.put(changeAddressEndpoint, formValues)
            .then(response => {
                const addressSuggestions = !response.data.addressChanged ? response.data.addressSuggestions : undefined;
                const zipCode = response.data.addressChanged ? formValues.zipCode : undefined;
                setRequest({ isLoading: false, error: undefined, addressSuggestions, zipCode, requestMade: true });
                onSubmitSuccess();
            })
            .catch(error => {
                const errorCode = parseErrorResponse<WithDefaultCpIntegrationErrors<AddressChangeError>>(error).code;
                setRequest({
                    isLoading: false,
                    error: errorCode,
                    addressSuggestions: undefined,
                    zipCode: undefined,
                    requestMade: false,
                });
            });
    };

    return (
        <>
            {request.isLoading && <Spinner fullPage={true} />}
            {request.requestMade ? (
                request.addressSuggestions ? (
                    <AddressSelection
                        addresses={request.addressSuggestions}
                        onCancel={showAddressForm}
                        onSubmit={submit}
                        isContractView={isContractView}
                    />
                ) : (
                    onCancel({} as AddressChange)
                )
            ) : (
                <ChangeAddressForm
                    {...props}
                    onSubmit={submit}
                    error={request.error}
                    onCancel={onCancel}
                    isContractView={isContractView}
                />
            )}
        </>
    );
};
