import { AutoAboContract, AutoAboDetails, ContractType, UserRegistrationsResult } from '@cp-de/common';
import {
    AutoAboContractDetailsWithDeliveryAddress,
    AutoAboContractDetailsWithMissingValues,
    AutoAboVehicleDetailsWithAllValues,
    AutoAboContractDetailsWithInactiveStatus,
} from './details';
import { formatCpDate } from '@cp-shared-10/common-utilities';

export const aboContractId = 'encrypted-AX23456';
export const canceledAboContractId = 'encrypted-AX900';

export const AutoAboDetailsWithAllValues: AutoAboDetails = {
    contractDetails: AutoAboContractDetailsWithDeliveryAddress,
    vehicleDetails: AutoAboVehicleDetailsWithAllValues,
};

export const AutoAboDetailsWithInactiveStatus: AutoAboDetails = {
    contractDetails: AutoAboContractDetailsWithInactiveStatus,
    vehicleDetails: AutoAboVehicleDetailsWithAllValues,
};

export const AutoAboDetailsWithMissingValues = {};

export const AutoAboContractWithAllValues: AutoAboContract = {
    contractNumber: 'AX123456',
    contractId: aboContractId,
    contractType: ContractType.AUTO_ABO,
    monthlyPayment: '1234.56',
    monthlyMileage: 1200,
    licensePlate: 'MHR-U735',
    minimumContractPeriod: '3 months',
    startDate: '2019-05-01T22:00:00.000Z',
    details: AutoAboDetailsWithAllValues,
    isInReservation: false,
};

export const AutoAboContractWithInactiveStatus: AutoAboContract = {
    ...AutoAboContractWithAllValues,
    details: AutoAboDetailsWithInactiveStatus,
};

export const AutoAboContractWithMissingValues: AutoAboContract = {
    contractNumber: 'AX789',
    contractId: 'encrypted-AX789',
    contractType: ContractType.AUTO_ABO,
    details: AutoAboDetailsWithMissingValues,
    isInReservation: false,
};

export const AutoAboContractInReservation: AutoAboContract = {
    contractNumber: 'AX800',
    contractId: 'encrypted-AX800',
    contractType: ContractType.AUTO_ABO,
    details: {
        contractDetails: AutoAboContractDetailsWithMissingValues,
    },
    isInReservation: true,
};

export const AutoAboContractInCancellation: AutoAboContract = {
    ...AutoAboContractWithInactiveStatus,
    contractNumber: 'AX900-IN-CANCELLATION',
    contractId: 'encrypted-AX900',
    cancellationNoticeDate: formatCpDate('2022-01-01').toCpDate(),
};

export const AutoAboContractCanceled: AutoAboContract = {
    ...AutoAboContractWithInactiveStatus,
    contractNumber: 'AX900-CANCELED',
    contractId: canceledAboContractId + '-1',
    cancellationNoticeDate: formatCpDate('2022-01-01').toCpDate(),
    cancellationDate: formatCpDate('2022-01-05').toCpDate(),
};

export const AutoAboContractsWithDifferentValues = [
    AutoAboContractWithAllValues,
    AutoAboContractWithMissingValues,
    AutoAboContractInReservation,
    AutoAboContractInCancellation,
    AutoAboContractCanceled,
];

export const RegistrationResponseMock: UserRegistrationsResult = {
    standard: { isRegistered: true, mappingNeeded: true },
    abo: { isRegistered: false },
    aod: { isRegistered: false },
    rac: { isRegistered: false },
    fourSales: { isRegistered: false },
    digitalOffer: { isRegistered: false },
};
