import { Contract } from '@cp-de/common';
import { AbstractDataState, useGetSimpleApiData } from '@cp-shared-10/frontend-integration';
import { fetchContracts } from './ContractsSlice';
import { ContractsDataSelector } from './ContractsDataSelector';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-10/common-utilities';

export function useContracts(
    isFetchingAllowed = true,
): AbstractDataState<Contract[], DefaultBusinessMarketApiErrorCode> {
    return useGetSimpleApiData(fetchContracts, ContractsDataSelector, false, undefined, undefined, isFetchingAllowed);
}
