import { getUserRegistrationStatusEndpoint, PostboxConsents } from '@cp-de/common';
import { getSimpleDataFetchSlice } from '@cp-shared-10/frontend-integration';
import { CpDataApi } from 'cp-xhr';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-10/common-utilities';

const { reducer, fetchData } = getSimpleDataFetchSlice<PostboxConsents, DefaultBusinessMarketApiErrorCode>({
    dataName: 'postboxConsent',
    fetchCallback() {
        return CpDataApi.get(getUserRegistrationStatusEndpoint()).then(response => {
            return response.data.standard.postboxConsents;
        });
    },
});

export default reducer;
export const fetchPostboxConsents = fetchData;
