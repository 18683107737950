import { useAuthentication } from '@cp-shared-10/frontend-ui';
import { NotificationsGroup, Notification } from '@vwfs-bronson/bronson-react';
import { landingPagePath } from '../navigation/paths';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../store/rootReducer';
import { useSelector, useDispatch } from 'react-redux';
import { timeOutNotificationStateActions } from './TimeOutNotificationSlice';

export const TimeOutNotification: React.FC = () => {
    const { t } = useTranslation('time-out-notification');
    const dispatch = useDispatch();
    const { router: router, timeOutNotification: timeOutNotificationState } = useSelector((state: RootState) => state);
    const [timeLeft, setTimeLeft] = useState<number>(5 * 60);
    const { isAuthenticated, logout } = useAuthentication();
    const [isNotificationVisible, setIsNotificationVisible] = useState<boolean>(true);

    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    const formattedSeconds = String(seconds).padStart(2, '0');

    const resetTimer = () => {
        setTimeLeft(5 * 60);
    };

    document.getElementById('root')?.addEventListener('click', resetTimer);
    window.addEventListener('scroll', resetTimer);
    document.getElementById('root')?.addEventListener('keydown', resetTimer);

    const handleLogout = () => {
        const redirectUri = window.location.origin + landingPagePath() + '?sesion_expired=true';
        return logout({ redirectUri });
    };

    const closeNotification = () => {
        setIsNotificationVisible(false);
    };

    useEffect(() => {
        if (router.location.query['sesion_expired'] == 'true') {
            dispatch(timeOutNotificationStateActions.setSessionExpired(true));
        }

        if (!isAuthenticated) {
            return;
        }
        if (isAuthenticated) {
            dispatch(timeOutNotificationStateActions.setSessionExpired(false));
            const timer = setInterval(() => {
                setTimeLeft(prevTime => {
                    if (prevTime <= 1) {
                        clearInterval(timer);
                        handleLogout();
                        return 0;
                    }
                    return prevTime - 1;
                });
            }, 1000);
            return () => clearInterval(timer);
        }
    }, []);

    const displayNotification = () => {
        if (timeOutNotificationState.isSessionExpired) {
            return (
                <NotificationsGroup>
                    <Notification
                        onCloseClick={() => {
                            closeNotification();
                        }}
                        visible={!!isNotificationVisible}
                    >
                        <React.Fragment key=".0">{t('loggedOut')}</React.Fragment>
                    </Notification>
                </NotificationsGroup>
            );
        } else if (isAuthenticated) {
            return (
                <NotificationsGroup>
                    <Notification
                        onCloseClick={() => {
                            closeNotification();
                        }}
                        visible={!!isNotificationVisible}
                    >
                        <React.Fragment key=".0">
                            {t('loggedIn')} {minutes}:{formattedSeconds}
                        </React.Fragment>
                    </Notification>
                </NotificationsGroup>
            );
        } else {
            return <></>;
        }
    };

    return displayNotification();
};
