import React from 'react';
import { Formik } from 'formik';
import { Form, ButtonContainer, Button, Fieldset, Layout, Paragraph } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';

import { preventSubmit, ValidatedSelect, CleaveInput } from '@cp-shared-10/frontend-ui';
import { ServiceRequestCategory, Contract, longDateFormat, ReasonForShipment, ContractType } from '@cp-de/common';

import { RequestRegistrationCertificateInfo } from '../../../my-profile/address-section/change-address/request-registration-certificate/request-registration-certificate-info';
import { getCategoryFields, getShippingReasonFields } from './utils/categoryFields';
import { validationSchema } from './utils/validation';
import { RequestDocumentFormCategory, RequestDocumentFormFields } from './utils/RequestDocumentFormFields';
import { RequestRegistrationCertificate } from './request-registration-certificate';
import { formatCpDate } from '@cp-shared-10/common-utilities';
import { IdentitySection } from '../../../my-profile/identity-section';
import { AddressSection } from '../../../my-profile/address-section';
import { useMyProfile } from '../../../my-profile/overview';
import { withLoadingAndNoConnectionHandler } from '../../../integration-wrapper';

type RequestDocumentFormProps = {
    requestCategories: ServiceRequestCategory[];
    contract: Contract;
    onCancel: () => void;
    onSubmit: (formValues: RequestDocumentFormFields) => void;
    defaultDocumentCategory?: RequestDocumentFormCategory;
    defaultShippingReason?: ReasonForShipment;
};

export const testIds = {
    cancel: 'cancel',
    submit: 'submit',
    category: 'category',
    shippingReason: 'reason',
    year: 'year',
    from: 'from',
    to: 'to',
};

const AddressSectionWithHandlers = withLoadingAndNoConnectionHandler(AddressSection);
const IdentitySectionWithHandlers = withLoadingAndNoConnectionHandler(IdentitySection);

export const RequestDocumentForm: React.FC<RequestDocumentFormProps> = ({
    requestCategories,
    contract,
    onCancel,
    onSubmit,
    defaultDocumentCategory,
    defaultShippingReason,
}) => {
    const { data: myProfile, isLoading, loadingError } = useMyProfile();

    const initialValues: RequestDocumentFormFields = {
        category: defaultDocumentCategory || '',
        year: '',
        shippingReason: defaultShippingReason || '',
        from: contract.startDate ? formatCpDate(contract.startDate).format(longDateFormat) : '',
        to: formatCpDate().format(longDateFormat),
    };

    const { t } = useTranslation('request-document');

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema(t, formatCpDate(contract.startDate).toMoment())}
        >
            {formik => (
                <Form onSubmit={e => preventSubmit(e)} className="u-mt">
                    <Fieldset>
                        <Fieldset.Row>
                            <ValidatedSelect
                                label={t('form-fields.category.label')}
                                name={'category'}
                                id={testIds.category}
                                testId={testIds.category}
                                selectItems={getCategoryFields(requestCategories).map(field => ({
                                    value: field.category,
                                    label: t(field.translationId),
                                }))}
                                placeholder={t('form-fields.category.placeholder')}
                                disablePlaceholder={true}
                            />
                        </Fieldset.Row>
                        {formik.values.category === 'InterestCertificate' && (
                            <Fieldset.Row>
                                <CleaveInput
                                    cleaveOptions={{
                                        blocks: [4],
                                        numericOnly: true,
                                    }}
                                    placeholder="JJJJ"
                                    inputMode={'numeric'}
                                    label={t('form-fields.year.label')}
                                    name={'year'}
                                    testId={testIds.year}
                                />
                            </Fieldset.Row>
                        )}
                        {formik.values.category === 'StatementOfAccount' &&
                            contract.contractType !== ContractType.FINANCING && (
                                <>
                                    <Fieldset.Row>
                                        <Layout>
                                            <Layout.Item default={'1/2'} m={'1/1'}>
                                                <CleaveInput
                                                    cleaveOptions={{
                                                        delimiter: '.',
                                                        blocks: [2, 2, 4],
                                                        numericOnly: true,
                                                    }}
                                                    inputMode={'numeric'}
                                                    label={t('form-fields.from.label')}
                                                    name={'from'}
                                                    testId={testIds.from}
                                                />
                                            </Layout.Item>
                                            <Layout.Item default={'1/2'} m={'1/1'}>
                                                <CleaveInput
                                                    cleaveOptions={{
                                                        delimiter: '.',
                                                        blocks: [2, 2, 4],
                                                        numericOnly: true,
                                                    }}
                                                    inputMode={'numeric'}
                                                    label={t('form-fields.to.label')}
                                                    name={'to'}
                                                    testId={testIds.to}
                                                />
                                            </Layout.Item>
                                        </Layout>
                                    </Fieldset.Row>
                                </>
                            )}
                    </Fieldset>
                    {formik.values.category === 'RequestForZBT2Leasing' ||
                    formik.values.category === 'RequestForZBT2Finance' ? (
                        <>
                            <Fieldset.Row>
                                <ValidatedSelect
                                    label={t('form-fields.shipping-reason.label')}
                                    name={'shippingReason'}
                                    id={testIds.shippingReason}
                                    testId={testIds.shippingReason}
                                    selectItems={getShippingReasonFields(contract.contractType).map(field => ({
                                        value: field.reason,
                                        label: t(field.translationId),
                                    }))}
                                    placeholder={t('form-fields.shipping-reason.placeholder')}
                                    disablePlaceholder={true}
                                />
                            </Fieldset.Row>
                            {['ChangeOfAddress', 'ChangeOfName'].includes(formik.values.shippingReason) && (
                                <Paragraph className={'u-mb'}>{t('info-text')}</Paragraph>
                            )}
                            {formik.values.shippingReason === 'ChangeOfAddress' && myProfile && (
                                <AddressSectionWithHandlers
                                    profile={myProfile}
                                    isLoading={isLoading}
                                    hasError={!!loadingError}
                                />
                            )}
                            {formik.values.shippingReason === 'ChangeOfName' && myProfile && (
                                <IdentitySectionWithHandlers
                                    profile={myProfile}
                                    isLoading={isLoading}
                                    hasError={!!loadingError}
                                />
                            )}
                            <RequestRegistrationCertificateInfo
                                hideSuccessMessage
                                shippingReason={formik.values.shippingReason}
                                contract={contract}
                            />
                            <RequestRegistrationCertificate
                                onSubmit={onSubmit}
                                onCancel={onCancel}
                                category={formik.values.category}
                                shippingReason={formik.values.shippingReason}
                                contract={contract}
                            />
                        </>
                    ) : (
                        <ButtonContainer nav className="u-mt">
                            <Button type="reset" secondary onClick={onCancel} testId={testIds.cancel}>
                                {t('cancel')}
                            </Button>
                            <Button type="submit" onClick={formik.submitForm} testId={testIds.submit}>
                                {t('submit')}
                            </Button>
                        </ButtonContainer>
                    )}
                </Form>
            )}
        </Formik>
    );
};
