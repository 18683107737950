import { CPDate } from '@cp-shared-10/common-utilities';

export type SilentExtensionValues = {
    endDateOfExceeding: CPDate;
    email: string;
};

export const getInitialValues = (email = ''): SilentExtensionValues => ({
    endDateOfExceeding: '',
    email,
});
