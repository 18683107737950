import { isLoading as isLoadingAuth, notInitiated } from '@cp-shared-10/frontend-ui';
import { useRegistrationFlow } from '../auth';
import { useConsent } from '../components/consent';
import { isAuthorizationResult } from '../utils';

type FetchingPermission = {
    isFetchingAboAllowed: boolean;
    isFetchingStandardAllowed: boolean;
    isFetchingAodAllowed: boolean;
    isFetchingRacAllowed: boolean;
    isFetchingFourSalesAllowed: boolean;
    isLoadingPermission: boolean;
};

export const useFetchingPermission = (): FetchingPermission => {
    const { state: registrationState } = useRegistrationFlow();
    const { data: consent } = useConsent(isAuthorizationResult(registrationState) && registrationState.isAuthorized);

    const isFetchingStandardAllowed =
        isAuthorizationResult(registrationState) && registrationState.standard.isRegistered;
    const isFetchingAboAllowed =
        isAuthorizationResult(registrationState) && registrationState.abo.isRegistered && !!consent?.usageAgreement;
    const isFetchingAodAllowed =
        isAuthorizationResult(registrationState) && registrationState.aod.isRegistered && !!consent?.usageAgreement;
    const isFetchingRacAllowed =
        isAuthorizationResult(registrationState) && registrationState.rac.isRegistered && !!consent?.usageAgreement;
    const isFetchingFourSalesAllowed =
        isAuthorizationResult(registrationState) &&
        registrationState.fourSales.isRegistered &&
        !!consent?.usageAgreement;
    const isLoadingPermission = notInitiated(registrationState) || isLoadingAuth(registrationState);

    return {
        isFetchingAboAllowed,
        isFetchingStandardAllowed,
        isFetchingAodAllowed,
        isFetchingRacAllowed,
        isFetchingFourSalesAllowed,
        isLoadingPermission,
    };
};
