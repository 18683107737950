import React from 'react';

import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';

import { AutoLogoutSuccessUi } from './ui';
import { useAutoLogoutSuccess } from './useAutoLogoutSuccess';
import { useAnalyticsPageViewTracker } from '@cp-shared-10/frontend-ui';

const AutoLogoutWithHandlers = withLoadingAndNoConnectionHandler(AutoLogoutSuccessUi);

export const AutoLogoutSuccess: React.FC = () => {
    const { cmsContent: autoLogoutSuccess, isLoading, loadingError } = useAutoLogoutSuccess();
    useAnalyticsPageViewTracker('onAutoLogout');

    return (
        <AutoLogoutWithHandlers isLoading={isLoading} autoLogoutSuccess={autoLogoutSuccess} hasError={!!loadingError} />
    );
};
