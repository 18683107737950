import React from 'react';
import { Layout } from '@vwfs-bronson/bronson-react';
import { Skeleton } from '@cp-shared-10/frontend-ui';

export const AddContractLoadingPlaceholder: React.FC = () => (
    <>
        <Layout className="u-pr u-pr-none@m">
            <Layout.Item default={'1/1'}>
                <Skeleton count={1} height={40} width={'40%'} />
            </Layout.Item>
            <Layout.Item default={'1/1'}>
                <Skeleton height={40} />
            </Layout.Item>
            <Layout.Item default={'1/2'} s={'1/1'}>
                <Skeleton height={40} />
            </Layout.Item>
            <Layout.Item default={'1/2'} s={'1/1'}>
                <Skeleton height={40} />
            </Layout.Item>
            <Layout.Item default={'1/1'}>
                <Skeleton height={40} />
            </Layout.Item>
            <Layout.Item default={'1/1'}>
                <Skeleton height={40} />
            </Layout.Item>
            <Layout.Item default={'1/1'} className={'u-mt-large u-mb'}>
                <Skeleton height={40} />
            </Layout.Item>
            <Layout.Item default={'1/1'} s={'1/1'} className={'u-text-center'}>
                <Skeleton height={40} width={200} />
            </Layout.Item>
        </Layout>
    </>
);
