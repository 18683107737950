import React from 'react';

import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';

import { LegalFooter as LegalFooterUi, LegalFooterLoadingPlaceholder } from '@cp-shared-10/frontend-ui';
import { useLegalFooter } from './useLegalFooter';
import { formatCpDate } from '@cp-shared-10/common-utilities';
import { featureAppLicensesPagePath, loginChoicePagePath } from '../navigation/paths';
import { useLocation } from 'react-router-dom';
import { getUrlSearchParams } from '../../utils/urlSearchParams';

const pagesWithoutLinks = [featureAppLicensesPagePath()];

const LegalFooterWithHandlers = withLoadingAndNoConnectionHandler(LegalFooterUi);

export const LegalFooter: React.FC = () => {
    const { cmsContent: legalFooter, isLoading, loadingError } = useLegalFooter();
    const currentPathName = useLocation().pathname;

    const currentYear = formatCpDate()
        .toMoment()
        .year();

    const hidden = pagesWithoutLinks.includes(currentPathName);

    const aodInvitationMailQueryParam = getUrlSearchParams().get('email');
    if (currentPathName === loginChoicePagePath() && aodInvitationMailQueryParam) {
        return null;
    }

    return (
        <LegalFooterWithHandlers
            isLoading={isLoading}
            hasError={!!loadingError}
            footnotes={legalFooter?.footnotes}
            copyrightText={legalFooter?.copyrightText && `${legalFooter?.copyrightText} ${currentYear}`}
            legalText={legalFooter?.legalText || ''}
            links={hidden ? [] : legalFooter?.links || []}
            loadingPlaceholder={<LegalFooterLoadingPlaceholder />}
        />
    );
};
