import { FourSalesContract, getContractsEndpoint, UserType } from '@cp-de/common';
import { getSimpleDataFetchSlice } from '@cp-shared-10/frontend-integration';
import { CpDataApi } from '../../../cp-xhr';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-10/common-utilities';

const { reducer, fetchData } = getSimpleDataFetchSlice<FourSalesContract[], DefaultBusinessMarketApiErrorCode>({
    dataName: 'fourSalesContracts',
    fetchCallback() {
        return CpDataApi.get(getContractsEndpoint(UserType.FOUR_SALES)).then(response => response.data);
    },
});

export default reducer;
export const fetchFourSalesContracts = fetchData;
