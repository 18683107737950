import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonContainer } from '@vwfs-bronson/bronson-react';
import { formatAsCurrency } from '@cp-de/common';
import { useAnalyticsPageViewTracker } from '@cp-shared-10/frontend-ui';

type ArrearPaymentStepProps = {
    handleNext: () => void;
    handlePrev: () => void;
    outstandingPayment: number;
};

export const ArrearPaymentStep1: React.FC<ArrearPaymentStepProps> = ({
    handleNext,
    handlePrev,
    outstandingPayment,
}) => {
    const { t } = useTranslation('arrear-payment');
    useAnalyticsPageViewTracker('unpaidDetails', true);

    return (
        <>
            <div className={'u-mh-xlarge u-mb u-ph-xlarge u-ph-small@m u-mh-none@m'}>
                <p>{t('step-1.description-1')}</p>
                <p
                    dangerouslySetInnerHTML={{
                        __html: t('step-1.overdue', { overdueAmount: formatAsCurrency(outstandingPayment) }),
                    }}
                />
                <strong>{t('step-1.subtitle')}</strong>
                <p>{t('step-1.description-2')}</p>
            </div>
            <ButtonContainer center className={'u-ph-large@s'}>
                <Button secondary testId="arrear-back-button-1" onClick={handlePrev}>
                    {t('step-1.back-button')}
                </Button>
                <Button testId="arrear-confirm-button-1" onClick={handleNext}>
                    {t('step-1.confirm-button')}
                </Button>
            </ButtonContainer>
        </>
    );
};
