import React, { useState, useEffect } from 'react';
import { Modal } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { CpDataApi } from 'cp-xhr';
import base64ToBlob from 'b64-to-blob';
import { saveAs } from 'file-saver';

import { PostboxDocument, PostboxDocumentFile, UserType } from '@cp-de/common';
import {
    ContractIdentifierFilterItem,
    DocumentTypeFilterItem,
    Notification,
    NotificationStatus,
    Postbox,
    PostboxFilterProps,
    PostboxTableProps,
    TimeFrameFilterItem,
    PostboxDocument as PostboxDocumentRow,
    useAnalyticsActionTracker,
} from '@cp-shared-10/frontend-ui';
import { getAvailableTimeFrames } from '../getAvailableTimeFrames';
import { ContractFilterItem } from './ContractFilterItem';
import { formatCpDate } from '@cp-shared-10/common-utilities';
import { CombinedContractType, isFourSalesContract } from '../../../utils';

export const testIds = {
    downloadFailureModal: 'downloadFailureModal',
};

export const PostboxUi: React.FC<{
    postboxDocuments?: PostboxDocument[];
    contracts?: CombinedContractType[];
    contractNumber?: string;
}> = ({ postboxDocuments, contracts, contractNumber }) => {
    const { t } = useTranslation('postbox');
    const [loadingDocuments, setLoadingDocuments] = useState<{ [key: number]: boolean }>({});
    const [startDownload, setStartDownload] = useState<number | null>(null);
    const [finishDownload, setFinishDownload] = useState<number | null>(null);
    const [downloadError, setDownloadError] = useState<boolean>(false);

    const { onAction: onSuccess } = useAnalyticsActionTracker('onPostboxDownload');

    // UseEffect has to be used to update the loadingDocuments state. Otherwise it is not ensured that the latest loadingDocumets is taken for setLoadingDocuments if several document downloads are started in paralell (due to the asynchronious state update).
    useEffect(() => {
        if (startDownload !== null && !loadingDocuments[startDownload]) {
            setLoadingDocuments({ ...loadingDocuments, [startDownload]: true });
            setStartDownload(null);
        }
        if (finishDownload !== null && loadingDocuments[finishDownload]) {
            setLoadingDocuments({ ...loadingDocuments, [finishDownload]: false });
            setFinishDownload(null);
        }
    }, [startDownload, finishDownload, loadingDocuments]);

    const downloadDocument = (
        index: number,
        postboxDocument: PostboxDocument,
        shouldAppendContractIdToFilename: boolean,
    ): void => {
        setStartDownload(index);
        const { _downloadLink, description, creationDate } = postboxDocument;

        CpDataApi.get(_downloadLink)
            .then(response => {
                const { content }: PostboxDocumentFile = response.data;
                const blob = base64ToBlob(content, response.data.mediaType);
                const extension = response.data.mediaType !== 'application/pdf' ? 'tif' : 'pdf';

                const filenameDescriptionPart = shouldAppendContractIdToFilename
                    ? `${description}${contractNumber || ''}`
                    : description;
                const filename = `${filenameDescriptionPart}_${formatCpDate(creationDate).format(
                    'DDMMYYYY',
                )}.${extension}`;

                saveAs(blob, filename);
                setFinishDownload(index);
                onSuccess(description);
            })
            .catch(() => {
                setFinishDownload(index);
                setDownloadError(true);
            });
    };

    if (!postboxDocuments) {
        return null;
    }

    const documentsAvailable = postboxDocuments.length > 0;

    const getDocumentDescription = (document: PostboxDocument): string => {
        return document.contractType === UserType.FOUR_SALES
            ? t('four-sales-descriptions.' + document.description)
            : document.description;
    };

    function getAllDocumentTypes(postboxDocuments: PostboxDocument[]): string[] {
        const allDocumentTypes = postboxDocuments.map(postboxDocument => getDocumentDescription(postboxDocument));
        return Array.from(new Set(allDocumentTypes));
    }

    const documentTypeFilters: DocumentTypeFilterItem[] = getAllDocumentTypes(postboxDocuments).map(
        postboxDocumentType => {
            const documentTypeFilterItem: DocumentTypeFilterItem = {
                documentType: postboxDocumentType,
                value: postboxDocumentType,
            };
            return documentTypeFilterItem;
        },
    );

    const contractIdentifierFilter: ContractIdentifierFilterItem[] = contracts
        ? contracts.map(contract => {
              const contractIdentifierFilterItem: ContractIdentifierFilterItem = {
                  contractIdentifier: isFourSalesContract(contract) ? contract.contractId : contract.contractNumber,
                  reactNode: <ContractFilterItem contract={contract} />,
                  value: contract.contractNumber,
              };
              return contractIdentifierFilterItem;
          })
        : [];

    const timeFrameFilter: TimeFrameFilterItem[] = getAvailableTimeFrames(postboxDocuments).map(availableTimeFrame => {
        const timeFrame: TimeFrameFilterItem = {
            from: availableTimeFrame.from,
            key: availableTimeFrame.key,
            to: availableTimeFrame.to,
            value: t(availableTimeFrame.value),
        };
        return timeFrame;
    });

    const allLabel = t('all');

    const postboxFilters: PostboxFilterProps = {
        contractIdentifierFilter: {
            label: t('contract-filter'),
            id: 'contract-filter',
            filterItems: contractIdentifierFilter,
            allLabel,
        },
        documentTypeFilter: {
            label: t('document-type-filter'),
            id: 'document-type-filter',
            filterItems: documentTypeFilters,
            allLabel,
        },
        timeFrameFilter: {
            label: t('timeframe-filter'),
            id: 'timeframe-filter',
            filterItems: timeFrameFilter,
            allLabel,
        },
    };

    const postboxDocumentsRows: PostboxDocumentRow[] = postboxDocuments.map((postboxDocument, index) => {
        const documentSubject = ((): string => {
            // In case of 4S, contractNumber under postboxDocument is as encrypted contractId
            const contract = contracts?.find(
                contract =>
                    (isFourSalesContract(contract) ? contract.contractId : contract.contractNumber) ===
                    postboxDocument.contractNumber,
            );
            const contractNumber = contract ? `(${contract.contractNumber})` : '';
            return `${getDocumentDescription(postboxDocument)} ${contractNumber}`;
        })();
        const shouldAppendContractIdToFilename = Boolean(
            postboxDocument.contractNumber && documentSubject.includes(postboxDocument.contractNumber),
        );
        const row: PostboxDocumentRow = {
            contractIdentifier: postboxDocument.contractNumber,
            date: formatCpDate(postboxDocument.creationDate).toMoment(),
            documentId: index,
            loading: !!loadingDocuments[index],
            documentType: getDocumentDescription(postboxDocument),
            onClick: () => downloadDocument(index, postboxDocument, shouldAppendContractIdToFilename),
            subject: documentSubject,
            read: true,
        };
        return row;
    });

    const postboxTableProps: PostboxTableProps = {
        documents: postboxDocumentsRows,
        locale: 'de',
        tableHeaderColumnLabels: { date: t('table.date'), action: t('table.download'), subject: t('table.document') },
        noDocumentsSelectedErrorText: t('error-messages.check-the-filter'),
        sortingOrder: 'DESC',
    };

    if (!documentsAvailable) {
        return (
            <Notification
                className="u-mt c-notification--context"
                status={NotificationStatus.warning}
                text={t('error-messages.no-documents-available')}
                testId={'no-contracts-error'}
            />
        );
    }

    return (
        <>
            <Postbox
                filters={postboxFilters}
                defaultContractIdentifier={contractNumber}
                table={postboxTableProps}
                resetButtonText={t('clear-all-filters')}
            />

            <Modal
                shown={!!downloadError}
                buttonConfirmText={t('translation:editableSectionNav.close')}
                onConfirm={(): void => setDownloadError(false)}
                onClose={(): void => setDownloadError(false)}
                title={t('error-modal.title')}
                status="error"
                testId={testIds.downloadFailureModal}
            >
                {t('error-modal.text')}
            </Modal>
        </>
    );
};
