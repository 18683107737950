import React from 'react';
import { ReportDamageResponsibleInsurance } from '@cp-de/common';
import { RadioButton } from '../types';
import { ValidatedRadioButtonGroup } from '@cp-shared-10/frontend-ui';
import { useTranslation } from 'react-i18next';

type ResponsibleInsuranceRadioGroupProps = {
    name: string;
    options: Array<ReportDamageResponsibleInsurance>;
    onChange: (value: ReportDamageResponsibleInsurance) => void;
};

export const ResponsibleInsuranceRadioGroup: React.FC<ResponsibleInsuranceRadioGroupProps> = ({
    name,
    options,
    onChange,
}) => {
    const { t } = useTranslation('repair-approval');

    const buttons: RadioButton[] = [];
    if (options.includes(ReportDamageResponsibleInsurance.NONE)) {
        buttons.push({
            label: t('responsible-insurance.buttons.none.label'),
            tooltip: t('responsible-insurance.buttons.none.tooltip'),
            value: ReportDamageResponsibleInsurance.NONE,
        });
    }
    if (options.includes(ReportDamageResponsibleInsurance.OWN)) {
        buttons.push({ label: t('responsible-insurance.buttons.own'), value: ReportDamageResponsibleInsurance.OWN });
    }
    if (options.includes(ReportDamageResponsibleInsurance.OTHER_PARTY)) {
        buttons.push({
            label: t('responsible-insurance.buttons.other'),
            value: ReportDamageResponsibleInsurance.OTHER_PARTY,
        });
    }

    return (
        <ValidatedRadioButtonGroup
            label={t('responsible-insurance.label')}
            radioButtons={buttons}
            name={name}
            testId={name}
            handleChange={(evt: React.ChangeEvent<HTMLInputElement>): void => {
                onChange(evt.target.value as ReportDamageResponsibleInsurance);
            }}
        />
    );
};
