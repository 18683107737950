import { Contract, ContractStatus, ContractType } from '@cp-de/common';
import { ContractHeader as ContractHeaderShared, useAnalyticsActionTracker } from '@cp-shared-10/frontend-ui';
import { Button } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { LicensePlate } from '../license-plate';
import { getContractCategoryTranslationKey } from './getContractCategoryTranslationKey';
import { getCarInformation } from './getCarInformation';
import { isAccipiensContract, isDirectCredit } from '../../utils';

export type ContractHeaderProps = {
    contract: Contract;
    contractSummary?: React.ReactNode;
    isDeferralLinkShown?: boolean;
    hideLicencePlate?: boolean;
};

export const ContractHeader: React.FC<ContractHeaderProps> = ({
    contract,
    contractSummary,
    isDeferralLinkShown = true,
    hideLicencePlate,
}) => {
    const { t } = useTranslation('contracts');

    const { i18n } = useTranslation();
    const language = i18n.languages[0];

    const { onAction } = useAnalyticsActionTracker('onOpenSuspendInstallment');

    const handleClick = (): void => {
        onAction();
        window.open(t('deferral-button.link-url'), '_blank');
    };

    const isAccipiens = isAccipiensContract(contract);
    const isDirectCreditGroup = isDirectCredit(contract);

    const licensePlate = <LicensePlate licensePlate={contract.vehicle?.licensePlate} />;

    const carInformation =
        isAccipiens && isDirectCreditGroup ? t('direct-credit-title') : getCarInformation(contract, language);

    const translationKey = getContractCategoryTranslationKey(contract);
    const contractCategory = translationKey ? t(translationKey) : '';

    const showDeferralLink = isDeferralLinkShown
        ? contract.contractType === ContractType.FINANCING && contract.contractStatus === ContractStatus.ACTIVE
        : false;

    return (
        <>
            <ContractHeaderShared
                carInformation={carInformation}
                contractCategory={contractCategory}
                contractNumber={contract.contractNumber}
                contractIdentifier={hideLicencePlate || (isAccipiens && isDirectCreditGroup) ? null : licensePlate}
                contractSummary={contractSummary}
            />
            {showDeferralLink && (
                <Button
                    small
                    className="u-pb-small"
                    title={t('deferral-button.label')}
                    testId={'deferral-button'}
                    element="a"
                    href={t('deferral-button.link-url')}
                    icon="semantic-forward"
                    iconReversed
                    onClick={handleClick}
                >
                    {t('deferral-button.label')}
                </Button>
            )}
        </>
    );
};
