import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ConsentRequest, ConsentStatus, getConsentEndpoint } from '@cp-de/common';
import { Formik } from 'formik';

import { InfoCheckbox, preventSubmit } from '@cp-shared-10/frontend-ui';
import { Button, ButtonContainer, Fieldset, Form, Layout } from '@vwfs-bronson/bronson-react';
import { CpDataApi } from '../../../cp-xhr';
import { targetNewTab } from '../../../utils/string-utils';
import { getAbsoluteUrlWithParams } from '../../../utils/urlSearchParams';
import { dashboardPagePath } from '../../navigation/paths';
import { ConsentDescription } from '../ConsentDescription';
import { ConsentError } from './consent-error/ConsentError';
import { ConsentFormFields } from './ConsentFormFields';
import { consentUiTestIds } from './consentUiTestIds';
import { consentValidationSchema } from './validations';

export const ConsentUi: React.FC<{ consent?: ConsentStatus; usageAgreement?: string }> = ({
    consent,
    usageAgreement,
}) => {
    type RequestProps = {
        isLoading: boolean;
        error?: boolean;
    };
    const [request, setRequest] = useState<RequestProps>({ isLoading: false, error: false });

    const { t } = useTranslation('consent');
    const dashboardUrl = getAbsoluteUrlWithParams(dashboardPagePath());

    if (!consent || !usageAgreement) {
        return null;
    }

    const initialValues: ConsentFormFields = {
        confirmUsageAgreement: consent?.usageAgreement ? undefined : false,
        confirmMarketing: !consent?.marketing,
        confirmDataStorage: !consent?.dataStorage,
    };

    return (
        <Layout>
            <ConsentError error={request.error} />
            <ConsentDescription usageAgreement={usageAgreement} isFirstLogin={consent.isFirstLogin} />
            <Layout.Item>
                <Formik
                    initialValues={initialValues}
                    validationSchema={consentValidationSchema(t)}
                    onSubmit={(values: ConsentFormFields): void => {
                        setRequest({ isLoading: true, error: request.error });

                        const consentRequest: ConsentRequest = {
                            usageAgreement: !!consent?.usageAgreement || !!values.confirmUsageAgreement,
                            marketing: !!consent?.marketing && !!values.confirmMarketing,
                            dataStorage: !!consent?.dataStorage && !!values.confirmDataStorage,
                            dataStorageVersionDetailsId: consent?.dataStorage?.versionDetailsId,
                        };

                        CpDataApi.post(getConsentEndpoint(), consentRequest)
                            .then(() => {
                                setRequest({ isLoading: false, error: false });
                                window.location.assign(dashboardUrl);
                            })
                            .catch(() => {
                                setRequest({ isLoading: false, error: true });
                            });
                    }}
                >
                    {formik => (
                        <Form onSubmit={e => preventSubmit(e)}>
                            <Fieldset>
                                {!consent?.usageAgreement && (
                                    <InfoCheckbox
                                        preLinkLabel={t('form.usage-agreement.pre')}
                                        linkLabel={t('form.usage-agreement.link')}
                                        afterLinkLabel={t('form.usage-agreement.after')}
                                        fieldName={consentUiTestIds.confirmUsageAgreement}
                                        testId={consentUiTestIds.confirmUsageAgreement}
                                        testIdInfoText={consentUiTestIds.confirmUsageAgreement}
                                    >
                                        <div dangerouslySetInnerHTML={{ __html: usageAgreement }} />
                                    </InfoCheckbox>
                                )}
                                {consent.marketing && (
                                    <InfoCheckbox
                                        preLinkLabel={t('form.marketing.pre')}
                                        linkLabel={t('form.marketing.link')}
                                        afterLinkLabel={t('form.marketing.after')}
                                        fieldName={consentUiTestIds.confirmMarketing}
                                        testId={consentUiTestIds.confirmMarketing}
                                        testIdInfoText={consentUiTestIds.confirmMarketing}
                                    >
                                        <div
                                            dangerouslySetInnerHTML={{ __html: targetNewTab(consent.marketing.text) }}
                                        />
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: targetNewTab(consent.marketing.disclaimer),
                                            }}
                                        />
                                    </InfoCheckbox>
                                )}
                                {consent.dataStorage && (
                                    <InfoCheckbox
                                        preLinkLabel={t('form.data-storage.pre')}
                                        linkLabel={t('form.data-storage.link')}
                                        afterLinkLabel={''}
                                        fieldName={consentUiTestIds.confirmDataStorage}
                                        testId={consentUiTestIds.confirmDataStorage}
                                        testIdInfoText={consentUiTestIds.confirmDataStorage}
                                    >
                                        <div
                                            dangerouslySetInnerHTML={{ __html: targetNewTab(consent.dataStorage.text) }}
                                        />
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: targetNewTab(consent.dataStorage.disclaimer),
                                            }}
                                        />
                                    </InfoCheckbox>
                                )}
                                <Fieldset.Row>
                                    <Button
                                        onClick={(e): void => {
                                            e.preventDefault();
                                            window.open('https://www.vwfs.de/metaseiten/datenschutz.html', '_blank');
                                        }}
                                        link={true}
                                        testId={consentUiTestIds.showPrivacyPolicy}
                                    >
                                        {t('form.privacy-policy.label')}
                                    </Button>
                                </Fieldset.Row>
                                <Fieldset.Row>
                                    <ButtonContainer center>
                                        {!consent.isFirstLogin && (
                                            <Button
                                                full={true}
                                                onClick={(): void => {
                                                    window.location.assign(dashboardPagePath());
                                                }}
                                                testId={consentUiTestIds.submitLaterButton}
                                                disabled={consent.isFirstSeenMoreThenFourWeeksAgo}
                                            >
                                                {t('form.submit-later')}
                                            </Button>
                                        )}
                                        <Button
                                            full={true}
                                            onClick={formik.submitForm}
                                            testId={consentUiTestIds.submitButton}
                                            disabled={
                                                request.isLoading ||
                                                (!formik.values.confirmUsageAgreement && consent.isFirstLogin)
                                            }
                                        >
                                            {t('form.submit')}
                                        </Button>
                                    </ButtonContainer>
                                </Fieldset.Row>
                            </Fieldset>
                        </Form>
                    )}
                </Formik>
            </Layout.Item>
        </Layout>
    );
};
