import React from 'react';
import { Contract } from '@cp-de/common';
import { DashboardHeroTeaserUi } from './DashboardHeroTeaserUi';
import { useDashboardMarketingCard } from '../dashboard-marketing-cards/useDashboardMarketingCard';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { MarketingCardLoadingPlaceholder } from '@cp-shared-10/frontend-ui';

interface DashboardHeroTeasersProps {
    contracts?: Contract[];
    userWithMotorInsurance: boolean;
    userWithAutoAbo: boolean;
}

export const DashboardHeroTeaser: React.FC<DashboardHeroTeasersProps> = ({
    contracts,
    userWithMotorInsurance,
    userWithAutoAbo,
}) => {
    const { isLoading, loadingError } = useDashboardMarketingCard();
    const DashboardHeroTeaserUiWithHandlers = withLoadingAndNoConnectionHandler(DashboardHeroTeaserUi);

    return (
        <DashboardHeroTeaserUiWithHandlers
            isLoading={isLoading}
            hasError={!!loadingError}
            contracts={contracts}
            userWithMotorInsurance={userWithMotorInsurance}
            userWithAutoAbo={userWithAutoAbo}
            loadingPlaceholder={<MarketingCardLoadingPlaceholder />}
        />
    );
};
