import React from 'react';
import { Accordion } from '@vwfs-bronson/bronson-react';
import { AoDContract, RaCContract, ReservationContractStatus, ContractType, UserType } from '@cp-de/common';
import { useTranslation } from 'react-i18next';
import { ContractActionItemType, ContractActions, useAnalyticsActionTracker } from '@cp-shared-10/frontend-ui';
// eslint-disable-next-line import/named
import { compact } from 'lodash';
import { cancelReservationPagePath } from '../../../../navigation/paths';
import { ReservationContractDetails } from '../reservation-contract-details';
import { ReservationContractParties } from '../reservation-contract-parties';
import { formatCpDate } from '@cp-shared-10/common-utilities';
import { useHistory } from 'react-router-dom';

type ContractDetailsUiProps = {
    contract: RaCContract | AoDContract;
};

export const ReservationDetails: React.FC<ContractDetailsUiProps> = ({ contract }) => {
    const { t } = useTranslation('reservation-contract');
    const history = useHistory();
    const { onAction: onCancelContract } = useAnalyticsActionTracker('onCancelContract');

    const { contractStatus, contractType } = contract;
    const { customerDetails } = contract.details;

    const isReservationStarted = formatCpDate(contract?.startDate)
        .toMoment()
        .isAfter(formatCpDate().toMoment());

    const userType: UserType = contractType === ContractType.AUDI_ON_DEMAND ? UserType.AOD : UserType.RAC;

    const actionItems: ContractActionItemType[] = compact([
        !(contractStatus === ReservationContractStatus.RENT_CANCELLED) &&
            isReservationStarted && {
                iconName: 'semantic-crossed-out',
                onClick: () => {
                    onCancelContract();
                    history.push(cancelReservationPagePath(contract?.contractId, userType.toLocaleLowerCase()));
                },
                pageUrl: cancelReservationPagePath(contract?.contractId, userType.toLocaleLowerCase()),
                label: t('cancel-reservation-button'),
            },
    ]);

    return (
        <Accordion>
            <ContractActions contractActionItems={actionItems} className="u-mt-none u-mb" />
            <Accordion.Item title={t('reservation-details-header')} className={'u-mt'}>
                <ReservationContractDetails contract={contract} />
            </Accordion.Item>
            <Accordion.Item title={t('contract-parties-header')}>
                <ReservationContractParties customerDetails={customerDetails} />
            </Accordion.Item>
        </Accordion>
    );
};
