import { ConsentStatus } from '@cp-de/common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-10/common-utilities';
import { AbstractDataState, useGetSimpleApiData } from '@cp-shared-10/frontend-integration';
import { ConsentDataSelector } from './ConsentDataSelector';
import { fetchConsent } from './ConsentSlice';

export function useConsent(
    isFetchingAllowed = true,
): AbstractDataState<ConsentStatus, DefaultBusinessMarketApiErrorCode> {
    return useGetSimpleApiData(fetchConsent, ConsentDataSelector, false, undefined, undefined, isFetchingAllowed);
}
