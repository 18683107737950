import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from '@cp-shared-10/frontend-ui';
import { Profile, AuthorityAddress } from '@cp-de/common';
import { withLoadingAndNoConnectionHandler } from '../../../../../integration-wrapper';
import { useLicensingAuthorities } from './useLicensingAuthorities';
import { useCustomLicensingAuthorities } from './custom-licensing-authorities/useCustomLicensingAuthorities';
import { NoConnectionNotification } from '../../../../../notifications/no-connection/NoConnectionNotification';
import { LicensingAuthorities as LicensingAuthorityTiles } from '../../../../../my-profile/address-section/change-address/request-registration-certificate/licensing-authorities';

const LicensingAuthoritiesWithHandlers = withLoadingAndNoConnectionHandler(LicensingAuthorityTiles);

type LicensingAuthoritiesComponentProps = {
    zipCode: string;
    onSelect: (legalAuthority?: AuthorityAddress, allowUndefined?: boolean) => void;
    selected?: number;
    customZipCode?: boolean;
    showRoadTrafficOfficesInfo?: boolean;
    setShowWarning?: React.Dispatch<React.SetStateAction<boolean>>;
    setCustomLoadingError?: React.Dispatch<React.SetStateAction<boolean>>;
};

const LicensingAuthoritiesComponent: React.FC<LicensingAuthoritiesComponentProps> = ({
    zipCode,
    onSelect,
    customZipCode,
    showRoadTrafficOfficesInfo,
    setShowWarning,
    setCustomLoadingError,
}) => {
    const { t } = useTranslation('my-profile');
    const { data: licensingAuthorities, isLoading: isLoading1, loadingError: loadingError1 } = useLicensingAuthorities(
        !customZipCode ? zipCode : '0',
        false,
    );
    const {
        data: customLicensingAuthorities,
        isLoading: isLoading2,
        loadingError: loadingError2,
    } = useCustomLicensingAuthorities(customZipCode ? zipCode : '0', true);
    const authorityObject = {
        authories: !!customZipCode ? customLicensingAuthorities : licensingAuthorities,
        isLoading: !!customZipCode ? isLoading2 : isLoading1,
        loadingError: !!customZipCode ? loadingError2 : loadingError1,
    };
    // Select first licensingAuthority by default
    useEffect(() => {
        if (authorityObject.authories && authorityObject.authories.length > 0) {
            onSelect(authorityObject.authories[0], !licensingAuthorities?.length);
            return;
        }
        onSelect(undefined, !licensingAuthorities?.length);
    }, [licensingAuthorities, customLicensingAuthorities, onSelect, authorityObject.authories]);

    if (customZipCode && loadingError1 && loadingError2) {
        !!setShowWarning && setShowWarning(true);
    }

    if (!!setCustomLoadingError) {
        setCustomLoadingError(!!loadingError2);
    }

    const handleSelect = (selected: number): void => {
        onSelect(authorityObject.authories?.[selected], !licensingAuthorities?.length);
    };

    return (
        <LicensingAuthoritiesWithHandlers
            isLoading={authorityObject.isLoading}
            licensingAuthorities={authorityObject.authories?.slice(0, 6)}
            hasError={!customZipCode && !!authorityObject.loadingError}
            errorText={t('licensing-authorities.loading-error')}
            onSelect={handleSelect}
            showRoadTrafficOfficesInfo={showRoadTrafficOfficesInfo}
        />
    );
};

export type LicensingAuthorityProps = {
    myProfileData?: Profile;
    customZipCode?: string;
    isLoading: boolean;
    loadingError: boolean;
    showRoadTrafficOfficesInfo?: boolean;
    onSelect: (legalAuthority?: AuthorityAddress, allowUndefined?: boolean) => void;
    setShowWarning?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const LicensingAuthorities: React.FC<LicensingAuthorityProps> = ({
    myProfileData,
    isLoading,
    loadingError,
    customZipCode,
    showRoadTrafficOfficesInfo,
    onSelect,
    setShowWarning,
}) => {
    const { t } = useTranslation('my-profile');
    const processedZipCode = customZipCode === '' ? '0' : customZipCode;
    const [customLoadingError, setCustomLoadingError] = useState(false);
    if (processedZipCode) {
        return (
            <>
                <LicensingAuthoritiesComponent
                    zipCode={processedZipCode}
                    onSelect={onSelect}
                    setCustomLoadingError={setCustomLoadingError}
                    customZipCode={true}
                    showRoadTrafficOfficesInfo={showRoadTrafficOfficesInfo}
                    setShowWarning={setShowWarning}
                />
                {customLoadingError && (
                    <NoConnectionNotification
                        errorTitle={''}
                        errorText={t('licensing-authorities.custom-zip-code-loading-error')}
                    />
                )}
            </>
        );
    }
    if (isLoading) {
        return <Spinner center />;
    }

    if (loadingError || (myProfileData && !myProfileData.address?.zipCode)) {
        return <NoConnectionNotification errorTitle={''} errorText={t('licensing-authorities.loading-error')} />;
    }

    // LicensingAuthoritiesComponent must not be invoked before my profile data with zip code has been loaded
    // because of the useLicensingAuthorities hook which needs the zip code as value
    if (!myProfileData?.address?.zipCode) {
        return null;
    }
    return (
        <LicensingAuthoritiesComponent
            zipCode={myProfileData.address.zipCode}
            onSelect={onSelect}
            showRoadTrafficOfficesInfo={showRoadTrafficOfficesInfo}
        />
    );
};
