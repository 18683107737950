import {
    Contract,
    CustomerVehicleDocumentReceiver,
    EarlySettlementRequest,
    FinancialDetails,
    longDateFormat,
    marketApiDateFormat,
    ProductType,
    Profile,
    Requester,
    VehicleDocumentReceiver,
} from '@cp-de/common';
import { Spinner, useAnalyticsActionTracker } from '@cp-shared-10/frontend-ui';
import { Card } from '@vwfs-bronson/bronson-react';
import base64ToBlob from 'b64-to-blob';
import { ContractHeader } from '../../contract-header';
import { CpDataApi } from 'cp-xhr';
import { saveAs } from 'file-saver';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { dashboardPagePath } from '../../navigation/paths';
import { FormOfAddress, Tab } from '../form/enums';
import { InitialValues } from '../form/initialValues';
import { stripWhitespaces } from '../form/third-party-form/iban-validation-view/ibanValidations';
import { TotalEarlySettlementForm } from '../form/TotalEarlySettlementForm';
import { TotalEarlySettlementModal } from '../modal';
import { IbanStateHandler } from '../types';
import { isDirectCredit } from '../../../utils';

export type TotalEarlySettlementUiProps = {
    contract?: Contract;
    financialDetails?: FinancialDetails;
    myProfile?: Profile;
    ibanStateHandler: IbanStateHandler;
};
type RequestProps = {
    status?: 'IS_LOADING' | 'HAS_ERROR' | 'SUCCESS';
};

const withAdditionalFields = (
    contract: Contract,
    requester: Requester,
    formValues: InitialValues,
    withKbs: boolean,
    bankIdentificationCode: string,
): EarlySettlementRequest => {
    const {
        contractRedemptionDate,
        isThirdPartyRequester,
        selectedTab,
        person,
        business,
        accountHolder = '',
        iban,
        vehicleDocumentReceiver = VehicleDocumentReceiver.CUSTOMER,
        customerVehicleDocumentReceiver = CustomerVehicleDocumentReceiver.CUSTOMER,
        terminatePpi = 'false',
    } = formValues;

    const formattedContractRedemptionDate = moment(contractRedemptionDate, longDateFormat).format(marketApiDateFormat);

    let additionalProperties: { [key: string]: string | number } = {};
    let documentReceiver: VehicleDocumentReceiver | CustomerVehicleDocumentReceiver = customerVehicleDocumentReceiver;

    if (isThirdPartyRequester) {
        const customerType =
            selectedTab === Tab.PERSON ? person : { ...business, formOfAddress: FormOfAddress.COMPANY };
        const ibanWithoutSpaces = stripWhitespaces(iban);
        const ibanValues = {
            internationalBankAccountNumber: ibanWithoutSpaces,
            bankIdentificationCode,
        };

        const properties = {
            ...customerType,
            ...ibanValues,
            accountHolder,
        };

        additionalProperties = withKbs ? { ...properties, terminatePpi } : properties;
        documentReceiver = vehicleDocumentReceiver;
    }

    const isClassicCredit = contract.productType === ProductType.CLASSIC_CREDIT;
    const isDirectCreditGroup = isDirectCredit(contract);

    return {
        requester,
        contractRedemptionDate: formattedContractRedemptionDate,
        vehicleDocumentReceiver: isClassicCredit && isDirectCreditGroup ? undefined : documentReceiver,
        additionalProperties,
    };
};

export const TotalEarlySettlementUi: React.FC<TotalEarlySettlementUiProps> = ({
    contract,
    financialDetails,
    myProfile,
    ibanStateHandler,
}) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [thirdPartyDocument, setThirdPartyDocument] = useState<string>();
    const [isThirdPartyRequester, setIsThirdPartyRequester] = useState<boolean>(false);
    const [request, setRequest] = useState<RequestProps>({});
    const { t } = useTranslation('total-early-settlement');

    const history = useHistory();
    const { onAction } = useAnalyticsActionTracker('onEditEarlySettlementSummaryLayerSuccess');

    if (!contract) return null;

    const withKbs: boolean =
        !!financialDetails?.additionalProducts &&
        financialDetails.additionalProducts.some(({ productId }: { productId?: string }) => productId);

    const onSubmit = (formValues: InitialValues): void => {
        const { isThirdPartyRequester } = formValues;
        setIsThirdPartyRequester(!!isThirdPartyRequester);
        const requester: Requester = isThirdPartyRequester ? Requester.THIRD_PARTY : Requester.CUSTOMER;
        const bankIdentificationCode = ibanStateHandler.signedBankData?.data.bankDetails?.bic || '';

        if (contract._links?.earlySettlementRequest && formValues.contractRedemptionDate) {
            setRequest({ status: 'IS_LOADING' });

            CpDataApi.post(
                contract._links?.earlySettlementRequest,
                withAdditionalFields(contract, requester, formValues, withKbs, bankIdentificationCode),
            )
                .then(response => {
                    setRequest({ status: 'SUCCESS' });
                    const documentInBase64 = response.data?.data;
                    documentInBase64 && isThirdPartyRequester && setThirdPartyDocument(documentInBase64);
                    onAction();
                })
                .catch(() => {
                    setRequest({ status: 'HAS_ERROR' });
                })
                .finally(() => setShowModal(true));
        }
    };

    const downloadDocument = (): void => {
        if (thirdPartyDocument) {
            const pdfContentType = 'application/pdf';
            const pdfBlob = base64ToBlob(thirdPartyDocument, pdfContentType);
            const filename = `${t('modal.success.filename')}.pdf`;
            saveAs(pdfBlob, filename);
        }
    };

    const goBackToDashboard = (): void => {
        history.push(dashboardPagePath());
    };

    const onConfirmModal = (): void => {
        if (request.status === 'HAS_ERROR') {
            setShowModal(false);
            return;
        }
        goBackToDashboard();
    };

    return (
        <>
            {request.status === 'IS_LOADING' && <Spinner fullPage={true} />}
            <Card element="article">
                <ContractHeader contract={contract} isDeferralLinkShown={false} />
            </Card>
            <TotalEarlySettlementForm
                contract={contract}
                iban={financialDetails?.iban}
                name={myProfile?.identity?.fullName}
                ibanStateHandler={ibanStateHandler}
                onCancel={goBackToDashboard}
                onSubmit={onSubmit}
                withKbs={withKbs}
            />
            <TotalEarlySettlementModal
                isThirdPartyRequester={isThirdPartyRequester}
                shown={showModal}
                error={request.status === 'HAS_ERROR'}
                onClose={(): void => setShowModal(false)}
                onConfirm={onConfirmModal}
                downloadDocument={downloadDocument}
                contract={contract}
            />
        </>
    );
};
